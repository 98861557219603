import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trackByFn } from 'src/app/shared/utils/track-by';
@Component({
  selector: 'app-simple-dashboard',
  templateUrl: './simple-dashboard.component.html',
  styleUrls: ['./simple-dashboard.component.scss']
})
export class SimpleDashboardComponent implements OnInit {

  @Input()
  config = [];

  trackByFn = trackByFn;

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  public returnCurrency(): string{
    return this.translateService.instant('global.currency');
  }

  public returnFormat(item): string{
    if(this.returnCurrency() === "USD"){
      return parseFloat(item).toLocaleString('en')
    }else{
      return parseFloat(item).toLocaleString('brl')
    }
  }
}
