import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';


import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
  } from '@coreui/angular';
  import { NgxDatatableModule } from '@swimlane/ngx-datatable';
  
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [
    ],
    imports: [
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        AppAsideModule,
        AppBreadcrumbModule,
        AppHeaderModule,
        AppFooterModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        NgxDatatableModule,
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        ToastrModule.forRoot()    
    ],
    exports: [
        BsDropdownModule,
        ModalModule,
        AppAsideModule,
        AppBreadcrumbModule,
        AppHeaderModule,
        AppFooterModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        NgxDatatableModule,
        TooltipModule,
        BsDatepickerModule,
        TypeaheadModule,
        TabsModule,
        AccordionModule,
        ToastrModule,
    ],
    providers: [
        BsModalService
    ]
})
export class NgxBoostrapModule { }
