import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { addOptionsList, findInList, removeOptionsList } from '../../utils/table-functions';
import { FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from '../../models/table-config.model';
import { MaskPipe } from 'ngx-mask';
import { BasicModalComponent } from '../basic-modal/basic-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Fields } from '../../enum/fields.enum';
import { TransformService } from '../../services/transform.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { CPFValidator, CNPJValidator } from '../../utils/validations';
import { ColumnsType } from '../../enum/columns-types.enum';
import { RegexPatternsData } from '../../data/regex-patterns.data';
import { TableActionsEnum } from '../../enum/table-actions.enum';

@Component({
  selector: 'app-guarantors-list',
  templateUrl: './guarantors-list.component.html',
  styleUrls: ['./guarantors-list.component.scss']
})
export class GuarantorsListComponent implements OnInit {

  @Input()
  guarantorsList = [];

  @Input()
  canEnableButtons = false;

  @Output()
  save = new EventEmitter<any>();

  @Output()
  remove = new EventEmitter<any>();

  form = this.fb.group({
    id: [null],
    sellerFundId: [null],
    cnpjCpf: [null, Validators.required],
    name: [null, Validators.required],
    email: [null, [Validators.email, Validators.required]],
  });

  config: TableConfig = {
    columns: [
      { header: 'name' },
      { header: 'email' },
      { header: 'cnpjCpf' },
      { header: 'dateCreated', type: ColumnsType.DATE }
    ],
    rows:
      [],
    limit: 100,
    total: 0,
    page: 0,
    showLimit: false,
    options: {
      actions: []
    }
  };

  selectType = {
    value: null,
    label: 'global.type',
    options: [
      {
        value: 0,
        label: 'CNPJ'
      },
      {
        value: 1,
        label: 'CPF'
      }
    ]
  };

  tmpRows = [];
  patterns = RegexPatternsData;

  constructor(public fb: FormBuilder,
    private transform: TransformService,
    private modal: BsModalService,
    private confirm: ConfirmDialogService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.guarantorsList && changes.guarantorsList) {
      this.config.rows = changes.guarantorsList.currentValue;
      this.config.total = this.config.rows.length;
      this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    }
  }
  ngOnInit(): void {
    this.config.rows = this.guarantorsList;
    this.config.total = this.guarantorsList.length;
    this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    this.config.options.actions = [
      {
        label: 'fields.edit',
        type: TableActionsEnum.EDIT,
        icon: 'fa fa-edit',
        class: 'btn click__item--blue',
        enabled: this.canEnableButtons
      },
      {
        label: 'fields.remove',
        type: TableActionsEnum.REMOVE,
        icon: 'fa fa-trash',
        class: 'btn click__item--red',
        enabled: this.canEnableButtons
      }
    ];
  }

  public addItem(): void {
    const form = this.form.value;

    if (!this.verifyDocument(form.cnpjCpf)) {
      return;
    }

    form.cnpjCpf = this.transform.transformDocument(form.cnpjCpf);
    this.form.reset();
    this.save.emit(form);
  }

  public editItem(item): void {
    const modalRef = this.modal.show(BasicModalComponent,
      {
        class: 'modal-dialog-centered',
        initialState: {
          config: {
            title: 'menu.updateGuarantor',
            // message: 'global.updateDataButton',
            actionButton: 'buttons.update',
            result: item,
            fields: [
              {
                type: Fields.CPF_CNPJ,
                value: item.cnpjCpf,
                showTitle: true,
                required: true
              },
              {
                name: 'global.name',
                type: Fields.TEXT,
                value: item.name,
                required: true
              },
              {
                name: 'global.email',
                type: Fields.EMAIL,
                value: item.email,
                required: true
              },
            ]
          }
        }
      });

    modalRef.content.onClose.subscribe((response) => {
      let error = false;
      if (response.fields.length > 0) {

        response.fields.map((itens) => {
          if (itens.required) {
            if (itens.type !== Fields.YES_NO && (!itens.value || itens.value.trim() == '')) {
              error = true;
            }
            if (itens.type === Fields.EMAIL && !(new RegExp(this.patterns.EMAIL_PATTERN).test(itens.value))){
              error = true;
            }
          }
        });


        if (!error) {
          const request = {
            ...response.result,
            cnpjCpf: response.fields[0].value,
            name: response.fields[1].value,
            email: response.fields[2].value
          }

          this.save.emit(request);
          modalRef.hide();
        }
      }
    });
  }


  public removeItem(item): void {
    const option = this.confirm.openDialog(null);

    option.subscribe((op) => {
      if (item.id) {
        this.remove.emit(item);
      }
    });
  }

  get formData() {
    return this.form.controls;
  }
  public search(item: string): void {
    if (!item || item === '') {
      this.config.rows = JSON.parse(JSON.stringify(this.tmpRows));
      this.config.total = this.config.rows.length;
    } else {
      item = item.toLowerCase();
      this.config.rows = this.tmpRows.filter((r) => r.name.toLowerCase().includes(item) || r.email.toLowerCase().includes(item) || r.cnpjCpf.replace(/[^\d]+/g, '').includes(item));
      this.config.total = this.config.rows.length;
    }
  }

  private verifyDocument(document): boolean {

    document = document.replace(/[^\d]+/g, '');

    if (document && document.length > 11) {
      return CNPJValidator(document);
    } else {
      return CPFValidator(document);
    }
  }
}
