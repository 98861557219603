import { NavbarContainerComponent } from './shared/components/navbar-container/navbar-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/authentication/login/login.component';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { UnauthorizedComponent } from './modules/authentication/unauthorized/unauthorized.component';
import { ActivateUserComponent } from './modules/authentication/activate-user/activate-user.component';
import { SelectAccessComponent } from './modules/authentication/select-access/select-access.component';
import { NotFoundComponent } from './modules/authentication/not-found/not-found.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'select-access',
    component: SelectAccessComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'activate-user/:id',
    component: ActivateUserComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'contract/:id',
    loadChildren: () => import('./modules/contract/contract.module').then(m => m.ContractModule)
  },
  {
    path: 'contract-pj/:id',
    loadChildren: () => import('./modules/contract-pj/contract-pj.module').then(m => m.ContractPjModule)
  },
  {
    path: 'contract-consultant/:id',
    loadChildren: () => import('./modules/contract-consultant/contract-consultant.module').then(m => m.ContractConsultantModule)
  },
  {
    path: 'home',
    component: NavbarContainerComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'home'
    },
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./modules/administrative/administrative.module').then(m => m.AdministrativeModule)
      },
      {
        path: 'registrations',
        loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'operation',
        loadChildren: () => import('./modules/operation/operation.module').then(m => m.OperationModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'imports',
        loadChildren: () => import('./modules/imports/imports.module').then(m => m.ImportsModule)
      },
      {
        path: 'parameterization',
        loadChildren: () => import('./modules/parameterization/parameterization.module').then(m => m.ParameterizationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
