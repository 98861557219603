export enum Fields {
    DATE_PATTERN = 'dd/MM/yyyy',
    DATE_PATTERN_US = 'MM/dd/yyyy',
    DATE_HOUR_PATTERN = 'dd/MM/yyyy HH:mm:ss',
    DATE_HOUR_PATTERN_US = 'MM/dd/yyyy HH:mm:ss',
    YES = 'fields.yes',
    NO = 'fields.no',
    FILTER = 'filter',
    KEYWORD = 'keyword',
    EQUAL = '=',
    SIZE = 'pageSize',
    SIZE_DEFAULT = '1000',
    PAGE = 'page',
    WRITE = 'write',
    LIST = 'list',
    REMOVE = 'remove',
    ACTIVE = 'active',
    CONTAINS = '%a%',
    NOT_REGISTRED = 'global.notRegistred',
    COMPANY = 'COMPANY',
    USER = 'user',
    LANGUAGE = 'LANGUAGE',
    COMPANY_TYPES = 'COMPANY_TYPES',
    TEXT = 'text',
    EMAIL = 'email',
    MASKED = 'masked',
    CPF_CNPJ = 'cpf_cnpj',
    STRING = 'string',
    NUMBER = 'number',
    COMBO = 'combo',
    CPF = 'cpf',
    YES_NO = 'yes_no',
    PHONE = 'phone',
    FAX = 'fax',
    PHONE_MASK = '(00)0000-0000',
    MOBILE_MASK = '(00)00000-0000',
    CEP_MASK = '00000-000',
    CNPJ_MASK = '00.000.000/0000-00',
    CPF_MASK = '000.000.000-00',
    FIRST_PAGE = '1',
    BANK_PATTERN = '[0-9]',
    IDSF = 'idsf.',
    REAL = 'BRL',
    LIST_TEXT = 'list_text',
    LIST_DATA = 'list_data',
    CHECKBOX = 'checkbox',
    TEXT_AREA = 'text_area'
}
