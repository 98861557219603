import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { trackByFn } from '../../utils/track-by';
import { RequestService } from '../../services/request.service';
import { APIS } from '../../enum/apis.enum';
import { CompanyType } from '../../enum/company-type.enum';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfigListModel } from '../../models/config-list.model';
import * as jwt_decode from "jwt-decode";
import { HttpParams } from '@angular/common/http';
import { CompanyObject } from '../../enum/companies-object.enum';

enum UserCompanies {
  MASTER = 'userMasters',
  CUSTODIAN = 'userCustodians',
  ADMINISTRATOR = 'userAdministrators',
  CONSULTANT = 'userConsultants',
  MANAGER = 'userManagers',
  FUND = 'userFunds'
}

@Component({
  selector: 'app-context-companies',
  templateUrl: './context-companies.component.html',
  styleUrls: ['./context-companies.component.scss'],
  providers: [TranslatePipe]
})
export class ContextCompaniesComponent implements OnInit {

  public onClose: Subject<boolean>;

  companyTypes = this.service.getCompanyTypes();
  config: any[] = [];
  treeConfig = [];
  values = [];
  userId: string;

  configCompanies: ConfigListModel;
  companiesSelected = [];

  itemConfig = {
    hasAllCheckBox: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 250,
  };

  accessLabel: string;
  trackByFn = trackByFn;
  value = null;

  options: any[] = [
    {
      label: 'teste',
      value: 1
    }
  ];

  companiesType = {
    0: CompanyType.MASTER,
    1: CompanyType.CUSTODIAN,
    2: CompanyType.ADMINISTRATOR,
    3: CompanyType.MANAGER,
    4: CompanyType.CONSULTANT,
    5: CompanyType.FUND
  };

  translatedTypes = {
    0: 'global.master',
    1: 'global.custodian',
    2: 'global.administrator',
    3: 'global.manager',
    4: 'global.consultant',
    5: 'global.fund',
  };

  contextGroups = {
    custodians: 'masterId',
    admins: 'custodianId',
    managers: 'administratorId',
    consultants: 'managerId',
    funds: 'consultantId'
  };

  isFiltered = {
    value: null,
    label: "global.fund",
    options: [
      {
        value: 1,
        label: 'global.custodian',
      },
      {
        value: 2,
        label: 'global.administrator',
      },
      {
        value: 3,
        label: 'global.manager',
      },
      {
        value: 4,
        label: 'global.consultant',
      }
    ]
  };

  typesConverter = {
    0: UserCompanies.CONSULTANT,
    1: UserCompanies.MANAGER,
    2: UserCompanies.ADMINISTRATOR,
    3: UserCompanies.CUSTODIAN,
    4: UserCompanies.MASTER,
  };

  CONSULTANT = 'consultans';
  accessType: string;

  constructor(private auth: AuthService,
    private service: RequestService,
    public bsModalRef: BsModalRef,
    public translatePipe: TranslatePipe) {
    this.onClose = new Subject();
  }


  async ngOnInit() {
    const accessItem = this.auth.getAccessType();
    this.accessType = accessItem.access ? accessItem.access : '';
    this.accessLabel = accessItem.label ? accessItem.label : '';
    this.buildRelativeItems();

    let token = this.auth.getAuthentication();
    let user = jwt_decode(token);
    this.userId = user.UserId;

    if (this.userId) {
      await this.listUsersCompanies();
    }

    this.listAllCompanies();
  }


  onSelectedChange(evt) {
    this.values = [];
    this.configCompanies = evt;

    this.configCompanies.permissions.map((item: any) => {
      if (item.value) {
        this.values.push({
          id: item.id,
          name: item.label
        });
      }
    });

    if (this.accessType === this.CONSULTANT) {
      this.onSave();
    }
  }

  public onBack(): void {
    this.bsModalRef.hide();
  }

  public onSave(): void {

    const request = this.values.map((value) => {
      value.fundId = value.id;
      return { fundId: value.id }
    });

    this.auth.saveFunds(this.values);

    this.service.post(`${APIS.COMPANY_CURRENT}${APIS.POST}`, request).subscribe((success) => {
      this.onClose.next(true);
      this.onBack();
    });
  }

  private async buildRelativeItems() {

    let accesses = this.auth.getAllAccesses();
    const companiesByGroup = [];
    if (!accesses.masters) {

      const companies = await this.service.list(`${APIS.COMMON}${APIS.GET_COMMON}${APIS.COMPANY}-type-list`, null).toPromise();

      const response = companies.model;
      const types = Object.keys(response);

      types.map((item) => {
        const data = response[item];
        if (response[item].length > 0) {
          companiesByGroup.push(data);
        }
      });


      response.totalTypes = companiesByGroup.length;
      accesses = response;
      this.auth.setAllAccesses(response);
    }

    this.buildTreeViewComponentData(accesses);
  }

  private buildTreeViewComponentData(accesses): void {

    const items = [];
    let keys = Object.keys(accesses);
    keys.splice(keys.length - 1, 1);

    for (let i = 0; i < keys.length; i++) {
      accesses[keys[i]].map((item) => {
        items.push({
          id: item.id,
          text: `${this.translatePipe.transform(this.translatedTypes[i])}: ${item.company.fullName}`,
          type: this.companiesType[i],
          parentId: item[this.contextGroups[keys[i]]],
          value: { fundId: item.id, name: item.company.fullName },
          children: [],
          checked: false
        });
      });
    }

    const master = items[0];

    const custodians = items.filter((items) => items.type === CompanyType.CUSTODIAN);

    master.children = custodians;

    master.children.map((custodian) => {
      const administrator = items.filter((item) => custodian.id === item.parentId);

      custodian.children.push(...administrator);

      custodian.children.map((administrators) => {
        const managersFinded = items.filter((item) => administrators.id === item.parentId);
        administrators.children.push(...managersFinded);

        administrators.children.map((managers) => {
          const consultantsFinded = items.filter((item) => managers.id === item.parentId);
          managers.children.push(...consultantsFinded);

          managers.children.map((consultants) => {
            const funds = items.filter((item) => consultants.id === item.parentId);
            consultants.children.push(...funds);
          });
        });
      });
    });


    let config = [];
    if (this.accessType === 'masters') {
      config = [master];
    } else {
      const tempConf = [];
      master.children.map((conf) => {
        tempConf.push(conf);
      });

      config = tempConf;
    }

    this.treeConfig = config;
  }

  private async listUsersCompanies() {
    let companies: any = await this.service.list(`${APIS.USER}/get/companies-by-userid`, new HttpParams().append('userId', this.userId.toString())).toPromise();
    companies = companies.model;

    const columns = Object.values(CompanyObject);

    columns.map((column) => {
      companies[column].map((item) => {
        const label = column.substring(4, column.length - 1).toLowerCase();

        if (item[label]) {
          this.companiesSelected.push({ id: item[label].id, type: label.toUpperCase() });
        }
      });
    });
  }

  private listAllCompanies(): void {

    this.service.list(`${APIS.COMMON}${APIS.GET_COMMON}fund-list`, null).subscribe((response) => {
      const items = [];
      const companies = [];

      response.model.items.map((item) => {
        items.push({
          id: item.id,
          label: item.company.shortName,
          type: CompanyType.FUND,
          value: false,
          hierarchy: this.buildCompanyItem(item)
        });
      });

      this.companyTypes.map((types) => {
        let tempArray = items.filter((c) => c.type === types.label);

        tempArray = tempArray.map((temp) => {
          const item = this.companiesSelected.find((c) => c.id === temp.id && c.type === types.label);
          return { id: temp.id, label: temp.label, value: false, hierarchy: temp.hierarchy ? temp.hierarchy : [] };
        });

        if (tempArray.length > 0) {

          const fund = this.auth.getFunds() ? this.auth.getFunds()[0] : null;

          if (fund) {
            const checked = tempArray.find((item) => item.id === fund.fundId);

            if (checked) {
              checked.value = true;
            }
          }

          const companyConfig = {
            title: types.label,
            isUnique: true,
            permissions: tempArray
          }

          tempArray.map((item) => {
            if (item.value) {
              this.values.push(item);
            }
          });
          companies.push(companyConfig);
        }

      });
      this.configCompanies = companies[0] ? companies[0] : null;
    });
  }

  private buildCompanyItem(item) {
    const list = [];

    list.push(this.setCompanyData(item.consultant, this.translatedTypes[4], true));

    list.push(this.setCompanyData(item.consultant.manager, this.translatedTypes[3], true));
    list.push(this.setCompanyData(item.consultant.manager.administrator, this.translatedTypes[2], true));
    list.push(this.setCompanyData(item.consultant.manager.administrator.custodian, this.translatedTypes[1], true));
    list.push(this.setCompanyData(item.consultant.manager.administrator.custodian.master, this.translatedTypes[0], false));

    return list;
  }

  private setCompanyData(item, type, visible) {

    return { id: item.id, name: item.company.fullName, group: type, visible: visible };
  }

}
