<div class="translater">
    <app-translater></app-translater>
</div>
<div class="login">

    <div class="login__img__container" [ngClass]="{'id__company': isIdcompany, 'edge__company': !isIdcompany}">
        <img [src]="logoImg">
    </div>

    <div class="login_container">
        <div>
            <img class="logo" [src]="logoImg">
            <h2>{{'authentication.definePassword' | translate }}</h2>

            <form [formGroup]="activateForm">
                <div class="col-md-6">
                    <small>{{'authentication.password' | translate }}*</small>
                    <input class="form-control mb-3" type="password"
                        placeholder="{{'authentication.password' | translate }}" formControlName="password"
                        name="password" (blur)="verifyPassword()" (keyup.enter)="activateUser()">
                </div>

                <div class="col-md-6">
                    <small>{{'authentication.confirmedPassword' | translate }}*</small>
                    <input class="form-control mb-3" type="password"
                        placeholder="{{'authentication.confirmedPassword' | translate }}"
                        formControlName="confirmedPassword" name="password" (blur)="verifyPassword()"
                        (keyup.enter)="activateUser()" (keyup)="verifyPassword()">
                    <small class="small__error" *ngIf="passwordEqual">{{'global.diffPasswords' | translate }}</small>
                </div>

                <div class="col-md-6">
                    <button type="button" class="btn btn-primary" [disabled]="!activateForm.valid || passwordEqual"
                    (mouseover)="verifyPassword()"  (click)="activateUser()">{{'fields.confirm' | translate}}</button>
                </div>

            </form>
        </div>
    </div>

</div>