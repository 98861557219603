import { MessageService } from './../../shared/services/message.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Messages } from 'src/app/shared/enum/messages.enum';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public auth: AuthService, public router: Router,
                public messages:  MessageService) { }

    canActivate(event): boolean {

        if (!this.auth.getAuthentication()) {
            this.router.navigate(['login']);
            return false;
        } else {

            const claims = this.auth.getClaims();
            const claim = Object.keys(claims).includes(event.data.claim);
            if (event.data.claim && !claim) {
                this.router.navigate(['unauthorized']);
                return false;
            }
        }

        return true;
    }
}