import { Company } from '../../../shared/enum/company-enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { trackByFn } from 'src/app/shared/utils/track-by';
import { Fields } from 'src/app/shared/enum/fields.enum';
import { ContextCompaniesComponent } from 'src/app/shared/components/context-companies/context-companies.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RequestService } from 'src/app/shared/services/request.service';
import { APIS } from 'src/app/shared/enum/apis.enum';
import { ClaimsModel } from 'src/app/shared/models/claims.model';
import * as jwt_decode from 'jwt-decode';
import { LoginService } from '../login/login.service';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { AccessTypeData } from 'src/app/shared/data/access-type.data';
import { MessageService } from 'src/app/shared/services/message.service';
import { Messages } from 'src/app/shared/enum/messages.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-access',
  templateUrl: './select-access.component.html',
  styleUrls: ['./select-access.component.scss'],
  providers: [LoginService]
})
export class SelectAccessComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(5)]],
    rememberMe: [false],
  });

  forgotPassword = false;
  logoImg = 'assets/images/logo.png';
  isIdcompany = false;
  accessType = false;
  maintainType = false;

  accesses = [];
  companiesPeerGroup = [];

  userAccessTypes;
  trackByFn = trackByFn;

  acessesType = AccessTypeData.acessesType;

  constructor(private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private service: RequestService,
    private loginService: LoginService,
    private message: MessageService) { }

  ngOnInit() {
    this.buildAccesses();
    this.verifyCompany();
  }


  private verifyCompany(): void {
    const isIdcompany = window.location.href.includes(Fields.IDSF);

    if (isIdcompany) {
      this.logoImg = 'assets/images/id-logo.png';
      localStorage.setItem(Fields.COMPANY, Company.ID.toString());
      this.isIdcompany = true;
    }
  }

  private buildAccesses(): void {
    const allItems = this.auth.getAccessOptions();
    this.userAccessTypes = allItems.accesses;
    this.accesses = allItems.accesses;
  }

  public async selectAccessType(event) {

    const currentType = await this.service.post(`${APIS.USER_CURRENT_TYPE}${APIS.REGISTER}`, {
      accessType: event.type,
      isPersistent: this.maintainType
    }).toPromise();

    if (currentType.code === 200) {
      await this.finalAuthentication();

      this.getCompaniesByUser(event);
    }
  }

  private getCompaniesByUser(event): void {

    this.service.list(`${APIS.COMMON}${APIS.GET_COMMON}${APIS.COMPANY}-type-list`, null).subscribe(async (companies) => {
      const response = companies.model;
      this.userAccessTypes = response;
      const types = Object.keys(this.acessesType);

      types.map((item) => {
        const data = this.acessesType[item];
        if (response[item].length > 0) {
          this.companiesPeerGroup.push(data);
        }
      });

      if (this.companiesPeerGroup.length > 0) {
        response.totalTypes = this.companiesPeerGroup.length;
        this.setAccessesTypes(response, event);

        const companies = await this.service.list(`${APIS.COMPANY_CURRENT}${APIS.GET}`, null).toPromise();

        if (companies.model.items.length === 0) {
          this.openContextModal();
        } else {
          const funds = companies.model.items.map((fund) => {
            return { fundId: fund.fundId, name: fund.fund.company.fullName };
          });
          this.auth.saveFunds(funds);
          this.router.navigate(['/home/dashboards/wallet']);
        }

      } else {
        this.message.show(Messages.NO_COMPANY_ABLE, Messages.ERROR);
        this.auth.logout();
        this.router.navigate(['login']);
      }
    });
  }

  private setAccessesTypes(data, event) {

    this.auth.setAllAccesses(data);

    const userAccessType = this.userAccessTypes ? this.userAccessTypes[event.field] : [];

    this.auth.setAccessType({
      label: event.label,
      access: event.field,
      companies: userAccessType
    });
  }

  private openContextModal(): void {
    const modalRef = this.modalService.show(ContextCompaniesComponent,
      {
        class: 'modal-dialog-centered',
        initialState: null
      });

    modalRef.setClass('modal-lg');
    modalRef.content.onClose.subscribe((item) => {
      this.router.navigate(['/home/dashboards/wallet']);
    });
  }
  private buildClaims(claims: ClaimsModel): void {
    claims = new ClaimsModel(claims);
    let claimsObject;

    Object.keys(claims).map((claim) => {
      claimsObject = {
        ...claimsObject,
        [`${claim}`]: true
      }
    });

    this.auth.setClaims(claimsObject);
  }

  public getCompanyTypes(): void {
    this.service.list(`${APIS.COMPANY}${APIS.GET_COMMON}company-type`, null).subscribe((company: any) => {

      company.map((c) => {
        c.value = c.name;
        c.label = c.name;
        delete c.name;
      });

      localStorage.setItem(Fields.COMPANY_TYPES, JSON.stringify(company));
    });
  }

  private getUserData(): void {
    this.loginService.getUserData().subscribe((user: ResponseModel) => {
      localStorage.setItem(Fields.USER, JSON.stringify({
        username: user.model.name,
        email: user.model.email,
      }));
    });
  }

  private async finalAuthentication() {
    const success = await this.service.post(`${APIS.AUTHENTICATION}/gettoken`, null).toPromise();

    this.auth.setAuthentication(success.model);

    this.buildClaims(jwt_decode(success.model));

    this.getUserData();
    this.getCompanyTypes();
  }

}
