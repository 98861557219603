<div class="modal-content">
<div *ngIf="config != 'cookies'">
  <div [ngStyle]="{'border-color': 'solid 1px '+color,'background-color':color}" class="basic__title">
    <h2><strong>{{'global.privacy' | translate}}</strong></h2>
  </div>
  <div class="basic__header" style="text-align: left;">
    <label><span>{{'global.mainPrioritiesRegistrationForm' | translate}}</span></label>
    <br>
    <label><strong>{{'global.consent' | translate}}</strong></label>
    <label><span>{{'global.informationCollect' | translate}}</span></label>
    <br>
    <label><strong>{{'global.informationWeCollect' | translate}}</strong></label>
    <label><span>{{'global.personalInformationWeCollect' | translate}}</span></label>
    <label><span>{{'global.directlyContact' | translate}}</span></label>
    <label><span>{{'global.whenRegisterAccount' | translate}}</span></label>
    <br>
    <label><strong>{{'global.howUseInformation' | translate}}</strong></label>
    <label><span>{{'global.varietyofWays' | translate}}</span></label>
    <ul>
      <li><span>{{'global.maintainWebsite' | translate}}</span></li>
      <li><span>{{'global.expandWebsite' | translate}}</span></li>
      <li><span>{{'global.howUseWebsite' | translate}}</span></li>
      <li><span>{{'global.developNewProducts' | translate}}</span></li>
      <li><span>{{'global.communicateWithYou' | translate}}</span></li>
      <li><span>{{'global.sendEmails' | translate}}</span></li>
      <li><span>{{'global.findFraud' | translate}}</span></li>
      <li><span>{{'global.logFiles' | translate}}</span></li>
    </ul>
    <br>
    <label><span>{{'global.registrationFormWithLog' | translate}}</span></label>
    <br>
    <label><strong>{{'global.webBeacons' | translate}}</strong></label>
    <label><span>{{'global.likeAnyWebsite' | translate}}</span></label>
    <br>
    <label><strong>{{'global.thidPrivacyPolicies' | translate}}</strong></label>
    <label><span>{{'global.policyRegistrationForm' | translate}}</span></label>
    <label><span>{{'global.disableCookies' | translate}}</span></label>
    <br>
    <label><strong>{{'global.ccpaInformation' | translate}}</strong></label>
    <label><span>{{'global.underCcpa' | translate}}</span></label>
    <br>
    <label><span>{{'global.requestCompanyCollectsConsumer' | translate}}</span></label>
    <label><span>{{'global.requestCompanyDeletePersonalConsumer' | translate}}</span></label>
    <label><span>{{'global.requestCompanySellsConsumer' | translate}}</span></label>
    <br>
    <label><span>{{'global.ifMakeRequest' | translate}}</span></label>
    <br>
    <label><strong>{{'global.gdprDataProtection' | translate}}</strong></label>
    <label><span>{{'global.youFullyAware' | translate}}</span></label>
    <br>
    <p><strong>{{'global.rigthAccess' | translate}} - </strong>{{'global.haveRigthAccess' | translate}}</p>
    <p><strong>{{'global.rigthRectification' | translate}} - </strong>{{'global.haveRigthRectification' | translate}}</p>
    <p><strong>{{'global.rigthErase' | translate}} - </strong>{{'global.haveRigthErase' | translate}}</p>
    <p><strong>{{'global.rigthRestrict' | translate}} - </strong>{{'global.haveRigthRestrict' | translate}}</p>
    <p><strong>{{'global.rigthObject' | translate}} - </strong>{{'global.haveRigthObject' | translate}}</p>
    <p><strong>{{'global.rigthPortability' | translate}} - </strong>{{'global.haveRigthPortability' | translate}}</p>
    <br>
    <label><span>{{'global.ifMakeRequest' | translate}}</span></label>
    <br>
    <label><strong>{{'global.childInformation' | translate}}</strong></label>
    <label><span>{{'global.addChildrenProtection' | translate}}</span></label>
    <label><span>{{'global.registrationFormNotCollet' | translate}}</span></label>
  </div>
</div>
<div *ngIf="config == 'cookies'">
  <div [ngStyle]="{'border-color': 'solid 1px '+color,'background-color':color}" class="basic__title">
    <h1><strong>{{'global.cookies' | translate}}</strong></h1>
  </div>
  <div class="basic__header" style="text-align: left;">
    <label><strong>{{'global.whatCookies' | translate}}</strong></label>
    <label><span>{{'global.commonPracticeCookies' | translate}}</span></label>
    <br>
    <label><strong>{{'global.howUseCookies' | translate}}</strong></label>
    <label><span>{{'global.severalReasonsCookies' | translate}}</span></label>
    <br>
    <label><strong>{{'global.disablingCookies' | translate}}</strong></label>
    <label><span>{{'global.preventCookies' | translate}}</span></label>
    <br>
    <h3><strong>{{'global.cookiesWeSet' | translate}}</strong></h3>
    <label><strong>{{'global.accountRelatedCookies' | translate}}</strong></label>
    <label><span>{{'global.ifCreateAccount' | translate}}</span></label>
    <br>
    <label><strong>{{'global.formsCookies' | translate}}</strong></label>
    <label><span>{{'global.whenSubmitDataForm' | translate}}</span></label>
    <br>
    <label><strong>{{'global.sitePreferencesCookies' | translate}}</strong></label>
    <label><span>{{'global.operatingPreferencesWebsite' | translate}}</span></label>
    <br>
    <label><strong>{{'global.thirdPartyCookies' | translate}}</strong></label>
    <label><span>{{'global.cookiesProvidedThirdPartiesSpecialCase' | translate}}</span></label>
    <label><span>{{'global.cookiesProvidedThirdPartiesTimeToTime' | translate}}</span></label>
    <br>
    <label><strong>{{'global.moreInformation' | translate}}</strong></label>
    <label><span>{{'global.weHopeClarifiedThings' | translate}}</span></label>
  </div>
</div>
<div class="btnBack">
  <button [ngStyle]="{'background-color': color}"  type="button" class="btn btnAction" (click)="closeModal()">{{'buttons.back'  | translate }}</button>
</div><br>
</div>
