import { AccessTypesSimplified } from '../enum/access-types-simplified.enum';

export class AccessTypeData {

    public static acessesType = {
        [AccessTypesSimplified.MASTERS]: {
            id: 1,
            label: 'global.master',
            field: AccessTypesSimplified.MASTERS
        },
        [AccessTypesSimplified.CUSTODIANS]: {
            id: 2,
            label: 'global.custodian',
            field: AccessTypesSimplified.CUSTODIANS
        },
        [AccessTypesSimplified.MANAGERS]: {
            id: 3,
            label: 'global.manager',
            field: AccessTypesSimplified.MANAGERS
        },
        [AccessTypesSimplified.CONSULTANTS]: {
            id: 4,
            label: 'global.consultant',
            field: AccessTypesSimplified.CONSULTANTS
        },
        [AccessTypesSimplified.ADMINS]: {
            id: 5,
            label: 'AccessTypesSimplified.administrator',
            field: AccessTypesSimplified.ADMINS
        }
    };

}