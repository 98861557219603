<br />
<br />
<div class="card__actions">
    <div id="moreItems" *ngIf="form?.controls['id']?.value && more?.length > 0" class="btn-group" dropdown [dropup]="isDropup">
        <button id="button-dropup" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-dropup">
            {{'fields.more' | translate }} <span class="caret"></span>
        </button>
        <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-dropup">
            <li *ngFor="let item of more; trackBy: trackByFn" role="menuitem" (click)="onMoreOptions(item)"><a class="dropdown-item" >{{ "fields." + item.label | translate}}</a></li>
        </ul>
    </div>
    <button type="button" class="btn btn-secondary" (click)="back()">{{'buttons.back'
        | translate }}</button>
    <button id="saveid" type="button" *ngIf="showSave" class="btn btn-success" [disabled]="form.invalid || disabled" (click)="saveAction()">{{'buttons.save'
        | translate }}</button>
</div>
