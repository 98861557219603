<div>
    <mat-table mat-table [dataSource]="dataSource" matSort (matSortChange)="matSortChange($event)">

        <ng-container matColumnDef="select" *ngIf="config.showSelect !== false">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" *ngIf="showCheckboxRight"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
          <strong *ngIf="!showCheckboxRight" class="menu-title">{{'global.selectedCertifiers' | translate}}</strong>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(row) : null"
                    [checked]="selection.isSelected(row) || row.checked" [aria-label]="checkboxLabel(row)" *ngIf="showCheckboxRight">
                </mat-checkbox>
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(row) : null"
                    [checked]="selection.isSelected(row) || row.checked" [aria-label]="checkboxLabel(row)" *ngIf="!showCheckboxRight" style="margin-right: 60px;">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container *ngFor="let column of config.columns" [matColumnDef]="column.header">
            <mat-header-cell *matHeaderCellDef mat-sort-header><strong>{{ 'global.' +  column.header | translate }}</strong>
             <div *ngIf="showFilter">
              <div class="header" *ngIf="config.showFilter !== false">
                    <button mat-button class="btn-toggle" [matMenuTriggerFor]="menu">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>

                </div>
                <mat-menu #menu>
                    <div mat-menu-item mat-filter-item [disableRipple]="true" class="menu-title">
                        {{ 'global.' +  column.header | translate }}
                    </div>
                    <div mat-menu-item mat-filter-item [disableRipple]="true">

                        <mat-select *ngIf="!column.filterItems && column.type !== 6 && column.type !== 2" [panelClass]="'mat-elevation-z10'" placeholder="{{'global.conditions' | translate}}"
                            [(value)]="searchCondition[column.header]">
                            <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">
                                {{'global.' + condition.label | translate}}</mat-option>
                        </mat-select>

                        <mat-select *ngIf="!column.filterItems && column.type === 6 || column.type === 2" [panelClass]="'mat-elevation-z10'" placeholder="{{'global.conditions' | translate}}"
                        [(value)]="searchCondition[column.header]">
                        <mat-option *ngFor="let  condition of conditionsDateList" [value]="condition.value">
                            {{'global.' + condition.label | translate}}</mat-option>
                    </mat-select>

                        <mat-select *ngIf="column.filterItems" [panelClass]="'mat-elevation-z10'" placeholder="{{'global.conditions' | translate}}"
                        [(value)]="searchCondition[column.header]">
                        <mat-option *ngFor="let  condition of conditionsListBasic" [value]="condition.value">
                            {{'global.' + condition.label | translate}}</mat-option>
                    </mat-select>

                    </div>

                    <div mat-menu-item mat-filter-item [disableRipple]="true">
                      <div *ngIf="!column?.filterItems;else showList">
                        <input *ngIf="column.type !== 6 && column.type !== 2" matInput
                            placeholder="{{'global.value' | translate}}" [(ngModel)]="searchValue[column.header]">
                        <input type="text" class="form-control" bsDatepicker placeholder="{{'global.value' | translate}}"
                          [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' }" name="filter"
                          [(ngModel)]="filterDate" (ngModelChange)="searchValue[column.header]"
                          *ngIf="(column.type === 6 || column.type === 2)">
                        </div>
                        <ng-template #showList>
                            <mat-select [panelClass]="'mat-elevation-z10'"
                                placeholder="{{ 'global.' + column.header | translate}}"
                                [(value)]="searchValue[column.header]">
                                <mat-option *ngFor="let item of column.filterItems" [value]="item.value">
                                    {{ item.label | translate}}</mat-option>
                            </mat-select>
                        </ng-template>


                    </div>

                    <div mat-menu-item mat-filter-item [disableRipple]="true">
                        <button mat-raised-button
                            (click)="clearColumn(column.header)">{{'fields.remove' | translate}}</button>
                        <button class="button--blue" mat-raised-button color="primary" (click)="applyFilter(column)"
                            [disabled]="!searchValue[column.header] && !searchCondition[column.header]">{{'fields.search' | translate}}</button>

                    </div>
                </mat-menu>
               </div>
              </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <small class="mobile">{{ 'global.' +  column.header | translate }}</small>

                <div *ngIf="column.type === columnsType.CHECK" class="appcheck__list__item">
                    <label class="switch switch-outline-primary-alt">
                        <input [(ngModel)]="row.checkValue" name="checkValue" [checked]="row.checkValue"
                            class="switch-input" type="checkbox"><span class="switch-slider"></span>
                    </label>
                </div>

                <div *ngIf="column.type === columnsType.ACTION && row[column.header + 'Visible']"
                    class="appcheck__list__item">
                    <button [class]="column.class" (click)="emitAction(row, column)"
                        [disabled]="!row[column.header + 'Enabled']" tooltip="{{column.label | translate}}">
                        <i [class]="column.icon"></i>
                    </button>
                </div>

                <div *ngIf="column.type === columnsType.THUMBS_UP" class="appcheck__list__item">
                    <button class="{{row.thumbs ? 'btn click__item--green': 'btn click__item--red'}}">
                        <i class="{{row.thumbs ? 'fa fa-thumbs-up': 'fa fa-thumbs-down'}}"></i>
                    </button>
                </div>

                <span *ngIf="verifyColumnDifferent(column); else elseBlock">
                    <span *ngIf="column.type !== columnsType.LINK" [ngStyle]="isColoredLabel(column, row)"
                        tooltip="{{ buildRow(column, row[column.header]) }}">{{ buildRow(column, row[column.header]) }}</span>
                    <a class="a__link" *ngIf="column.type === columnsType.LINK"
                        tooltip="{{ buildRow(column, row[column.header]) }}" (click)="emitLink(column, row)">
                        {{ buildRow(column, row[column.header]) }}
                    </a>

                </span>
                <ng-template #elseBlock><label *ngIf="verifyColumnDifferent(column)">
                        <label *ngIf="row[column.header]">
                            <div>
                                <div *ngIf="column.header == 'groups'; else companyBlock">
                                    <span>{{ verifyTootipItem(row[column.header].title)}}
                                        <span *ngIf="row[column.header].title">:</span>
                                        <span
                                            *ngIf="!row[column.header].title">{{'global.notRegistred'| translate}}</span>
                                        {{row[column.header].title?.name}}</span>
                                    <ng-template #popTemplate>
                                        <span class="span__tooltip"
                                            *ngFor="let item of row[column.header].tooltip; trackBy: trackByFn">
                                            {{item.roleGroup | translate}}: {{item.name}}
                                        </span>
                                    </ng-template>
                                    <a *ngIf="row[column.header]?.total > 0" [routerLink]="" [tooltip]="popTemplate"
                                        placement="bottom">
                                        {{ "global.and" | translate }}
                                        {{ "global.more" | translate }}
                                        {{ row[column.header].total }}</a>
                                </div>
                                <ng-template #companyBlock>
                                    <a *ngIf="
                              row[column.header]?.total == 1 ||
                                row[column.header]?.total == 0;
                              else totalBlock
                            ">
                                        {{ row[column.header]?.total }}
                                        {{ "global.company" | translate }}
                                    </a>
                                    <ng-template #totalBlock>
                                        <a>
                                            {{ row[column.header]?.total }}
                                            {{ "global.companies" | translate }}
                                        </a>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </label>
                    </label></ng-template>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef><strong>{{'global.actions' | translate}}</strong></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div class="action__buttons" *ngIf="!isAllSelected()">

                    <span *ngFor="let action of config?.options?.actions;trackBy: trackByFn">
                        <button [class]="action.class"
                            *ngIf="action.type !== tableActionsEnum.REFRESH && action.type !== tableActionsEnum.DETAILS && action.type !== tableActionsEnum.EDIT"
                            (click)="emitAction(row, action)"
                            [disabled]="!action.enabled || verificaStatusOperacao(row, action)"
                            tooltip="{{action.label | translate}}">
                            <i [class]="action.icon"></i>
                        </button>

                        <a [href]="buildUrl(action.updateLink, row.id)" [class]="action.class"
                            tooltip="{{action.label | translate}}"
                            *ngIf="action.type === tableActionsEnum.EDIT && action.enabled && !verificaStatusOperacao(row, action)" rel="noopener noreferrer">
                            <i [class]="action.icon"></i>
                        </a>


                        <button [class]="action.class" *ngIf="action.type === tableActionsEnum.REFRESH && row.refresh"
                            (click)="emitAction(row, action)" tooltip="{{action.label | translate}}">
                            <i [class]="action.icon"></i>
                        </button>
                        <button [class]="action.class" *ngIf="action.type === tableActionsEnum.DETAILS && row.details"
                            (click)="emitAction(row, action)" tooltip="{{action.label | translate}}">
                            <i [class]="action.icon"></i>
                        </button>
                    </span>
                    <div *ngIf="config?.options?.more?.length > 0">
                        <button class="btn click__item--green" tooltip="{{ 'fields.more' | translate }}"
                            [matMenuTriggerFor]="popupmenu">
                            <i class="fa fa-sort-desc" aria-hidden="true"></i>
                        </button>
                        <mat-menu #popupmenu>
                            <button *ngFor="
                          let m of config.options.more[row.index];
                          trackBy: trackByFn
                        " (click)="onMoreOptions(row, m)" [disabled]="!m.enabled" class="more__button dropdown-item"
                                mat-menu-item [disableRipple]="true">
                                {{ "fields." + m.label | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
    <mat-paginator *ngIf="config.showLimit !== false" [length]="length" [pageIndex]="pageControl"
        [pageSize]="config?.limit" [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons (page)="page($event)">
    </mat-paginator>
</div>
