import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit {

  trackByFn = trackByFn;

  @Input()
  select = null;

  @Output()
  selection: EventEmitter<any> = new EventEmitter();

  checkedItems: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public selectItem(event): void {

    const comparator = Number(event) ? Number(event) : event;
    const item = this.select.options.find((option) => option.value === comparator);

    if (!this.checkedItems.includes(item)) {
      this.checkedItems.push(item);
      this.emitItem();
    }
    this.clearValue();
  }

  public remove(item): void {
    this.checkedItems.splice(this.checkedItems.indexOf(item), 1);
    this.emitItem();
    this.clearValue();
  }

  private emitItem(): void {
    this.selection.emit(this.checkedItems);
  }

  private clearValue(): void {
    this.select.value = null;

    const tempSelect = Object.assign({}, this.select);

    this.select = null;

    setTimeout(() => {
      this.select = tempSelect;
    }, 80);
  }
}
