<li class="nav-item dropdown" dropdown placement="bottom right">
    <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img [src]="img">
    </a>
    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div *ngFor="let lang of languages;trackBy: trackByFn" class="dropdown-item" (click)="selectLang(lang)">
            <img [src]="lang.image">
            <a>{{lang.name}}</a>
        </div>
    </div>
</li>