import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/shared/models/response.model';


@Injectable()
export class LoginService {

    constructor(private http: HttpClient) { }

    public login(request: any): Observable<ResponseModel> {
        return this.http.post<ResponseModel>(`${environment.API}/Authentication/login`, request);
    }

    public forgotPassword(email: string): Observable<ResponseModel> {
        return this.http.post<ResponseModel>(`${environment.API}/Authentication/forgot-password?email=${email}`, null);
    }

    public getUserData(): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${environment.API}/Authentication/me`);
    }

    public activateUser(request: any): Observable<ResponseModel> {
        return this.http.post<ResponseModel>(`${environment.API}/authentication/activate-user`, request);
    }
}
