export enum ColumnsType  {
    BOOLEAN = 1,
    DATE = 2,
    TOOLTIP = 3,
    TYPE = 4,
    THUMBS_UP = 5,
    DATE_TIME = 6,
    HEADER_VALUE = 7,
    RATING = 8,
    TRUNCATED = 9,
    LINK = 10,
    CHECK = 11,
    CURRENCY = 12,
    ACTION = 13,
    NUMBER = 14,
    PHONE = 15
};
