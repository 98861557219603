import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-check-list-filtered',
  templateUrl: './check-list-filtered.component.html',
  styleUrls: ['./check-list-filtered.component.scss']
})
export class CheckListFilteredComponent implements OnInit {

  @Input()
  config;

  @Output()
  selection: EventEmitter<any> = new EventEmitter();

  @Input()
  select = {
    value: null,
    label: "global.type",
    options: []
  };

  value = null;
  search: String;

  filteredItems = [];
  trackByFn = trackByFn;

  typesConverter = {
    0: 4,
    1: 3,
    2: 2,
    3: 1,
    4: 0,
  };

  constructor() { }

  ngOnInit(): void {
    this.filteredItems = this.removeReference(this.config.permissions);
  }

  onSelection() {
    this.verifyUniqueItem();
    this.config.permissions = this.removeReference(this.filteredItems);
    this.selection.emit(this.config);
  }

  onSearch(event) {

    if (!this.search) {
      this.filteredItems = this.removeReference(this.config.permissions);
      return;
    }

    let filter = [];
    if (this.value && this.value !== 'null') {
      for (let i = 0; i < this.config.permissions.length; i++) {
        const item = this.config.permissions[i];
        if (item.hierarchy[this.typesConverter[this.value]].name.toLowerCase().includes(this.search.toLowerCase())) {
          filter.push(item);
        }
      }
    } else {
      filter = this.config.permissions.filter((items) => items.label.toLowerCase().includes(this.search.toLowerCase()));
    }

    this.filteredItems = filter;

  }

  selectItem(event) {

  }

  private removeReference(object) {
    return JSON.parse(JSON.stringify(object));
  }

  private verifyUniqueItem(): void {
    if (this.config.isUnique) {

      const checked = this.filteredItems.find((item) => item.lastChecked && item.value);

      if (checked) {

        this.filteredItems.map((item) => {
          if (item.id !== checked.id) {
            item.value = false;
          } else {
            item.lastChecked = false;
          }
        });
      }
    }
  }
}
