<div class="basic__title">
    {{config.title | translate}}
</div>
<div class="basic__header">
    <label *ngIf="config.message">{{config.message | translate}}:</label>

    <div [ngClass]="{'items__list':config?.fields.length === 1, 'row__list': config?.fields.length > 1 }">
        <div *ngFor="let item of config?.fields" [ngStyle]="{'width': item?.fullSize ? '300px' : 'auto'} || {'width': item?.mediumSize ? '300x' : 'auto'}">
            <div *ngIf="item.type === fields.CPF_CNPJ">
                <small *ngIf="item.showTitle">CNPJ/CPF*</small>
                <app-cpf-cnpj-field [form]="form" (enterEvent)="submit()"></app-cpf-cnpj-field>
            </div>

            <div *ngIf="verifyDefaultField(item)">
                <small>{{ item?.name | translate }}<span *ngIf="item?.required">*</span></small>
                <input class="form-control mb-3" [type]="item.type" placeholder="{{item.name  | translate }}"
                    name="field" [(ngModel)]="item.value">
            </div>

            <div *ngIf="item.type === fields.NUMBER">
                <small>{{ item?.name | translate }}<span *ngIf="item?.required">*</span></small>
                <input onlyNumbers class="form-control mb-3" type="text" placeholder="{{item.name  | translate }}"
                    name="field" [(ngModel)]="item.value" [maxlength]="item.max">
            </div>

            <div *ngIf="item.type === fields.MASKED">
                <small>{{ item?.name | translate }}<span *ngIf="item?.required">*</span></small>
                <input class="form-control mb-3" type="text" placeholder="{{item.name  | translate }}"
                    name="fieldMasked" [(ngModel)]="item.value" [mask]="item.mask">
            </div>

            <div class="mb-3" *ngIf="item.type === fields.YES_NO">
                <small>{{ item?.name | translate }}<span *ngIf="item?.required">*</span></small>
                <app-select [value]="item.data.value" [options]="item.data.options" (selection)="item.value = $event">
                </app-select>
            </div>

            <div class="mb-3" *ngIf="item.type === fields.COMBO">
                <small>{{ item?.name | translate }}<span *ngIf="item?.required">*</span></small>
                <app-select [value]="item.value" [options]="item.options" (selection)="item.value = $event">
                </app-select>
            </div>
            <div *ngIf="item.type === fields.CPF">
                <small *ngIf="item.showTitle && !item.name">CPF*</small>
                <small *ngIf="item.showTitle && item.name">{{item.name | translate}}*</small>
                <app-cpf-field [form]="form" [placeholder]="item.name"></app-cpf-field>
            </div>

            <div *ngIf="item.type === fields.LIST_TEXT">
                <strong>{{item.name | translate}}</strong>
                <small class="small__line" *ngFor="let item of item.list">{{item.text}}</small>
            </div>

            <div class="appcheck__list__item" *ngIf="item.type === fields.CHECKBOX">
                {{item?.name| translate }}
                <label class="switch switch-outline-primary-alt">
                    <input [(ngModel)]="item.value" name="checkbox" [checked]="item.value" class="switch-input"
                        type="checkbox"><span class="switch-slider"></span>
                </label>
            </div>

            <div class="appcheck__list__item" *ngIf="item.type === fields.TEXT_AREA">
              <textarea  class="form-control mb-3" placeholder="{{item.name  | translate }}"
                  name="field" [(ngModel)]="item.value"  rows="4" cols="50"></textarea>
            </div>
        </div>
    </div>
    <div class="btn__actions">
        <button type="button" class="next__button btn btn-info" (click)="close()">{{'buttons.back'
            | translate }}</button>
        <button id="emitItemBasicModalYes" type="button" class="next__button btn btn-success" (click)="submit()">{{(config.actionButton ? config.actionButton :'buttons.save')
            | translate }}</button>
    </div>
</div>
