import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CNPJValidator } from '../../utils/validations';
import { FormBuilder, Validators } from '@angular/forms';
import { APIS } from '../../enum/apis.enum';
import { HttpParams } from '@angular/common/http';
import { RequestService } from '../../services/request.service';
import { MaskPipe } from 'ngx-mask';
import { CompanyType } from '../../enum/company-type.enum';
import { Fields } from '../../enum/fields.enum';
import { CompanyModel } from '../../models/company-model';
import { BasicModalComponent } from '../basic-modal/basic-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RegexPatternsData } from '../../data/regex-patterns.data';


@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  @Input()
  companiesForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    fullName: ['', Validators.required],
    shortName: ['', Validators.required],
    cnpj: [null, Validators.required],
    ie: [null],
    dateCreated: [{ value: null, disabled: true }],
    isActive: [{ value: true, disabled: true }],
    isInternal: [{ value: false, disabled: true }],
    isDigitalSignature: [{ value: false, disabled: false }],
    userIdCreated: [{ value: null, disabled: true }],
    comments: ['']
  });
  @Input()
  addressForm = this.fb.group({
    addressStreet: [''],
    addressNumber: [''],
    addressComplement: [''],
    addressNeighborhood: [''],
    addressCity: [''],
    addressState: [''],
    addressPostalCode: [''],
    addressCountry: ['']
  });

  @Input()
  contactForm = this.fb.group({
    contacts: [''],
    phoneNumbers: [''],
    email: ['', Validators.email],
    webSite: ['']
  });

  @Input()
  companyType: string;

  @Input()
  companyReceived: CompanyModel;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  select = {
    value: null,
    label: 'global.ownerCompany',
    options: []
  };

  invalidCnpj = false;
  translatedType = 'global.';

  types = {
    CUSTODIAN: CompanyType.MASTER,
    ADMINISTRATOR: CompanyType.CUSTODIAN,
    MANAGER: CompanyType.ADMINISTRATOR,
    CONSULTANT: CompanyType.MANAGER,
    FUND: CompanyType.CONSULTANT,
    SHAREHOLDER: CompanyType.SHAREHOLDER
  };

  isDisabled = false;

  tabs = {
    main: true,
    parameterization: false,
  };



  constructor(private service: RequestService,
    private mask: MaskPipe,
    private fb: FormBuilder,
    private modal: BsModalService) { }

  async ngOnInit() {
    if (this.companyType) {
      this.translatedType = `${this.translatedType}${this.types[this.companyType].toLowerCase()}`

      if (this.companyReceived.id) {
        this.isDisabled = true;
        this.buildUpdateForm();
      } else {
        await this.listSelectItems();
      }
    }
  }

  private buildUpdateForm(): void {

    this.select.options.push({ value: this.companyReceived.selectId, label: this.companyReceived.selectLabel });

    this.select.value = this.companyReceived[`${this.types[this.companyType].toLowerCase()}Id`];
    this.companiesForm.patchValue(this.companyReceived);
    this.contactForm.patchValue(this.companyReceived);
    this.addressForm.patchValue(this.companyReceived);

    if (!this.companyReceived.allowUpdate) {
      this.companiesForm.disable();
      this.contactForm.disable();
      this.addressForm.disable();
    }
  }

  get formData() {
    return this.companiesForm.controls;
  }

  public validateCnpj(): void {

    const cnpj = this.formData['cnpj'];

    if (cnpj.value) {
      this.invalidCnpj = !(CNPJValidator(cnpj.value));

      if (this.invalidCnpj) {
        this.companiesForm.setErrors({ notUnique: true });
      } else {
        this.companiesForm.setErrors(null);
        this.getCompanyByCnpj();
      }
    }

  }

  public getCompanyByCnpj(): void {
    const cnpj = this.formData['cnpj'].value;

    this.service.list(`${APIS.COMPANY}${APIS.GET_BY_ID}-by-cnpj`, new HttpParams().append('cnpj', this.mask.transform(this.formData['cnpj'].value, `${Fields.CNPJ_MASK}`))).subscribe((response) => {
      if (response.model) {
        const company: CompanyModel = response.model;
        this.companiesForm.patchValue(company);
        this.addressForm.patchValue(company);
        this.contactForm.patchValue(company);
      } else {
        this.companiesForm.reset();
        this.formData['isActive'].setValue(true);
        this.formData['isInternal'].setValue(false);
        this.formData['isDigitalSignature'].setValue(false);
        this.formData['cnpj'].setValue(cnpj);
        this.addressForm.reset();
        this.contactForm.reset();
      }
    });
  }

  private async listSelectItems() {

    const api = APIS[this.types[this.companyType]];

    const response = await this.service.list(`${api}${APIS.GET}`, new HttpParams().append(Fields.SIZE, Fields.SIZE_DEFAULT)).toPromise();

    if (this.companyType === CompanyType.CUSTODIAN) {
      this.select.value = response.model.items[0].id;
    } else {
      response.model.items.map((item) => {
        item.value = item.id;
        item.label = item.company.shortName;
      });
      this.select.options = response.model.items;
    }
  }


  public save(): void {
    let onwerName;

    switch (this.companyType) {
      case CompanyType.CUSTODIAN:
        onwerName = 'masterId';
        break;
      case CompanyType.ADMINISTRATOR:
        onwerName = 'custodianId';
        break;
      case CompanyType.MANAGER:
        onwerName = 'administratorId';
        break;
      case CompanyType.CONSULTANT:
        onwerName = 'managerId';
        break;
      case CompanyType.FUND:
        onwerName = 'consultantId';
        break;
    }

    const company = this.companiesForm.getRawValue();

    delete company.userIdCreated;
    delete company.dateCreated;
    company.cnpj = this.mask.transform(this.formData['cnpj'].value, `${Fields.CNPJ_MASK}`)

    let request: any;

    request = {
      digitalSignature: this.formData['isDigitalSignature'].value,
      company: {
        ...company,
        ...this.contactForm.value,
        ...this.addressForm.value,
      },
      [onwerName]: this.select.value ? this.select.value : this.companyReceived[onwerName]
    };

    if (company.id) {
      request.companyId = this.companyReceived.companyId;
    } else {
      delete request.company.id;
    }

    if (this.companyReceived && this.companyReceived.itemId) {
      request.id = this.companyReceived.itemId;
    }

    if (this.companyType !== CompanyType.FUND) {
      delete request.digitalSignature;
    }

    this.action.emit(request);
  }

  public isSaveEnabled(): boolean {
    if (!this.companyReceived.allowUpdate
      || (this.companyType !== CompanyType.CUSTODIAN &&
        !this.select.value)) {
      return true;
    }
    return false;
  }

  public selectTab(tab) {

    this.tabs[tab] = true;

    const items = Object.keys(this.tabs);
    items.map((item) => {

      if (item !== tab) {
        this.tabs[item] = false;
      }

    });

  }

  public remove(event): void {
    event.deleteOption = true;
    this.action.emit(event);
  }

}
