import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { APIS } from '../../enum/apis.enum';
import { HttpParams } from '@angular/common/http';
import { Fields } from '../../enum/fields.enum';
import { FormBuilder, Validators } from '@angular/forms';
import { CompanyType } from '../../enum/company-type.enum';
import { Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { CompanyModel } from '../../models/company-model';

@Component({
  selector: 'app-subcompanies-form',
  templateUrl: './subcompanies-form.component.html',
  styleUrls: ['./subcompanies-form.component.scss']
})
export class SubcompaniesFormComponent implements OnInit {

  @Input()
  askCreation = true;

  @Input()
  companyType;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  onwerCompanyId: string;
  cnpj: string;

  list = {
    one: {
      selected: true
    },
    two: {
      selected: false
    }
  };

  select = {
    value: null,
    label: 'global.ownerCompany',
    options: this.service.getCompanyTypes()
  };

  companiesForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    fullName: ['', Validators.required],
    shortName: ['', Validators.required],
    cnpj: [null, Validators.required],
    ie: [null],
    dateCreated: [{ value: null, disabled: true }],
    isActive: [{ value: true, disabled: true }],
    isInternal: [{ value: false, disabled: true }],
    userIdCreated: [{ value: null, disabled: true }],
    comments: ['']
  });

  addressForm = this.fb.group({
    addressStreet: [''],
    addressNumber: [''],
    addressComplement: [''],
    addressNeighborhood: [''],
    addressCity: [''],
    addressState: [''],
    addressPostalCode: [''],
    addressCountry: ['']
  });

  contactForm = this.fb.group({
    contacts: [''],
    phoneNumbers: [''],
    email: ['', Validators.email],
    webSite: ['']
  });

  showForm = false;

  @Input()
  companyReceived: CompanyModel;

  types = {
    CUSTODIAN: CompanyType.MASTER,
    ADMINISTRATOR: CompanyType.CUSTODIAN,
    MANAGER: CompanyType.ADMINISTRATOR,
    CONSULTANT: CompanyType.MANAGER,
    FUND: CompanyType.CONSULTANT
  };

  name = 'global.companies';

  constructor(private service: RequestService,
    private fb: FormBuilder,
    private router: Router,
    private mask: MaskPipe) { }

  ngOnInit(): void {
    this.listSelectItems();

    if (this.companyReceived) {
      this.buildUpdateForm();
    }
  }


  private buildUpdateForm(): void {
    this.list = {
      one: {
        selected: false
      },
      two: {
        selected: false
      }
    };
    this.showForm = true;
    this.companiesForm.patchValue(this.companyReceived);
    this.contactForm.patchValue(this.companyReceived);
    this.addressForm.patchValue(this.companyReceived);

    if(!this.companyReceived.allowUpdate) {
      this.companiesForm.disable();
      this.contactForm.disable();
      this.addressForm.disable();
    }
  }

  public selectMenu(item: string): void {

    if (!this.onwerCompanyId) {
      return;
    }
    this.list = {
      one: {
        selected: false
      },
      two: {
        selected: false
      }
    };

    this.list[item].selected = true;
  }

  public selectItem(event) {
    this.onwerCompanyId = event;
  }

  private listSelectItems(): void {

    const api = APIS[this.types[this.companyType]];

    this.service.list(`${api}${APIS.GET}`, new HttpParams().append(Fields.SIZE, Fields.SIZE_DEFAULT)).subscribe((response) => {
      response.model.items.map((item) => {
        item.value = item.id;
        item.label = item.company.shortName;
      });

      this.select.options = response.model.items;
    });
  }

  public getCompanyByCnpj(): void {
    this.service.list(`${APIS.COMPANY}${APIS.GET_BY_ID}-by-cnpj`, new HttpParams().append('cnpj', this.mask.transform(this.cnpj, `${Fields.CNPJ_MASK}`))).subscribe((response) => {
      if (response.model) {
        const company = response.model;
        this.companiesForm.patchValue(company);
        this.addressForm.patchValue(company);
        this.contactForm.patchValue(company);
        this.foundAction();
        this.showForm = true;
      } else {
        this.noFoundAction();
      }
    });
  }

  get formData() {
    return this.companiesForm.controls;
  }

  private noFoundAction(): void {
    switch (this.companyType) {
      case CompanyType.CUSTODIAN:
        this.router.navigate([`/home/admin/companies/add/${this.cnpj}`]);
        break;
      case CompanyType.ADMINISTRATOR:
        this.router.navigate([`/home/admin/companies/add/${this.cnpj}`]);
        break;
      default:
        this.showForm = true;
        break;
    }
  }

  private foundAction(): void {
    switch (this.companyType) {
      case CompanyType.CUSTODIAN:
        this.disableForm();
        break;
      case CompanyType.ADMINISTRATOR:
        this.disableForm();
        break;
      default:
        if (this.formData['isInternal'].value) {
          this.disableForm();
        }
        this.showForm = true;
        break;
    }
  }

  private disableForm(): void {
    for (let control in this.companiesForm.controls) {
      this.companiesForm.controls[control].disable();
    }

    for (let control in this.addressForm.controls) {
      this.addressForm.controls[control].disable();
    }

    for (let control in this.contactForm.controls) {
      this.contactForm.controls[control].disable();
    }
  }

  public save(): void {

    let onwerName;

    switch (this.companyType) {
      case CompanyType.CUSTODIAN:
        onwerName = 'masterId';
        break;
      case CompanyType.ADMINISTRATOR:
        onwerName = 'custodianId';
        break;
      case CompanyType.MANAGER:
        onwerName = 'administratorId';
        break;
      case CompanyType.CONSULTANT:
        onwerName = 'managerId';
        break;
      case CompanyType.FUND:
        onwerName = 'consultantId';
        break;
    }

    if (!this.companyReceived && this.companyType === CompanyType.CUSTODIAN ||
      this.companyType === CompanyType.ADMINISTRATOR) {
      this.action.emit({
        companyId: this.formData['id'].value,
        [onwerName]: this.onwerCompanyId
      });
    } else {
      const company = this.companiesForm.getRawValue();

      delete company.userIdCreated;
      delete company.dateCreated;

      let request: any = {
        company: {
          ...company,
          ...this.contactForm.value,
          ...this.addressForm.value,
        },
        [onwerName]: this.onwerCompanyId ? this.onwerCompanyId : this.companyReceived[onwerName],
        companyId: company.id
      };

      if (this.companyReceived && this.companyReceived.id) {
        request.id = this.companyReceived.id;
      }

      this.action.emit(request);
    }
  }

}
