import { CoreModule } from './../../core/core.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { SelectAccessComponent } from './select-access/select-access.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
    declarations: [
        LoginComponent,
        UnauthorizedComponent,
        ActivateUserComponent,
        SelectAccessComponent,
        NotFoundComponent 
    ],
    imports: [
        SharedModule,
        CommonModule,
        CoreModule
    ],
    exports: [
        LoginComponent
    ],
    providers: [],
    bootstrap: []
})
export class AuthenticationModule { }
