<app-header [navbarBrandRouterLink]="['/home']" [fixed]="true" (click)="dispathResize()"
    [navbarBrandFull]="navbarBrandFull" [navbarBrandMinimized]="navbarBrandMinimized" [sidebarToggler]="'lg'"
    [asideMenuToggler]="false" [mobileAsideMenuToggler]="false" (toggleSidenav)="dispathResize()">

    <ul class="nav navbar-nav ml-auto">
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <app-translater (change)="setMenuLanguage()"></app-translater>
            <div>
                <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                    aria-expanded="false" dropdownToggle (click)="false">
                    <img src="assets/icons/user.svg" class="img-avatar" alt="Imagem de usuário" />
                </a>
                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

                    <div class="dropdown-header text-center"><strong> {{ 'global.welcome' | translate }}
                            {{ user?.username }}</strong></div>

                    <a class="dropdown-item">
                        <span (click)="openContextModal()">{{funds.length > 0 ? funds[0]?.name: ''}}</span><br /> <small
                            class="a__link" [tooltip]="popTemplateSide">{{'global.and' | translate}}
                            {{'global.more'| translate}}
                            {{funds.length}}</small>
                        <ng-template #popTemplateSide>
                            <div [innerHtml]="fundsHtml"></div>
                        </ng-template>
                    </a>
                    <a class="dropdown-item" (click)="changeAccess()"><i class="fa fa-user-circle-o"></i>
                        {{ 'global.access' | translate }}:
                        {{accessLabel | translate }}</a>
                    <a class="dropdown-item"><i class="fa fa-user-circle-o"></i> Meu Perfil</a>
                    <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> Sair</a>
                </div>
            </div>
        </li>
        <div class="access" id="fundTitleNavbar">
            <span (click)="openContextModal()">{{funds.length > 0 ? funds[0]?.name: ''}}</span> <small *ngIf="funds.length > 1" class="a__link"
                [tooltip]="popTemplate">...</small><br />

            <ng-template #popTemplate>
                <div [innerHtml]="fundsHtml"></div>
            </ng-template>

            <small *ngIf="!showLink">{{ 'global.access' | translate }}: {{accessLabel | translate }}</small>
            <small class="a__link" *ngIf="showLink" (click)="changeAccess()">{{ 'global.access' | translate }}:
                {{accessLabel | translate }}</small>
        </div>
    </ul>
</app-header>
<div class="app-body">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
        (minimizedChange)="toggleMinimize($event)">
        <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>

    </app-sidebar>

    <main class="main">
        <app-edge-breadcrumb></app-edge-breadcrumb>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
