<div class="companies">
    <div class="card fullwidth">
        <div class="card-header card-header--white"><strong>{{'global.mainData' | translate }}</strong>
        </div>
        <div class="card-body">
            <form #form="ngForm" [formGroup]="companiesForm">

                <div *ngIf="formData?.id?.value" class="update-items col-md-12">
                    <app-hidden-default-fields [hiddenForm]="companiesForm" [userId]="formData?.userIdCreated?.value">
                    </app-hidden-default-fields>
                </div>

                <div *ngIf="companyType && companyType !== 'CUSTODIAN' && companyType !== 'SHAREHOLDER' "
                    class="col-md-12">
                    <small>{{ translatedType | translate}}*</small>
                    <app-select [value]="select.value" [label]="translatedType" [options]="select.options"
                        (selection)="select.value = $event" [isDisabled]="isDisabled"></app-select>
                </div>

                <div class="col-md-4">
                    <small>CNPJ*</small>
                    <input class="form-control mb-3" type="text" placeholder="CNPJ" name="cnpj" formControlName="cnpj"
                        mask="00.000.000/0000-00" (blur)="validateCnpj()">
                    <small class="small__error" *ngIf="invalidCnpj">{{'global.cnpjWrong' | translate }}</small>
                </div>

                <div class="col-md-8">
                    <small>{{'global.socialReason' | translate }}*</small>
                    <input class="form-control mb-3" type="text" placeholder="{{'global.socialReason' | translate }}"
                        name="name" formControlName="fullName">
                </div>

                <div class="col-md-4">
                    <small>{{'global.shortName' | translate }}</small>
                    <input class="form-control mb-3" type="text" placeholder="{{'global.shortName' | translate }}"
                        name="shortName" formControlName="shortName">
                </div>

                <div class="col-md-4">
                    <small>I.E</small>
                    <input class="form-control mb-3" type="text" placeholder="I.E" name="ie" formControlName="ie">
                </div>

                <div class="col-md-1">
                    <div class="appcheck__list__item"> {{'global.isActive'| translate }}
                        <label class="switch switch-outline-primary-alt">
                            <input formControlName="isActive" name="isActive" [checked]="formData?.isActive?.value"
                                class="switch-input" type="checkbox"><span class="switch-slider"></span>
                        </label>
                    </div>
                </div>

                <div class="col-md-1">
                    <div class="appcheck__list__item"> {{'global.isInternal'| translate }}
                        <label class="switch switch-outline-primary-alt">
                            <input formControlName="isInternal" name="isInternal"
                                [checked]="formData?.isInternal?.value" class="switch-input" type="checkbox"><span
                                class="switch-slider"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-2" *ngIf="companyReceived?.showWitness">
                    <div class="appcheck__list__item"> {{'global.isDigitalSignature'| translate }}
                        <label class="switch switch-outline-primary-alt">
                            <input formControlName="isDigitalSignature" name="isDigitalSignature"
                                [checked]="formData?.isDigitalSignature?.value" class="switch-input"
                                type="checkbox"><span class="switch-slider"></span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card_row">
        <div class="card middle-form">
            <div class="card-header card-header--white"><strong>{{'global.contact' | translate }}</strong>
            </div>
            <div class="card-body">
                <app-contact [form]="contactForm"></app-contact>
            </div>

        </div>
        <div class="card middle-form">
            <div class="card-header card-header--white"><strong>{{'global.address' | translate }}</strong>
            </div>
            <div class="card-body">
                <app-address [form]="addressForm"></app-address>
            </div>
        </div>
    </div>
    <div class="card fullwidth">
        <div class="card-header card-header--white"><strong>{{'global.additional' | translate }}</strong>
        </div>
        <div class="card-body">
            <form #form="ngForm" [formGroup]="companiesForm">
                <div class="col-md-12">
                    <small>{{'global.observations' | translate }}</small>
                    <textarea name="comments" formControlName="comments" class="form-control mb-3" name="comments"
                        placeholder="{{'global.observations' | translate }}"></textarea>
                </div>
            </form>
        </div>
    </div>
</div>
<app-btn-actions *ngIf="companyType" [form]="companiesForm" (save)="save()" [disabled]="isSaveEnabled()">
</app-btn-actions>
