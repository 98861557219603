<div class="contact-list">
  <div class="card middle-form">
    <div class="card-header card-header--white"><strong>{{'global.addGuarantors' | translate }}</strong></div>
    <div class="contact__form">
      <form class="row" [formGroup]="form">
        <div class="col-md-2 col-sm-12">
          <small>CNPJ/CPF*</small>
          <app-cpf-cnpj-field [form]="form"></app-cpf-cnpj-field>
        </div>

        <div class="col-md-4 col-sm-12">
          <small>{{'global.name' | translate }}*</small>
          <input class="form-control mb-3" type="text" placeholder="{{'global.name' | translate }}" name="name"
            formControlName="name">
        </div>


        <div class="col-md-3 col-sm-12">
          <small>{{'global.email' | translate }}</small>
          <input class="form-control mb-3" type="text" placeholder="{{'global.email' | translate }}"
          name="email" formControlName="email" maxlength="100" [pattern]="patterns.EMAIL_PATTERN">
        </div>

        <div class="col-md-2 col-sm-4" style="margin-top: 20px;">
          <div>
            <button type="button" class="btn btn-primary" [disabled]="form.invalid || !canEnableButtons"
              (click)="addItem()">{{'global.add' | translate }}</button>
          </div>
        </div>

      </form>
    </div>
  </div>

  <div class="card middle-form">
    <div class="card-header card-header--white"><strong>{{'global.existingGuarantors' | translate }}</strong></div>

    <div class="table__items">
      <app-basic-commands [total]="config.total" (search)="search($event)" [showNew]="false"></app-basic-commands>

      <app-table [config]="config" (edit)="editItem($event)" (remove)="removeItem($event)"></app-table>
    </div>
  </div>





</div>
