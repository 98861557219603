
import { Injectable } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import { Fields } from '../enum/fields.enum';

@Injectable()
export class TransformService {

    CPF_LENGTH = 11;
    constructor(private mask: MaskPipe) { }

    public transformDocument(document): string {
        if (document) {
            document = document.replace(/[^\d]+/g, '');
            if (document.length > 11) {
                return this.mask.transform(document, `${Fields.CNPJ_MASK}`);
            } else {
                return this.mask.transform(document, `${Fields.CPF_MASK}`);
            }
        }
    }
}
