export class ClaimsModel {
    ACTIVE_USER: boolean;
    REMOVE_COMPANY: boolean;
    WRITE_COMPANY: boolean;
    LIST_COMPANY: boolean;
    LIST_ROLE: boolean;
    REMOVE_ROLE: boolean;
    WRITE_USER: boolean;
    REMOVE_USER: boolean;
    LIST_USER: boolean;
    WRITE_ROLE: boolean;
    LIST_BANK: boolean;
    REMOVE_BANK: boolean;
    WRITE_BANK: boolean;
    UPDATE_BANK: boolean;
    UserId: string;
    LogoutId: string;
    exp: string;
    iss: string;
    aud: string;



    LIST_ADMINISTRATOR: boolean;
    REMOVE_ADMINISTRATOR: boolean;
    WRITE_ADMINISTRATOR: boolean;

    REMOVE_FUND: boolean;
    LIST_FUND: boolean;
    WRITE_FUND: boolean;

    WRITE_MANAGER: boolean;
    LIST_MANAGER: boolean;
    REMOVE_MANAGER: boolean;


    WRITE_MASTER: boolean;
    REMOVE_MASTER: boolean;
    LIST_MASTER: boolean;

    WRITE_CUSTODIAN: boolean;
    REMOVE_CUSTODIAN: boolean;
    LIST_CUSTODIAN: boolean;

    WRITE_CONSULTANT: boolean;
    LIST_CONSULTANT: boolean;
    REMOVE_CONSULTANT: boolean;

    WRITE_HOLIDAY: boolean;
    LIST_HOLIDAY: boolean;
    REMOVE_HOLIDAY: boolean;

    WRITE_SELLER: boolean;
    LIST_SELLER: boolean;
    REMOVE_SELLER: boolean;

    WRITE_SELLERBLOCK: boolean;
    WRITE_SELLERUNBLOCK: boolean;

    LIST_OPERATIONS: boolean;
    WRITE_OPERATIONS: boolean;
    REMOVE_OPERATIONS: boolean;

    LIST_SHAREHOLDER: boolean;
    WRITE_SHAREHOLDER: boolean;
    REMOVE_SHAREHOLDER: boolean;

    WRITE_CONTRACT_MASTER: boolean;

    LIST_DRAWEE: boolean;
    WRITE_DRAWEE: boolean;
    REMOVE_DRAWEE: boolean;

    LIST_RISK_OTHER_FUNDS: boolean;
    LIST_DILIGENCE_EXTERNAL_SOURCE: boolean;
    LIST_LEGAL: boolean;

    LIST_REPORT : boolean;

    WRITE_OPERATIONS_CONSULTANT_APPROVEMENT: boolean;
    WRITE_OPERATIONS_MANAGER_APPROVEMENT: boolean;
    WRITE_REPORTFILE_ACQUISITION: boolean;
    WRITE_REPORTFILE_STOCK: boolean;


    WRITE_CONSIGNMENTFILE: boolean;
    LIST_CONSIGNMENTFILE: boolean;
    REMOVE_CONSIGNMENTFILE: boolean;

    WRITE_REPORTFILE_LIQUIDATE: boolean;
    LIST_REPORTFILE_LIQUIDATE: boolean;
    REMOVE_REPORTFILE_LIQUIDATE: boolean;

    LIST_CERTIFYING: boolean;
    WRITE_CERTIFYING: boolean;

    LIST_TEMPLATE: boolean;
    WRITE_TEMPLATE: boolean;
    REMOVE_TEMPLATE: boolean;

    LIST_FUNDNOTIFICATION: boolean;
    WRITE_FUNDNOTIFICATION: boolean;
    REMOVE_FUNDNOTIFICATION: boolean;

    LIST_LIQUIDACAO: boolean;
    WRITE_LIQUIDACAO: boolean;
    REMOVE_LIQUIDACAO: boolean;

    WRITE_SHAREHOLDERANALYSIS: boolean;
    WRITE_SHAREHOLDERPENDENCY: boolean;
    WRITE_SHAREHOLDERSIGN: boolean;
    WRITE_SHAREHOLDERAwaitingActivation: boolean;
    WRITE_SHAREHOLDERACTIVATE: boolean;
    WRITE_SHAREHOLDERCANCEL: boolean;
    WRITE_SHAREHOLDERBLOCK: boolean;
    WRITE_SHAREHOLDERUNBLOCK: boolean;
    WRITE_SHAREHOLDERRESEND: boolean;

    constructor(claims) {
        delete claims.UserId;
        delete claims.exp;
        delete claims.iss;
        delete claims.aud;
        delete claims.LogoutId;
        return claims;
    }
}
