import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TableConfig } from '../../models/table-config.model';

@Component({
  selector: 'app-material-table-modal',
  templateUrl: './material-table-modal.component.html',
  styleUrls: ['./material-table-modal.component.scss']
})
export class MaterialTableModalComponent implements OnInit, OnDestroy {
  config: TableConfig;

  title: string;

  onClose: Subject<any>;

  showSave: boolean;

  constructor() {
    this.onClose = new Subject();
  }
  
  ngOnDestroy(): void {
    this.onClose.next(true);
  }

  ngOnInit(): void {
  }

  public actionEvent(event): void {

  }

  public action(): void {
    this.onClose.next(this.config.rows);
  }
}
