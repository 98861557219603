
  <div class="card">
    <div class="card-body">
      <div class="h4 m-0">{{records}} {{'global.records' | translate}}</div>
      <div>{{'global.bankruptcyMovement' | translate}} - {{'global.'+consultation | translate}}</div>
      <div class="progress progress-xs my-3">
        <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div>
        <small class="text-muted">{{'global.consultationPerform' | translate}} {{dateConsultationFormat()}}</small>
        <button class="btn" (onClick)="onDetails($event)"><i class="fa fa-search-plus"></i></button>
      </div>
    </div>
  </div>
