
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CNPJValidator, CPFValidator } from '../../utils/validations';

enum Mask {
  CPF = '000.000.000-00'
}

@Component({
  selector: 'app-cpf-field',
  templateUrl: './cpf-field.component.html',
  styleUrls: ['./cpf-field.component.scss']
})

export class CpfFieldComponent implements OnInit {

  @Input()
  form = this.fb.group({
    cpf: [null, Validators.required]
  });


  mask = Mask.CPF;

  validator = false;

  @Input()
  placeholder = 'CPF'
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {
    if(this.placeholder === undefined){
      this.placeholder = 'CPF'
    }
    const document = this.formData['cpf'].value ? this.formData['cpf'].value.replace(/[^\d]+/g, '') : null;

    if (document && document.length <= 11) {
      this.mask = Mask.CPF;
    }


    this.form.get("cpf").valueChanges.subscribe(field => {
      if (field && field.length <= 11) {
        this.mask = Mask.CPF;
      }

    });
  }

  public emitItem(): void {

    const field = this.formData['cpf'].value;

    if (field) {

      switch (field.length) {
        case 11:
          this.validator = !(CPFValidator(field))
          break;
        default:
          this.form.setErrors({ notUnique: true });
          this.validator = true;
          return;
      }

      if (this.validator) {
        this.form.setErrors({ notUnique: true });
      } else {
        this.form.setErrors(null);
        this.formData['cpf'].setErrors(null)
      }
    }
  }


  get formData() {
    return this.form.controls;
  }

}
