<div class="translater">
    <app-translater></app-translater>
</div>
<div class="login">

    <div class="login__img__container" [ngClass]="{'id__company': isIdcompany, 'edge__company': !isIdcompany}">
        <img [src]="logoImg">
    </div>


    <div class="login_container access__type__container">
        <img class="logo__forgot" [src]="logoImg">
        <h1>{{'global.accessType' | translate }}</h1>
        <label>{{'global.accessChooseType' | translate }}
        </label>
        <div class="access__type">
            <div *ngFor="let item of accesses; trackBy: trackByFn" (click)="selectAccessType(item)">
                <i class="{{item.icon}}"></i> {{item.label | translate}}
            </div>
        </div>

        <label class="container-check maintainType mb-3">
            <input [(ngModel)]="maintainType" type="checkbox" name="maintainType" id="maintainType">
            <span class="checkmark"></span>
            <label for="remeber">{{'global.maintainType' | translate }}</label>
        </label>

    </div>
</div>
