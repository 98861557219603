import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Fields } from './shared/enum/fields.enum';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'front-end';


  constructor(@Inject(DOCUMENT) private document: HTMLDocument,
    private titleService: Title) {

  }

  ngOnInit(): void {
    if (window.location.href.includes(Fields.IDSF)) {
      this.document.getElementById('appFavicon').setAttribute('href', 'assets/icons/favicon.ico');
      this.titleService.setTitle('Macaw - Fintech');
    } else {
      this.document.getElementById('appFavicon').setAttribute('href', 'assets/icons/favicon.ico');
      this.titleService.setTitle('Macaw - FIntech');
      // this.titleService.setTitle('Black Arrow Capital');
    }
  }
}
