<div class="items">
    <div *ngFor="let item of config; trackBy: trackByFn"
        class="item col-6 col-lg-4 card-body p-2 d-flex align-items-center">
        <div [ngStyle]="{'background-color': item.color}" class="image py-1 px-1 mfe-1">
            <img [src]="item.icon">
        </div>
        <div class="items__data">
            <div class="text-value" [ngStyle]="{'color': item.color}">
            <span *ngIf="item.money === true">{{'global.moneyFormat' | translate}}</span>
            {{returnFormat(item.value)}}
        </div>
            <div class="text-muted text-uppercase font-weight-bold small">{{item.label | translate}} <span *ngIf="item.percent !== null"
                    [ngStyle]="{'color': item.color}"> {{item.percent | translate}}</span></div>
        </div>
    </div>
</div>
