import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from '../../models/table-config.model';
import { ColumnsType } from '../../enum/columns-types.enum';
import { MaskPipe } from 'ngx-mask';
import { BasicModalComponent } from '../basic-modal/basic-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Fields } from '../../enum/fields.enum';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { CPFValidator } from '../../utils/validations';
import { RegexPatternsData } from '../../data/regex-patterns.data';
import { TableActionsEnum } from '../../enum/table-actions.enum';


enum PhoneMaskEnum {
  PHONE = '(00)0000-00000',
  CELLPHONE = '(00)00000-0000'
}

@Component({
  selector: 'app-representative-list',
  templateUrl: './representative-list.component.html',
  styleUrls: ['./representative-list.component.scss']
})
export class RepresentativeListComponent implements OnInit, OnChanges {

  @Input()
  representativesList = [];

  @Output()
  save = new EventEmitter<any>();

  @Output()
  remove = new EventEmitter<any>();

  form = this.fb.group({
    id: [null],
    sellerFundId: [null],
    cpf: [null, Validators.required],
    name: [null, Validators.required],
    email: [null, [Validators.email, Validators.required]],
    signIsolately: [null, [Validators.required]],
    createDuplicate: [null, [Validators.required]],
    signEndorsement: [null, [Validators.required]],
    signConcession: [null, [Validators.required]],
    phone: [null, [Validators.required]],
  });

  config: TableConfig = {
    columns: [
      { header: 'name' },
      { header: 'cpf' },
      { header: 'email' },
      { header: 'phone' },
      { header: 'signConcession', type: ColumnsType.BOOLEAN },
      { header: 'signIsolately', type: ColumnsType.BOOLEAN },
      { header: 'signEndorsement', type: ColumnsType.BOOLEAN },
      { header: 'createDuplicate', type: ColumnsType.BOOLEAN },
      { header: 'dateCreated', type: ColumnsType.DATE },
    ],
    rows:
      [],
    limit: 100,
    total: 0,
    page: 0,
    showLimit: false,
    options: {
      actions: [
        {
          label: 'fields.edit',
          type: TableActionsEnum.EDIT,
          icon: 'fa fa-edit',
          class: 'btn click__item--blue',
          enabled: true
        },
        {
          label: 'fields.remove',
          type: TableActionsEnum.REMOVE,
          icon: 'fa fa-trash',
          class: 'btn click__item--red',
          enabled: true
        }
      ]
    }
  };

  selectEndorsement = {
    value: null,
    label: null,
    options: [
      {
        label: 'fields.yes',
        value: true
      },
      {
        label: 'fields.no',
        value: false
      }
    ]
  };

  phone: string;
  tmpRows = [];

  fields = Fields;

  patterns = RegexPatternsData;

  maskPhone = PhoneMaskEnum.PHONE;

  invalidCpf = false;
  constructor(public fb: FormBuilder,
    private mask: MaskPipe,
    private modal: BsModalService,
    private confirm: ConfirmDialogService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.representativesList && changes.representativesList.currentValue) {
      this.config.rows = changes.representativesList.currentValue;
      this.config.total = this.config.rows.length;
      this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    }
  }
  ngOnInit(): void {
    this.config.rows = this.representativesList;
    this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
  }

  public addItem(): void {

    const form = this.form.value;

    form.cpf = this.mask.transform(form.cpf, `${Fields.CPF_MASK}`);

    if (form.phone.length > 10) {
      form.phone = this.mask.transform(form.phone, PhoneMaskEnum.CELLPHONE);
    } else {
      form.phone = this.mask.transform(form.phone, PhoneMaskEnum.PHONE);
    }
    this.phone = null;
    this.form.reset();
    this.save.emit(form);
  }

  public editItem(item): void {
    const modalRef = this.modal.show(BasicModalComponent,
      {
        class: 'modal-dialog-centered',
        initialState: {
          config: {
            title: 'menu.updateRepresentative',
            // message: 'global.updateDataButton',
            actionButton: 'buttons.update',
            result: item,
            fields: [
              {
                type: Fields.CPF,
                value: item.cpf,
                showTitle: true,
                required: true
              },
              {
                name: 'global.name',
                type: Fields.TEXT,
                value: item.name,
                fullSize: true,
                required: true
              },
              {
                name: 'global.email',
                value: item.email,
                type: Fields.EMAIL,
                required: true
              },
              {
                name: 'global.phone',
                value: item.phone,
                type: Fields.MASKED,
                mask: '(00)00000-0000',
                required: true
              },
              {
                name: 'global.signConcession',
                value: item.signConcession,
                type: Fields.YES_NO,
                required: true
              },
              {
                name: 'global.signIsolately',
                value: item.signIsolately,
                type: Fields.YES_NO,
                required: true
              },
              {
                name: 'global.signEndorsement',
                value: item.signEndorsement,
                type: Fields.YES_NO,
                required: true
              },
              {
                name: 'global.createDuplicate',
                value: item.createDuplicate,
                type: Fields.YES_NO,
                required: true
              }
            ]
          }
        }
      });

    modalRef.setClass('modal-lg');
    modalRef.content.onClose.subscribe((response) => {
      let error = false;
      if (response.fields.length > 0) {

        response.fields.map((itens) => {
          if (itens.required) {
            if (itens.type !== Fields.YES_NO  && (!itens.value || itens.value.trim() == '')) {
              error = true;
            }
            if (itens.type === Fields.EMAIL && !(new RegExp(this.patterns.EMAIL_PATTERN).test(itens.value))){
              error = true;
            }
          }
        });


        if (!error) {
          const request = {
            ...response.result,
            cpf: response.fields[0].value,
            name: response.fields[1].value,
            email: response.fields[2].value,
            signConcession: response.fields[4].value,
            signIsolately: response.fields[5].value,
            signEndorsement: response.fields[6].value,
            createDuplicate: response.fields[7].value,
            phone: response.fields[3].value.lenth > 10 ? this.mask.transform(response.fields[3].value, '(00)00000-0000') : this.mask.transform(response.fields[3].value, '(00)00000-0000')
          }

          this.save.emit(request);
          modalRef.hide();
        }
      }
    });
  }

  public removeItem(item): void {
    const option = this.confirm.openDialog(null);

    option.subscribe((op) => {
      if (item.id) {
        this.remove.emit(item);
      }
    });
  }

  get formData() {
    return this.form.controls;
  }

  public search(item: string): void {

    if (!item || item === '') {
      this.config.rows = JSON.parse(JSON.stringify(this.tmpRows));
      this.config.total = this.config.rows.length;
    } else {
      item = item.toLowerCase();
      this.config.rows = this.tmpRows.filter((r) => r.name.toLowerCase().includes(item) || r.cpf.replace(/[^\d]+/g, '').includes(item) || r.email.toLowerCase().includes(item));
      this.config.total = this.config.rows.length;
    }
  }

  public validateCpf(): void {

    if (this.formData['cpf'].value) {
      this.invalidCpf = !CPFValidator(this.formData['cpf'].value);
    }

  }


  public changeMask(): void {
    const field = this.formData['phone'].value;
    if (field && field.length > 10) {
      this.maskPhone = PhoneMaskEnum.CELLPHONE;
    } else {
      this.maskPhone = PhoneMaskEnum.PHONE;
    }
  }

  public verifyMask(): void {
    const control = this.formData['phone'];
    if (control.value.length === 10) {
      control.setErrors(null);
    }
  }

  public setPhone(): void {

    const phoneValidate = this.phone.replace(/[^\d]+/g, '');

    if (phoneValidate && (phoneValidate.length === 10 || phoneValidate.length === 11)) {
      this.formData['phone'].setValue(this.phone);
    }
  }

}
