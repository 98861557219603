import { NgZone, Renderer2, Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[focusDirective]'
})
export class FocusDirective {
    @Input() cssSelector: string
    inputElement: ElementRef;

    constructor(
        private ngZone: NgZone,
        private renderer: Renderer2,
        public el: ElementRef
    ) {
        this.inputElement = el.nativeElement;
    }

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.renderer.selectRootElement(this.cssSelector).focus();
            }, 20);
        });
    }
}