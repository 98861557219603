import { Component, OnInit, Input } from '@angular/core';
import { FieldsTypes } from '../../enum/fields-types.enum';
import { trackByFn } from '../../utils/track-by';
import { Columns } from '../../models/columns.model';
import { ColumnsType } from '../../enum/columns-types.enum';
import { Fields } from '../../enum/fields.enum';
import { DatePipe } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-basic-list-items',
  templateUrl: './basic-list-items.component.html',
  styleUrls: ['./basic-list-items.component.scss']
})
export class BasicListItemsComponent implements OnInit {

  @Input()
  config;

  trackByFn = trackByFn;
  fieldsType = FieldsTypes;
  constructor(private datePipe: DatePipe,
              private translatePipe: TranslatePipe,
              private translateService: TranslateService,
              private currency: CurrencyPipe) { }

  ngOnInit(): void {
  }

    public buildRow(column: Columns, item) {
    switch (column.type) {
      case ColumnsType.DATE:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_PATTERN_US);
        }
      case ColumnsType.BOOLEAN:
        return this.buildBooleanValue(item);
      case ColumnsType.TYPE:
        return this.translatePipe.transform(item);
      case ColumnsType.CURRENCY:
        return this.currency.transform(item, this.returnCurrencyType());
      default:
        if (!!item) {
          return item;
        }
        return this.translatePipe.transform(Fields.NOT_REGISTRED);
    }
  }

  private buildBooleanValue(value: boolean): string {

    let field = Fields.YES;
    if (!value) {
      field = Fields.NO;
    }
    return this.translatePipe.transform(field);
  }

  public returnCurrencyType(): string{
    return this.translateService.instant('global.currency')
  }

}
