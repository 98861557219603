export enum Messages  {
    SUCESS = 'S',
    SUCESS_DEFAULT = 'SUCESS',

    WARN = 'W',
    INFO = 'I',

    ERROR = 'E',
    ERROR_DEFAULT = 'ERROR',

    NO_COMPANY_ABLE = 'NO_COMPANY_ABLE',
};