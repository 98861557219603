import { Component, OnInit } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FieldsTypes } from '../../enum/fields-types.enum';
import { ColumnsType } from '../../enum/columns-types.enum';
import { trackByFn } from '../../utils/track-by';
import { Subject } from 'rxjs';
import { Messages } from '../../enum/messages.enum';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-confirm-dialog-field',
  templateUrl: './confirm-dialog-field.component.html',
  styleUrls: ['./confirm-dialog-field.component.scss']
})
export class ConfirmDialogFieldComponent implements OnInit {

  message: string;

  title: string;

  field: any = {
    type: null,
    name: null,
    sellerOption: null,
    value: null
  };
  fields = [];

  showCertifier = false;

  certifys;

  digitalSignature = true;

  fieldsTypes = FieldsTypes;
  columnsType = ColumnsType;

  public onClose: Subject<any>;

  trackByFn = trackByFn;

  constructor(public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    private messages: MessageService) { }

  ngOnInit(): void {
    this.onClose = new Subject();

    if(this.field.type === FieldsTypes.LIST_INPUTS) {
      this.field.value = true;
    }
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public onYes(): void {

    if(this.field.type === FieldsTypes.LIST_INPUTS) {
      this.onClose.next(this.fields);
      this.bsModalRef.hide();
     return;
    }

    const tempFields = JSON.parse(JSON.stringify(this.fields));

    if (tempFields && tempFields.length > 0) {
      tempFields.push(this.field);

      let validator = false;

      tempFields.map((values) => {
        if (values.value === null || values.value === '') {
          validator = true;
        }
      });
      if (validator) {
        return;
      } else {
        this.field = tempFields;
      }
    }

    if(this.field.sellerOption == 'active'){
      this.field.IsToSentCertifying = this.digitalSignature;
      this.field.Certifyingid = this.certifys.id;

      if(this.digitalSignature == true && this.certifys.isIntegrated == false && this.certifys.certifierName != "-"){
       this.messages.show("CERTIFIER_LINKED_NOT_INTEGRATED", Messages.ERROR);
      }else if(this.certifys.certifierName == "-" && this.certifys.isIntegrated == false && this.digitalSignature == true){
        this.messages.show("CERTIFER_NOT_LINKED", Messages.ERROR);
      }else{
        this.onClose.next(this.field);
       this.bsModalRef.hide();
     }
    }else{
      this.onClose.next(this.field);
      this.bsModalRef.hide();
    }

  }

  public listInputItemsValidation(): boolean {

    if(this.field.type !== FieldsTypes.LIST_INPUTS) {
      return false
    }

    const item = this.fields.find((item) => item.value !== null && item.value !== '');
    if(item) {
      return false;
    }
    return true;
  }//asdasdasd

  public valueField(): boolean{
    if(this.field.type === FieldsTypes.TEXT_AREA && this.field.shareholderOption === "resend"){
      return false;
    }else{
      return !this.field.value;
    }
  }
}
