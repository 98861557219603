<div class="contact-list">
  <div class="card middle-form">
    <div class="card-header card-header--white"><strong>{{'global.addBankAccount' | translate }}</strong></div>
      <div class="contact__form">
        <form class="row" [formGroup]="form">

          <div class="col-md-2 col-sm-12">
              <small>{{'global.bank' | translate }}*</small>
              <app-select [value]="formData['bankId'].value" [options]="selectBank.options"
                  (selection)="formData['bankId'].setValue($event)"></app-select>
          </div>

          <div class="col-md-2 col-sm-12">
              <small>{{'global.agencyNumbersonly' | translate }}*</small>
              <input onlyNumbers class="form-control mb-3" type="text" placeholder="{{'global.agency' | translate }}" name="agency"
                  formControlName="agency" maxlength="4">

          </div>


          <div class="col-md-2 col-sm-12">
              <small>{{'global.currentsAccountNumbersonly' | translate }}*</small>
              <input class="form-control mb-3" type="text" placeholder="{{'global.currentAccount' | translate }}" name="account"
                  formControlName="account" onlyNumbers maxlength="7">

          </div>

          <div class="col-md-3 col-sm-12">
              <small>{{'global.description' | translate }}*</small>
              <input class="form-control mb-3" type="text" placeholder="{{'global.description' | translate }}" name="description"
                  formControlName="description">
          </div>


          <div class="col-md-2 col-sm-12">
              <small>{{'global.pattern' | translate }}*</small>
              <app-select [value]="formData['pattern'].value" [options]="selectDefault.options"
                  (selection)="formData['pattern'].setValue($event)"></app-select>
          </div>

          <div class="col-md-1 col-sm-4" style="margin-top: 20px;">
            <div>
              <button type="button" class="btn btn-primary" [disabled]="form.invalid || !canEnableButtons" (click)="addItem()">{{'global.add' | translate }}</button>
            </div>
          </div>
      </form>
      </div>
  </div>

  <div class="card middle-form">
    <div class="card-header card-header--white"><strong>{{'global.existingBankAccount' | translate }}</strong></div>
    <div class="card middle-form">
      <div class="table__items">
          <app-basic-commands [total]="config.total" (search)="search($event)" [showNew]="false"></app-basic-commands>

          <app-table [config]="config" (edit)="editItem($event)" (remove)="removeItem($event)"></app-table>
      </div>
  </div>
  </div>

</div>
