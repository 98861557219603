<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'global.confirm' | translate}}</h5>
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ message | translate}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">{{ 'fields.no' |
            translate}}</button>
        <button type="button" class="btn btn-primary" (click)="onYes()">{{ 'fields.yes' | translate}}</button>
    </div>
</div>