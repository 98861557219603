import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { RatingEnum } from '../../enum/rating-status.enum';

@Component({
  selector: 'app-rating-compass',
  templateUrl: './rating-compass.component.html',
  styleUrls: ['./rating-compass.component.scss']
})
export class RatingCompassComponent implements OnInit, AfterViewInit {

  @Input()
  status = RatingEnum.REGULAR;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    switch (this.status) {
      case RatingEnum.REGULAR: 
      this.returnElement().style.transform = 'rotate(0deg)'
      break;
      case RatingEnum.GOOD: 
      this.returnElement().style.transform = 'rotate(-70deg)'
      break;
      case RatingEnum.BAD: 
      this.returnElement().style.transform = 'rotate(70deg)'
      break;
    }
  }

  private returnElement(): any {
    return document.getElementById('comp');
  }

}
