<div class="modal-content">
    <div class="modal-header">
        <h2 class="modal-title">{{ title | translate}}</h2>
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ message | translate}}


        <div class="flex__row--spaced">
            <div *ngFor="let item of fields; trackBy: trackByFn">
                <div class="col-md-12" *ngIf="item.type === columnsType.DATE">
                    <small>{{item.label | translate}}</small>
                    <input type="text" placeholder="{{item.label | translate}}" class="form-control" bsDatepicker
                        [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' }" name="value"
                        [(ngModel)]="item.value">
                </div>
                <div class="col-md-12" *ngIf="item.type === columnsType.BOOLEAN">
                    <div class="appcheck__list__item"> {{item.label | translate}}
                        <label class="switch switch-outline-primary-alt">
                            <input name="booleanValue" [(ngModel)]="item.value" [checked]="item.value"
                                class="switch-input" type="checkbox"><span class="switch-slider"></span>
                        </label>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="showCertifier">
            <a>{{'global.sendDigitalSignature' | translate}} </a>
              <label class="switch">
               <input type="checkbox" [checked]="digitalSignature" [(ngModel)]="digitalSignature">
               <span class="slider round"></span>
              </label><br>
           <a *ngIf="digitalSignature == true">{{'global.certifier' | translate }}: {{certifys.certifierName}} </a>
           <a *ngIf="digitalSignature == false">{{'global.signatureProcedureManual' | translate }}</a>
      </div>
        <div class="textarea__div" *ngIf="field.type === fieldsTypes.TEXT_AREA">
            <label for="textarea_focused">{{field.name | translate }}:</label>
            <textarea [(ngModel)]="field.value" name="value" id="textarea_focused" focusDirective cssSelector="#textarea_focused"
                (keyup.enter)="onYes()" maxlength="500" placeholder="{{field.placeholder ? (field.placeholder | translate) : ''}}">

            </textarea>
        </div>

        <div *ngIf="field.type === fieldsTypes.LIST_INPUTS">
            <label>{{field.name | translate }}</label>
            <div class="list__inputs" *ngFor="let item of fields; trackBy: trackByFn">
                <label for="textarea_focused">{{item.name | translate }}</label>
                <input type="text" placeholder="{{item.label | translate}}" class="form-control" name="value"
                    [(ngModel)]="item.value">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">{{ 'buttons.back' |
            translate}}</button>
        <button type="button" class="btn btn-primary" (click)="onYes()"
            [disabled]="valueField() || listInputItemsValidation()">{{ 'buttons.save' | translate}}</button>
    </div>
</div>
