<div class="check__list">
    <div class="search">
        <label>
            {{'global.searchFor' | translate}}
        </label>
        <select [(ngModel)]="value" (change)="selectItem($event.target.value)" class="select form-control" id="select"
            name="select">
            <option [value]="null" *ngIf="select.label">{{ select.label | translate }}</option>
            <option *ngFor="let op of select.options; trackBy: trackByFn" [value]="op.value">
                {{op.label | translate}}</option>
        </select>

        <input placeholder="{{'fields.search' | translate}}" (keyup)="onSearch($event)" [(ngModel)]="search"
            name="search">
    </div>
    <label class="check-list__title">{{'global.' + config.title.toLowerCase() | translate}}</label>
    <div class="check-list">
        <div class="check-list__items" *ngFor="let permitions of filteredItems; trackBy: trackByFn">
            <label class="container-check">{{permitions.label}}
                <input [(ngModel)]="permitions.value" type="checkbox" [checked]="permitions.value"
                    (change)="permitions.lastChecked = true;onSelection()">
                <span class="checkmark"></span>
                <div class="check__list__hierarchy">
                    <div *ngFor="let hierarchy of permitions?.hierarchy">
                        <div *ngIf="hierarchy.visible">
                            <small>
                                {{hierarchy.group | translate}}:
                            </small>
                            <small>
                                {{hierarchy.name}}
                            </small>
                        </div>
                    </div>
                </div>
            </label>
        </div>
    </div>
</div>