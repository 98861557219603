import { Component, OnInit, Input } from '@angular/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-values-detail-list',
  templateUrl: './values-detail-list.component.html',
  styleUrls: ['./values-detail-list.component.scss']
})
export class ValuesDetailListComponent implements OnInit {

  @Input()
  config: any[] = [];

  trackByFn = trackByFn;
  constructor() { }

  ngOnInit(): void {
  }

}
