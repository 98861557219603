import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RequestService } from './../../services/request.service';
import { ConfirmDialogService } from './../../services/confirm-dialog.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from 'src/app/shared/models/table-config.model';
import { ColumnsType } from 'src/app/shared/enum/columns-types.enum';
import { APIS } from 'src/app/shared/enum/apis.enum';

@Component({
  selector: 'app-historic-list',
  templateUrl: './historic-list.component.html',
  styleUrls: ['./historic-list.component.scss']
})
export class HistoricListComponent implements OnInit, OnChanges {

  @Input()
  historic = [];

  @Output()
  details = new EventEmitter<any>();

  form = this.fb.group({
    name: [null],
    dateImport: [null],
    description: [null]
  });

  config: TableConfig = {
    columns: [
      { header: 'user' },
      { header: 'dateImport', type: ColumnsType.DATE_TIME },
      { header: 'description', type: ColumnsType.TYPE }
    ],
    rows:
      [],
    limit: 100,
    total: 0,
    page: 0,
    showLimit: false
  };


  tmpRows = [];

  constructor(public fb: FormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.historic && changes.historic.currentValue) {
      this.config.rows = changes.historic.currentValue;
      this.config.total = this.config.rows.length;
      this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    }
  }

  ngOnInit(): void {
    this.config.rows = this.historic;
    this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
  }

  public detailsItem(item): void {


  }

  public search(item: string): void {
    if (!item || item === '') {
      this.config.rows = JSON.parse(JSON.stringify(this.tmpRows));
    } else {
      item = item.toLowerCase();
      this.config.rows = this.tmpRows.filter((r) => r.name.toLowerCase().includes(item) || r.description.toLowerCase().includes(item));
    }
  }



  get formData() {
    return this.form.controls;
  }
}
