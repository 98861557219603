<form class="flex__row--spaced " [formGroup]="form">

    <div class="col-md-4">
        <small>{{'global.postalCode' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.postalCode' | translate }}" name="addressPostalCode"
            formControlName="addressPostalCode" mask="00000-000">
    </div>

    <div class="col-md-8">
        <small>{{'global.street' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.street' | translate }}" name="addressStreet"
            formControlName="addressStreet">
    </div>

    <div class="col-md-3">
        <small>{{'global.number' | translate }}</small>
        <input class="form-control mb-3"  placeholder="{{'global.number' | translate }}" name="addressNumber"
            formControlName="addressNumber">
    </div>

    <div class="col-md-9">
        <small>{{'global.complement' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.complement' | translate }}" name="addressComplement"
            formControlName="addressComplement">
    </div>

    <div class="col-md-6">
        <small>{{'global.neighborhood' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.neighborhood' | translate }}" name="addressNeighborhood"
            formControlName="addressNeighborhood">
    </div>

    <div class="col-md-6">
        <small>{{'global.city' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.city' | translate }}" name="addressCity"
            formControlName="addressCity">
    </div>

    <div class="col-md-4">
        <small>{{'global.state' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.state' | translate }}" name="addressState"
            formControlName="addressState">
    </div>

    <div class="col-md-8">
        <small>{{'global.country' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.country' | translate }}" name="addressCountry"
            formControlName="addressCountry">
    </div>

</form>