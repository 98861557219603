import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fields } from 'src/app/shared/enum/fields.enum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  public setMenuLanguage(): void {
    const currentLang = localStorage.getItem(Fields.LANGUAGE);
    this.translate.use(currentLang || this.translate.getDefaultLang()).subscribe((success) => {

    });
  }

}
