import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TableConfig } from '../../models/table-config.model';
import { ColumnsType } from '../../enum/columns-types.enum';
import { Columns } from '../../models/columns.model';
import { Fields } from '../../enum/fields.enum';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit, AfterViewInit {

  columnsType = ColumnsType;
  trackByFn = trackByFn;

  @Input()
  config: TableConfig;

  @Input()
  type = null;

  @Input()
  isPaged = false;

  @Output()
  event = new EventEmitter();

  page = 0;
  paginatedArray = [];

  constructor(private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    private currencyPipe: CurrencyPipe,
    private translateService: TranslateService) { }


  ngOnInit(): void {
    if (this.isPaged) {
      this.buildPaginatedTable();
    }
  }

  private buildPaginatedTable(): void {

    const tempArray = JSON.parse(JSON.stringify(this.config.rows));

    if (tempArray.length > 10) {

      while (tempArray.length > 0) {
        this.paginatedArray.push(tempArray.splice(0, 10));
      }

      this.config.rows = JSON.parse(JSON.stringify(this.paginatedArray[0]));
    }

  }

  public setPage(page: number) {
    if (page < this.paginatedArray.length && page >= 0) {
      this.page = page;
      this.config.rows = JSON.parse(JSON.stringify(this.paginatedArray[page]));
    }
  }

  ngAfterViewInit(): void {
    if (this.type) {
      document.getElementsByName('simple__table__bordered__cp').forEach((item) => {
        item.classList.add('bottom__bordered__table');
      });
    }
  }

  public buildRow(column: Columns, item) {
    switch (column.type) {
      case ColumnsType.DATE:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_PATTERN_US);
        }
      case ColumnsType.BOOLEAN:
        return this.buildBooleanValue(item);
      case ColumnsType.TYPE:
        return this.translatePipe.transform(item);
      case ColumnsType.DATE_TIME:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN_US);
        }
      case ColumnsType.CURRENCY:
        if(this.returnCurrencyType() == 'BRL'){
          return this.returnCurrencyMoney() + parseFloat(item).toLocaleString('brl')
        }else{
          return this.returnCurrencyMoney() + parseFloat(item).toLocaleString('en')
        }
      default:
        if (item !== null && item !== undefined) {
          return item;
        }
        return this.translatePipe.transform(Fields.NOT_REGISTRED);
    }
  }

  private buildBooleanValue(value: boolean): string {

    let field = Fields.YES;
    if (!value) {
      field = Fields.NO;
    }
    return this.translatePipe.transform(field);
  }

  public hasOptions(): boolean {
    if (this.config.options && this.config.options.more && this.config.options.more.length > 0) {
      return true;
    }
    return false;
  }

  public manageOptions(item): void {
    this.event.emit(item);
  }

  public returnCurrencyType(): string{
    return this.translateService.instant('global.currency')
  }

  public returnCurrencyMoney(): string{
    return this.translateService.instant('global.moneyFormat')
  }

  public requiredValidator(item): boolean{
    if(item.required === true){
      return true;
    }else{
      return false;
    }
  }
}
