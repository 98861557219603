<div class="navbar">
    <img src="assets/images/id-logo.png">
    <app-translater (change)="setMenuLanguage()"></app-translater>
</div>

<div class="header">
    {{'global.notFoundUuid' | translate}}
</div>

<div class="bottom__div">
    {{'global.inDoubtsCaseEmail' | translate}}
    <a href="mailto:contato@idsf.com.br"> contato@idsf.com.br</a>
</div>