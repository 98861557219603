import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Fields } from '../../enum/fields.enum';
import { TransformService } from '../../services/transform.service';
import { CNPJValidator, CPFValidator } from '../../utils/validations';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss']
})
export class BasicModalComponent implements OnInit {
  fields = Fields;
  config;

  form = this.fb.group({
    cnpjCpf: [null, Validators.required],
    cpf: [null, Validators.required]
  });

  valueField: any;
  onClose: Subject<any>;
  yes_no_combo = {
    value: null,
    options: [
      {
        label: 'fields.yes',
        value: true
      },
      {
        label: 'fields.no',
        value: false
      }
    ]
  };
  hasCpfCnpj = false;
  TransformFunctions: any;

  constructor(private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private transform: TransformService) {
    this.onClose = new Subject();
  }

  ngOnInit(): void {

    this.config.fields.map((item) => {
      if (item.type === Fields.YES_NO) {
        item.data = JSON.parse(JSON.stringify(this.yes_no_combo));
        item.data.value = item.value ? item.value : false;
      }
      if (item.type === Fields.CPF_CNPJ) {
        this.hasCpfCnpj = true;
        this.form.controls['cnpjCpf'].setValue(item.value);
      }
      if (item.type === Fields.CPF) {
        this.hasCpfCnpj = true;
        this.form.controls['cpf'].setValue(item.value);
      }
    });
  }

  public close(): void {
    this.bsModalRef.hide();
    this.onClose.next(null);
  }

  public submit(): void {


    const identifier = this.validateIdentifier();

    if (!identifier && this.hasCpfCnpj) {
      return;
    }

    if (this.config && this.config.fields.length === 1 && this.config.fields[0].type === this.fields.CPF_CNPJ) {
      this.onClose.next(identifier);
    } else {
      if (this.hasCpfCnpj) {
        const item = this.config.fields.find((item) => item.type === this.fields.CPF_CNPJ || item.type === this.fields.CPF);
        item.value = identifier;
      }
      this.onClose.next(this.config);
    }
  }

  public verifyDefaultField(item): boolean {
    const t = item.type;
    if (![Fields.COMBO, Fields.YES_NO, Fields.MASKED, Fields.CPF_CNPJ, Fields.CPF, Fields.NUMBER, Fields.LIST_TEXT, Fields.CHECKBOX, Fields.TEXT_AREA].includes(t)) {
      return true;
    }
    return false;
  }

  private validateIdentifier(): string {
    let identifier = this.form.controls['cnpjCpf'].value || this.form.controls['cpf'].value;
    let validator;

    if (this.hasCpfCnpj && identifier) {
      if (identifier.replace(/[^\d]+/g, '').length > 11) {
        validator = CNPJValidator(identifier);
      } else {
        validator = CPFValidator(identifier);
      }
    }

    return (validator ? this.transform.transformDocument(identifier) : null);
  }
}
