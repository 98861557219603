import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {

    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService) { }

    public openDialog(options): Observable<any> {

        this.modalRef = this.modalService.show(ConfirmDialogComponent,
            {
                class: 'modal-dialog-centered',
                initialState: options
            });

        return this.modalRef.content.onClose;
    }
}
