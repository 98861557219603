import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { Company } from 'src/app/shared/enum/company-enum';
import { Fields } from 'src/app/shared/enum/fields.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss'],
  providers: [LoginService]
})
export class ActivateUserComponent implements OnInit {

  activateForm = this.fb.group({
    ticket: [null],
    password: [null, [Validators.required, Validators.minLength(5)]],
    confirmedPassword: [null, [Validators.required, Validators.minLength(5)]]
  });

  passwordEqual = false;
  logoImg = 'assets/images/logo.png';
  isIdcompany = false;

  constructor(private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private route: Router,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.verifyCompany();
  }

  public activateUser(): void {

    if (!this.activateForm.valid || this.passwordEqual) {
      return;
    }
    this.activateForm.controls['ticket'].setValue(this.activateRoute.snapshot.paramMap.get('id'));

    const request = this.activateForm.value;

    delete request.confirmedPassword;

    this.loginService.activateUser(this.activateForm.value).subscribe((user) => {
      this.route.navigate(['/login']);
    });
  }

  public verifyPassword(): void {
    const form = this.activateForm.controls;

    if (form['password'].value !== form['confirmedPassword'].value) {
      this.passwordEqual = true;
    } else {
      this.passwordEqual = false;
    }
  }

  private verifyCompany(): void {
    const isIdcompany = window.location.href.includes(Fields.IDSF);

    if (isIdcompany) {
      this.logoImg = 'assets/images/id-logo.png';
      localStorage.setItem(Fields.COMPANY, Company.ID.toString());
      this.isIdcompany = true;
    }
  }
}
