<div class="basic__title">
    {{title | translate}}
</div>
<div class="basic__header">
    <div class="simple__table--spaced">
      <div *ngIf="titleConfig2">
        <a>{{titleConfig2 | translate}}</a>
        <br>
        <app-simple-table [config]="config2" type="test"></app-simple-table>
        <br>
        <a>{{titleConfig | translate}}</a>
        <br>
      </div>
      <div class="certifer" *ngIf="certifier">
        <p><strong>{{"global.certifier" | translate}}:</strong> {{certifier}} </p>
        <p><strong>{{"global.fileName" | translate}}:</strong> {{fileName}}</p>
        <p><strong>Status:</strong> {{"global."+status | translate}}</p>
        <br><br>
        <h4><strong>{{titleConfig | translate}}</strong></h4>
      </div>
    </div>
    <app-simple-table [config]="config" type="test"></app-simple-table>
</div>
