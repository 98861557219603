import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PhoneData } from './phone-code.data';

@Component({
  selector: 'app-phone-code',
  templateUrl: './phone-code.component.html',
  styleUrls: ['./phone-code.component.scss']
})
export class PhoneCodeComponent implements OnInit {

  @Input() control: FormControl;

  @Output() select = new EventEmitter<any>();


  countriesList = PhoneData.countriesList;
  filteredOptions: Observable<any[]>;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {

    if (!this.control.value) {
      this.control.setValue('+55');
    }
  }

  public emitSelect(): void {
    this.select.emit(this.control);
  }
}
