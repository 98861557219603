export class PhoneData {
    static countriesList = [
        {
           "code":"+55",
           "name":"Brasil",
           "initials":"br",
           "nacionality":"brasileira"
        },
        {
           "code":"+1",
           "name":"Estados Unidos",
           "initials":"us",
           "nacionality":"norte-americana"
        },
        {
           "code":"+1",
           "name":"Canadá",
           "initials":"ca",
           "nacionality":"canadense"
        },
        {
           "code":"+93",
           "name":"Afeganistão",
           "initials":"af",
           "nacionality":"afegãne"
        },
        {
           "code":"+355",
           "name":"Albânia",
           "initials":"al",
           "nacionality":"albanesa"
        },
        {
           "code":"+49",
           "name":"Alemanha",
           "initials":"de",
           "nacionality":"alemã"
        },
        {
           "code":"+213",
           "name":"Algéria",
           "initials":"dz",
           "nacionality":"argelina"
        },
        {
           "code":"+376",
           "name":"Andorra",
           "initials":"ad",
           "nacionality":"andorrana"
        },
        {
           "code":"+244",
           "name":"Angola",
           "initials":"ao",
           "nacionality":"angolana"
        },
        {
           "code":"+1264",
           "name":"Anguilla",
           "initials":"ai",
           "nacionality":"anguillana"
        },
        {
           "code":"+1268",
           "name":"Antigua e Barbuda",
           "initials":"ag",
           "nacionality":"antiguana"
        },
        {
           "code":"+599",
           "name":"Antilhas Holandesas",
           "initials":"an",
           "nacionality":"Antilhas Holandesas"
        },
        {
           "code":"+672",
           "name":"Antártida",
           "initials":"aq",
           "nacionality":"de antártida"
        },
        {
           "code":"+54",
           "name":"Argentina",
           "initials":"ar",
           "nacionality":"argentina"
        },
        {
           "code":"+374",
           "name":"Armênia",
           "initials":"am",
           "nacionality":"armênia"
        },
        {
           "code":"+297",
           "name":"Aruba",
           "initials":"aw",
           "nacionality":"arubana"
        },
        {
           "code":"+966",
           "name":"Arábia Saudita",
           "initials":"sa",
           "nacionality":"saudita"
        },
        {
           "code":"+61",
           "name":"Austrália",
           "initials":"au",
           "nacionality":"australiana"
        },
        {
           "code":"+994",
           "name":"Azerbaijão",
           "initials":"az",
           "nacionality":"azerbaijano"
        },
        {
           "code":"+1242",
           "name":"Bahamas",
           "initials":"bs",
           "nacionality":"bahamense"
        },
        {
           "code":"+973",
           "name":"Bahrein",
           "initials":"bh",
           "nacionality":"barenita"
        },
        {
           "code":"+880",
           "name":"Bangladesh",
           "initials":"bd",
           "nacionality":"bengali"
        },
        {
           "code":"+246",
           "name":"Barbados",
           "initials":"bb",
           "nacionality":"barbadiana"
        },
        {
           "code":"+501",
           "name":"Belize",
           "initials":"bz",
           "nacionality":"belizenha"
        },
        {
           "code":"+229",
           "name":"Benin",
           "initials":"bj",
           "nacionality":"beninense"
        },
        {
           "code":"+1441",
           "name":"Bermuda",
           "initials":"bm",
           "nacionality":"bermudiana"
        },
        {
           "code":"+375",
           "name":"Bielorrússia",
           "initials":"by",
           "nacionality":"bielo-russa"
        },
        {
           "code":"+95",
           "name":"Birmânia",
           "initials":"mm",
           "nacionality":"birmanês"
        },
        {
           "code":"+591",
           "name":"Bolívia",
           "initials":"bo",
           "nacionality":"boliviana"
        },
        {
           "code":"+267",
           "name":"Botswana",
           "initials":"bw",
           "nacionality":"betchuana"
        },
        {
           "code":"+673",
           "name":"Brunei",
           "initials":"bn",
           "nacionality":"bruneiana"
        },
        {
           "code":"+359",
           "name":"Bulgária",
           "initials":"bg",
           "nacionality":"búlgara"
        },
        {
           "code":"+226",
           "name":"Burkina Faso",
           "initials":"bf",
           "nacionality":"burquinês"
        },
        {
           "code":"+257",
           "name":"Burundi",
           "initials":"bi",
           "nacionality":"burundinesa"
        },
        {
           "code":"+975",
           "name":"Butão",
           "initials":"bt",
           "nacionality":"butanesa"
        },
        {
           "code":"+32",
           "name":"Bélgica",
           "initials":"be",
           "nacionality":"belga"
        },
        {
           "code":"+387",
           "name":"Bósnia e Herzegovina",
           "initials":"ba",
           "nacionality":"bósnia"
        },
        {
           "code":"+238",
           "name":"Cabo Verde",
           "initials":"cv",
           "nacionality":"cabo-verdiana"
        },
        {
           "code":"+237",
           "name":"Camarões",
           "initials":"cm",
           "nacionality":"camaronesa"
        },
        {
           "code":"+855",
           "name":"Camboja",
           "initials":"kh",
           "nacionality":"cambojana"
        },
        {
           "code":"+974",
           "name":"Catar",
           "initials":"qa",
           "nacionality":"catarense"
        },
        {
           "code":"+7",
           "name":"Cazaquistão",
           "initials":"kz",
           "nacionality":"cazaque"
        },
        {
           "code":"+235",
           "name":"Chade",
           "initials":"td",
           "nacionality":"chadiana"
        },
        {
           "code":"+56",
           "name":"Chile",
           "initials":"cl",
           "nacionality":"chilena"
        },
        {
           "code":"+86",
           "name":"China",
           "initials":"cn",
           "nacionality":"chinesa"
        },
        {
           "code":"+357",
           "name":"Chipre",
           "initials":"cy",
           "nacionality":"cipriota"
        },
        {
           "code":"+57",
           "name":"Colômbia",
           "initials":"co",
           "nacionality":"colombiana"
        },
        {
           "code":"+269",
           "name":"Comores",
           "initials":"km",
           "nacionality":"comorense"
        },
        {
           "code":"+242",
           "name":"Congo",
           "initials":"cg",
           "nacionality":"congolesa"
        },
        {
           "code":"+242",
           "name":"Congo (DR)",
           "initials":"cd",
           "nacionality":"congolesa"
        },
        {
           "code":"+850",
           "name":"Coreia do Norte",
           "initials":"kp",
           "nacionality":"norte-coreano"
        },
        {
           "code":"+82",
           "name":"Coreia do Sul",
           "initials":"kr",
           "nacionality":"norte-coreana"
        },
        {
           "code":"+506",
           "name":"Costa Rica",
           "initials":"cr",
           "nacionality":"costarriquenha"
        },
        {
           "code":"+225",
           "name":"Costa do Marfim",
           "initials":"ci",
           "nacionality":"marfinense"
        },
        {
           "code":"+385",
           "name":"Croácia",
           "initials":"hr",
           "nacionality":"croata"
        },
        {
           "code":"+53",
           "name":"Cuba",
           "initials":"cu",
           "nacionality":"cubana"
        },
        {
           "code":"+45",
           "name":"Dinamarca",
           "initials":"dk",
           "nacionality":"dinamarquesa"
        },
        {
           "code":"+253",
           "name":"Djibuti",
           "initials":"dj",
           "nacionality":"djibutiana"
        },
        {
           "code":"+1767",
           "name":"Dominica",
           "initials":"dm",
           "nacionality":"dominiquense"
        },
        {
           "code":"+20",
           "name":"Egito",
           "initials":"eg",
           "nacionality":"egípcia"
        },
        {
           "code":"+503",
           "name":"El Salvador",
           "initials":"sv",
           "nacionality":"salvadorenha"
        },
        {
           "code":"+971",
           "name":"Emirados Árabes",
           "initials":"ae",
           "nacionality":"árabe"
        },
        {
           "code":"+593",
           "name":"Equador",
           "initials":"ec",
           "nacionality":"equatoriana"
        },
        {
           "code":"+291",
           "name":"Eritreia",
           "initials":"er",
           "nacionality":"eritreia"
        },
        {
           "code":"+421",
           "name":"Eslováquia",
           "initials":"sk",
           "nacionality":"eslovaco"
        },
        {
           "code":"+386",
           "name":"Eslovênia",
           "initials":"si",
           "nacionality":"esloveno"
        },
        {
           "code":"+34",
           "name":"Espanha",
           "initials":"es",
           "nacionality":"espanhola"
        },
        {
           "code":"+372",
           "name":"Estônia",
           "initials":"ee",
           "nacionality":"estoniana"
        },
        {
           "code":"+47",
           "name":"Esvalbarde",
           "initials":"sj",
           "nacionality":"svalbardense"
        },
        {
           "code":"+251",
           "name":"Etiópia",
           "initials":"et",
           "nacionality":"etíope"
        },
        {
           "code":"+679",
           "name":"Fiji",
           "initials":"fj",
           "nacionality":"fijiana"
        },
        {
           "code":"+63",
           "name":"Filipinas",
           "initials":"ph",
           "nacionality":"filipina"
        },
        {
           "code":"+358",
           "name":"Finlândia",
           "initials":"fi",
           "nacionality":"finlandesa"
        },
        {
           "code":"+33",
           "name":"França",
           "initials":"fr",
           "nacionality":"francesa"
        },
        {
           "code":"+241",
           "name":"Gabão",
           "initials":"ga",
           "nacionality":"gabonesa"
        },
        {
           "code":"+233",
           "name":"Gana",
           "initials":"gh",
           "nacionality":"ganense"
        },
        {
           "code":"+995",
           "name":"Geórgia",
           "initials":"ge",
           "nacionality":"georgiana"
        },
        {
           "code":"+350",
           "name":"Gibraltar",
           "initials":"gi",
           "nacionality":"gibraltariana"
        },
        {
           "code":"+1473",
           "name":"Granada",
           "initials":"gd",
           "nacionality":"granadina"
        },
        {
           "code":"+299",
           "name":"Groelândia",
           "initials":"gl",
           "nacionality":"groenlandesa"
        },
        {
           "code":"+30",
           "name":"Grécia",
           "initials":"gr",
           "nacionality":"grega"
        },
        {
           "code":"+590",
           "name":"Guadalupe",
           "initials":"gp",
           "nacionality":"guadalupense"
        },
        {
           "code":"+502",
           "name":"Guatemala",
           "initials":"gt",
           "nacionality":"guatemalteca"
        },
        {
           "code":"+592",
           "name":"Guiana",
           "initials":"gy",
           "nacionality":"guianense"
        },
        {
           "code":"+594",
           "name":"Guiana Francesa",
           "initials":"gf",
           "nacionality":"franco-guianense"
        },
        {
           "code":"+224",
           "name":"Guiné",
           "initials":"gn",
           "nacionality":"guinéu-equatoriano ou equatoguineana"
        },
        {
           "code":"+240",
           "name":"Guiné Equatorial",
           "initials":"gq",
           "nacionality":"guinéu-equatoriano"
        },
        {
           "code":"+245",
           "name":"Guiné-Bissau",
           "initials":"gw",
           "nacionality":"guineense"
        },
        {
           "code":"+1671",
           "name":"Guão",
           "initials":"gu",
           "nacionality":"guamês"
        },
        {
           "code":"+220",
           "name":"Gâmbia",
           "initials":"gm",
           "nacionality":"gambiana"
        },
        {
           "code":"+509",
           "name":"Haiti",
           "initials":"ht",
           "nacionality":"haitiana"
        },
        {
           "code":"+31",
           "name":"Holanda",
           "initials":"nl",
           "nacionality":"holandês"
        },
        {
           "code":"+504",
           "name":"Honduras",
           "initials":"hn",
           "nacionality":"hondurenha"
        },
        {
           "code":"+852",
           "name":"Hong Kong",
           "initials":"hk",
           "nacionality":"hong-konguiana ou chinesa"
        },
        {
           "code":"+36",
           "name":"Hungria",
           "initials":"hu",
           "nacionality":"húngara"
        },
        {
           "code":"+47",
           "name":"Ilha Bouvet",
           "initials":"bv",
           "nacionality":"da ilha bouvet"
        },
        {
           "code":"+61",
           "name":"Ilha Christmas",
           "initials":"cx",
           "nacionality":"natalense"
        },
        {
           "code":"+672",
           "name":"Ilha Norfolk",
           "initials":"nf",
           "nacionality":"norfolquino"
        },
        {
           "code":"+1345",
           "name":"Ilhas Cayman",
           "initials":"ky",
           "nacionality":"caimanês"
        },
        {
           "code":"+672",
           "name":"Ilhas Cocos (Keeling)",
           "initials":"cc",
           "nacionality":"coquense"
        },
        {
           "code":"+682",
           "name":"Ilhas Cook",
           "initials":"ck",
           "nacionality":"cookense"
        },
        {
           "code":"+298",
           "name":"Ilhas Faroe",
           "initials":"fo",
           "nacionality":"faroense"
        },
        {
           "code":"+500",
           "name":"Ilhas Geórgia do Sul e Sandwich do Sul",
           "initials":"gs",
           "nacionality":"das ilhas geórgia do sul e sandwich do sul"
        },
        {
           "code":"+672",
           "name":"Ilhas Heard e McDonald",
           "initials":"hm",
           "nacionality":"das ilhas heard e mcdonald"
        },
        {
           "code":"+500",
           "name":"Ilhas Malvinas",
           "initials":"fk",
           "nacionality":"malvinense"
        },
        {
           "code":"+1670",
           "name":"Ilhas Marianas do Norte",
           "initials":"mp",
           "nacionality":"norte-marianense"
        },
        {
           "code":"+692",
           "name":"Ilhas Marshall",
           "initials":"mh",
           "nacionality":"marshallino"
        },
        {
           "code":"+1",
           "name":"Ilhas Menores Distantes dos Estados Unidos",
           "initials":"um",
           "nacionality":"das ilhas menores afastadas"
        },
        {
           "code":"+672",
           "name":"Ilhas Picárnia",
           "initials":"pn",
           "nacionality":"pitcairnense"
        },
        {
           "code":"+677",
           "name":"Ilhas Salomão",
           "initials":"sb",
           "nacionality":"salomônico"
        },
        {
           "code":"+1649",
           "name":"Ilhas Turks e Caicos",
           "initials":"tc",
           "nacionality":"turquês"
        },
        {
           "code":"+1340",
           "name":"Ilhas Virgens (USA)",
           "initials":"vi",
           "nacionality":"virginense"
        },
        {
           "code":"+1284",
           "name":"Ilhas Virgens Inglesas",
           "initials":"vg",
           "nacionality":"virginense"
        },
        {
           "code":"+62",
           "name":"Indonésia",
           "initials":"id",
           "nacionality":"indonésia"
        },
        {
           "code":"+98",
           "name":"Iran",
           "initials":"ir",
           "nacionality":"iraniano"
        },
        {
           "code":"+964",
           "name":"Iraque",
           "initials":"iq",
           "nacionality":"iraquiana"
        },
        {
           "code":"+353",
           "name":"Irlanda",
           "initials":"ie",
           "nacionality":"irlandesa"
        },
        {
           "code":"+354",
           "name":"Islândia",
           "initials":"is",
           "nacionality":"islandesa"
        },
        {
           "code":"+972",
           "name":"Israel",
           "initials":"il",
           "nacionality":"israelense"
        },
        {
           "code":"+39",
           "name":"Itália",
           "initials":"it",
           "nacionality":"italiana"
        },
        {
           "code":"+967",
           "name":"Iêmen",
           "initials":"ye",
           "nacionality":"iemenita"
        },
        {
           "code":"+1876",
           "name":"Jamaica",
           "initials":"jm",
           "nacionality":"jamaicana"
        },
        {
           "code":"+81",
           "name":"Japão",
           "initials":"jp",
           "nacionality":"japonesa"
        },
        {
           "code":"+962",
           "name":"Jordânia",
           "initials":"jo",
           "nacionality":"jordaniana"
        },
        {
           "code":"+686",
           "name":"Kiribati",
           "initials":"ki",
           "nacionality":"kiribatiana"
        },
        {
           "code":"+965",
           "name":"Kuwait",
           "initials":"kw",
           "nacionality":"kuwaitiano"
        },
        {
           "code":"+856",
           "name":"Laos",
           "initials":"la",
           "nacionality":"laosiana"
        },
        {
           "code":"+266",
           "name":"Lesoto",
           "initials":"ls",
           "nacionality":"lesota"
        },
        {
           "code":"+371",
           "name":"Letônia",
           "initials":"lv",
           "nacionality":"letão"
        },
        {
           "code":"+231",
           "name":"Libéria",
           "initials":"lr",
           "nacionality":"liberiana"
        },
        {
           "code":"+423",
           "name":"Liechtenstein",
           "initials":"li",
           "nacionality":"liechtensteiniense"
        },
        {
           "code":"+370",
           "name":"Lituânia",
           "initials":"lt",
           "nacionality":"lituana"
        },
        {
           "code":"+352",
           "name":"Luxemburgo",
           "initials":"lu",
           "nacionality":"luxemburguesa"
        },
        {
           "code":"+961",
           "name":"Líbano",
           "initials":"lb",
           "nacionality":"libanesa"
        },
        {
           "code":"+218",
           "name":"Líbia",
           "initials":"ly",
           "nacionality":"líbia"
        },
        {
           "code":"+853",
           "name":"Macao",
           "initials":"mo",
           "nacionality":"macauense"
        },
        {
           "code":"+389",
           "name":"Macedônia",
           "initials":"mk",
           "nacionality":"macedônio"
        },
        {
           "code":"+261",
           "name":"Madagascar",
           "initials":"mg",
           "nacionality":"malgaxe"
        },
        {
           "code":"+265",
           "name":"Malawi",
           "initials":"mw",
           "nacionality":"malauiano"
        },
        {
           "code":"+960",
           "name":"Maldivas",
           "initials":"mv",
           "nacionality":"maldívia"
        },
        {
           "code":"+223",
           "name":"Mali",
           "initials":"ml",
           "nacionality":"malinesa"
        },
        {
           "code":"+356",
           "name":"Malta",
           "initials":"mt",
           "nacionality":"maltesa"
        },
        {
           "code":"+60",
           "name":"Malásia",
           "initials":"my",
           "nacionality":"malaia"
        },
        {
           "code":"+212",
           "name":"Marrocos",
           "initials":"ma",
           "nacionality":"marroquina"
        },
        {
           "code":"+596",
           "name":"Martinica",
           "initials":"mq",
           "nacionality":"martiniquense"
        },
        {
           "code":"+222",
           "name":"Mauritânia",
           "initials":"mr",
           "nacionality":"mauritana"
        },
        {
           "code":"+230",
           "name":"Maurício",
           "initials":"mu",
           "nacionality":"mauriciana"
        },
        {
           "code":"+269",
           "name":"Mayotte",
           "initials":"yt",
           "nacionality":"maiotense"
        },
        {
           "code":"+691",
           "name":"Micronésia",
           "initials":"fm",
           "nacionality":"micronésia"
        },
        {
           "code":"+373",
           "name":"Moldova",
           "initials":"md",
           "nacionality":"moldavo"
        },
        {
           "code":"+976",
           "name":"Mongólia",
           "initials":"mn",
           "nacionality":"mongol"
        },
        {
           "code":"+1664",
           "name":"Montserrat",
           "initials":"ms",
           "nacionality":"montserratiano"
        },
        {
           "code":"+258",
           "name":"Moçambique",
           "initials":"mz",
           "nacionality":"moçambicana"
        },
        {
           "code":"+52",
           "name":"México",
           "initials":"mx",
           "nacionality":"mexicana"
        },
        {
           "code":"+377",
           "name":"Mônaco",
           "initials":"mc",
           "nacionality":"monegasca"
        },
        {
           "code":"+264",
           "name":"Namíbia",
           "initials":"na",
           "nacionality":"namíbia"
        },
        {
           "code":"+674",
           "name":"Nauru",
           "initials":"nr",
           "nacionality":"nauruana"
        },
        {
           "code":"+977",
           "name":"Nepal",
           "initials":"np",
           "nacionality":"nepalesa"
        },
        {
           "code":"+505",
           "name":"Nicarágua",
           "initials":"ni",
           "nacionality":"nicaraguense"
        },
        {
           "code":"+227",
           "name":"Niger",
           "initials":"ne",
           "nacionality":"nigerina"
        },
        {
           "code":"+234",
           "name":"Nigéria",
           "initials":"ng",
           "nacionality":"nigeriana"
        },
        {
           "code":"+683",
           "name":"Niue",
           "initials":"nu",
           "nacionality":"niueana"
        },
        {
           "code":"+47",
           "name":"Noruega",
           "initials":"no",
           "nacionality":"norueguesa"
        },
        {
           "code":"+687",
           "name":"Nova Caledônia",
           "initials":"nc",
           "nacionality":"caledônia"
        },
        {
           "code":"+64",
           "name":"Nova Zelândia",
           "initials":"nz",
           "nacionality":"neozelandesa"
        },
        {
           "code":"+968",
           "name":"Omã",
           "initials":"om",
           "nacionality":"omani"
        },
        {
           "code":"+680",
           "name":"Palau",
           "initials":"pw",
           "nacionality":"palauense"
        },
        {
           "code":"+970",
           "name":"Palestina",
           "initials":"ps",
           "nacionality":"palestino"
        },
        {
           "code":"+507",
           "name":"Panamá",
           "initials":"pa",
           "nacionality":"zona do canal do panamá"
        },
        {
           "code":"+675",
           "name":"Papua-Nova Guiné",
           "initials":"pg",
           "nacionality":"pauásia"
        },
        {
           "code":"+92",
           "name":"Paquistão",
           "initials":"pk",
           "nacionality":"paquistanesa"
        },
        {
           "code":"+595",
           "name":"Paraguai",
           "initials":"py",
           "nacionality":"paraguaia"
        },
        {
           "code":"+51",
           "name":"Peru",
           "initials":"pe",
           "nacionality":"peruana"
        },
        {
           "code":"+689",
           "name":"Polinésia Francesa",
           "initials":"pf",
           "nacionality":"franco-polinésia"
        },
        {
           "code":"+48",
           "name":"Polônia",
           "initials":"pl",
           "nacionality":"polonesa"
        },
        {
           "code":"+1787",
           "name":"Porto Rico",
           "initials":"pr",
           "nacionality":"portorriquenha"
        },
        {
           "code":"+351",
           "name":"Portugal",
           "initials":"pt",
           "nacionality":"portuguesa"
        },
        {
           "code":"+996",
           "name":"Quirguistão",
           "initials":"kg",
           "nacionality":"quirguiz"
        },
        {
           "code":"+254",
           "name":"Quênia",
           "initials":"ke",
           "nacionality":"queniano"
        },
        {
           "code":"+44",
           "name":"Reino Unido",
           "initials":"gb",
           "nacionality":"inglesa"
        },
        {
           "code":"+236",
           "name":"República Centro-Africana",
           "initials":"cf",
           "nacionality":"centro-africana"
        },
        {
           "code":"+1809",
           "name":"República Dominicana",
           "initials":"do",
           "nacionality":"dominicana"
        },
        {
           "code":"+420",
           "name":"República Tcheca",
           "initials":"cz",
           "nacionality":"tcheco"
        },
        {
           "code":"+232",
           "name":"República da Serra Leoa",
           "initials":"sl",
           "nacionality":"leonesa"
        },
        {
           "code":"+262",
           "name":"Reunião",
           "initials":"re",
           "nacionality":"reunionense"
        },
        {
           "code":"+40",
           "name":"Romênia",
           "initials":"ro",
           "nacionality":"romena"
        },
        {
           "code":"+250",
           "name":"Ruanda",
           "initials":"rw",
           "nacionality":"ruandesa"
        },
        {
           "code":"+70",
           "name":"Rússia",
           "initials":"ru",
           "nacionality":"russa"
        },
        {
           "code":"+212",
           "name":"Saara Ocidental",
           "initials":"eh",
           "nacionality":"saariano"
        },
        {
           "code":"+684",
           "name":"Samoa",
           "initials":"ws",
           "nacionality":"samoano"
        },
        {
           "code":"+1684",
           "name":"Samoa Americana",
           "initials":"as",
           "nacionality":"samoana"
        },
        {
           "code":"+290",
           "name":"Santa Helena",
           "initials":"sh",
           "nacionality":"helenense"
        },
        {
           "code":"+1758",
           "name":"Santa Lúcia",
           "initials":"lc",
           "nacionality":"santa-lucense"
        },
        {
           "code":"+239",
           "name":"Sao Tomé e Príncipe",
           "initials":"st",
           "nacionality":"são-tomense"
        },
        {
           "code":"+248",
           "name":"Seicheles",
           "initials":"sc",
           "nacionality":"seichelense"
        },
        {
           "code":"+221",
           "name":"Senegal",
           "initials":"sn",
           "nacionality":"senegalesa"
        },
        {
           "code":"+65",
           "name":"Singapura",
           "initials":"sg",
           "nacionality":"cingapuriana"
        },
        {
           "code":"+252",
           "name":"Somália",
           "initials":"so",
           "nacionality":"somali"
        },
        {
           "code":"+94",
           "name":"Sri Lanka",
           "initials":"lk",
           "nacionality":"cingalesa"
        },
        {
           "code":"+268",
           "name":"Suazilândia",
           "initials":"sz",
           "nacionality":"suazi"
        },
        {
           "code":"+249",
           "name":"Sudão",
           "initials":"sd",
           "nacionality":"sudanesa"
        },
        {
           "code":"+41",
           "name":"Suiça",
           "initials":"se",
           "nacionality":"sueca"
        },
        {
           "code":"+597",
           "name":"Suriname",
           "initials":"sr",
           "nacionality":"surinamesa"
        },
        {
           "code":"+46",
           "name":"Suécia",
           "initials":"se",
           "nacionality":"sueca"
        },
        {
           "code":"+1869",
           "name":"São Cristóvão",
           "initials":"kn",
           "nacionality":"são-cristovense"
        },
        {
           "code":"+378",
           "name":"São Marino",
           "initials":"sm",
           "nacionality":"samarinês"
        },
        {
           "code":"+508",
           "name":"São Pedro e Miquelon",
           "initials":"pm",
           "nacionality":"pedro-miquelonense"
        },
        {
           "code":"+1784",
           "name":"São Vicente e Granadinas",
           "initials":"vc",
           "nacionality":"sao-vicentino"
        },
        {
           "code":"+381",
           "name":"Sérvia e Montenegro",
           "initials":"me",
           "nacionality":"montenegra"
        },
        {
           "code":"+963",
           "name":"Síria",
           "initials":"sy",
           "nacionality":"síria"
        },
        {
           "code":"+66",
           "name":"Tailândia",
           "initials":"th",
           "nacionality":"tailandesa"
        },
        {
           "code":"+886",
           "name":"Taiwan",
           "initials":"tw",
           "nacionality":"taiwanês"
        },
        {
           "code":"+992",
           "name":"Tajiquistão",
           "initials":"tj",
           "nacionality":"tadjique"
        },
        {
           "code":"+255",
           "name":"Tanzânia",
           "initials":"tz",
           "nacionality":"tanzaniana"
        },
        {
           "code":"+33",
           "name":"Terras Austrais e Antárticas Francesas",
           "initials":"tf",
           "nacionality":"do territórios do sul da frança"
        },
        {
           "code":"+246",
           "name":"Território Britânico do Oceano Índico",
           "initials":"io",
           "nacionality":"do território britânico do oceano índico"
        },
        {
           "code":"+670",
           "name":"Timor-Leste",
           "initials":"tl",
           "nacionality":"timorense"
        },
        {
           "code":"+228",
           "name":"Togo",
           "initials":"tg",
           "nacionality":"togolesa"
        },
        {
           "code":"+676",
           "name":"Tonga",
           "initials":"to",
           "nacionality":"tonganesa"
        },
        {
           "code":"+690",
           "name":"Toquelau",
           "initials":"tk",
           "nacionality":"toquelauano"
        },
        {
           "code":"+1868",
           "name":"Trinidad e Tobago",
           "initials":"tt",
           "nacionality":"trinitário-tobagense"
        },
        {
           "code":"+216",
           "name":"Tunísia",
           "initials":"tn",
           "nacionality":"tunisiana"
        },
        {
           "code":"+7370",
           "name":"Turcomenistão",
           "initials":"tm",
           "nacionality":"turcomana"
        },
        {
           "code":"+90",
           "name":"Turquia",
           "initials":"tr",
           "nacionality":"turca"
        },
        {
           "code":"+688",
           "name":"Tuvalu",
           "initials":"tv",
           "nacionality":"tuvaluana"
        },
        {
           "code":"+380",
           "name":"Ucrânia",
           "initials":"ua",
           "nacionality":"ucraniana"
        },
        {
           "code":"+256",
           "name":"Uganda",
           "initials":"ug",
           "nacionality":"ugandense"
        },
        {
           "code":"+598",
           "name":"Uruguai",
           "initials":"uy",
           "nacionality":"uruguaia"
        },
        {
           "code":"+998",
           "name":"Uzbequistão",
           "initials":"uz",
           "nacionality":"uzbeque"
        },
        {
           "code":"+678",
           "name":"Vanuatu",
           "initials":"vu",
           "nacionality":"vanuatuense"
        },
        {
           "code":"+39",
           "name":"Vaticano",
           "initials":"va",
           "nacionality":"vaticano"
        },
        {
           "code":"+58",
           "name":"Venezuela",
           "initials":"ve",
           "nacionality":"venezuelana"
        },
        {
           "code":"+84",
           "name":"Vietnam",
           "initials":"vn",
           "nacionality":"vietnamita"
        },
        {
           "code":"+681",
           "name":"Wallis e Futuna",
           "initials":"wf",
           "nacionality":"wallis-futunense"
        },
        {
           "code":"+263",
           "name":"Zimbábue",
           "initials":"zw",
           "nacionality":"zimbabuana"
        },
        {
           "code":"+260",
           "name":"Zâmbia",
           "initials":"zm",
           "nacionality":"zambiana"
        },
        {
           "code":"+27",
           "name":"África do Sul",
           "initials":"za",
           "nacionality":"sul-africana"
        },
        {
           "code":"+43",
           "name":"Áustria",
           "initials":"at",
           "nacionality":"austríaca"
        },
        {
           "code":"+91",
           "name":"Índia",
           "initials":"in",
           "nacionality":"indiano"
        }
     ];
}