export enum APIS {
  USER = 'user',
  COMPANY = 'company',
  ROLE = 'role',
  CLAIM = 'claim',
  BANK = 'bank',
  HOLIDAY = 'holiday',
  CITY = 'city',
  CUSTODIAN = 'custodian',
  MASTER = 'master',
  ADMINISTRATOR = 'administrator',
  MANAGER = 'manager',
  FUND = 'fund',
  CONSULTANT = 'consultant',
  CONTACT = 'contact',
  CURRENTACCOUNT = 'currentaccount',
  GUARANTOR = 'guarantor',
  REPRESENTATIVE = 'representative',
  UF = 'uf',
  COMMON = 'common',
  USER_CURRENT_TYPE = 'UserAccessCurrentType',
  AUTHENTICATION = 'authentication',
  COMPANY_CURRENT = 'UserCurrentCompany',
  SELLER = 'seller',
  CNPJCPF = 'cnpjcpf',
  WORKFLOW = 'workflow',
  DOCUMENT = 'document',
  SELLER_LOT = 'seller-upload-lot',
  SELLER_SUMMARY = 'seller-summary',
  SELLER_LOT_IMPORT = 'sellerlotimporter',
  OPERATIONS = 'operations',
  HISTORY = 'sellerhistory',
  BY_SELLER_FUND_ID = 'by-sellerfundid',
  FILE_CONTRACT = 'fileContract',
  DRAWEE = 'drawee',
  CREDIT_ANALYSIS = 'CreditAnalysis',
  QSA = 'qsa',
  SHAREHOLDER = 'shareholder',
  INVOICE_BACKING = 'invoice-backing',
  OPERATIONS_DOCUMENTS = 'operation-documents',
  CERTIFYING ='Certifying',
  PERSON_FUND = 'person-fund',
  REPORT_FILE = 'reportFile',
  CONSIGMENT_FILE = 'ConsignmentFile',
  TEMPLATE = 'template',
  SEARCH_CEP = 'search-cep',
  FUNDS_NOTIFICATION = 'fundnotification',
  REGISTRATION_FORM = 'registrationform',
  SEARCH_CNPJ_CPF = 'search-cnpj-cpf',
  LIQUIDACAO = 'liquidacao',
  LEGAL = 'legal',
  REGISTRATION_PERSON = 'registrationperson',
  REGISTRATION_LEGAL = 'registrationlegal',

  GET = '/get/list',
  GET_BY_ID = '/get/unique',
  GET_BY_FILE = '/get/file-link',
  REGISTER = '/register',
  UPDATE = '/update',
  DELETE = "/delete",
  POST = '/register',
  SAVE = '/save',
  REMOVE = "/remove",
  GET_COMMON = '/get/',
  CREATE = '/create/'
}
