import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from '../../models/table-config.model';
import { RequestService } from '../../services/request.service';
import { APIS } from '../../enum/apis.enum';
import { HttpParams } from '@angular/common/http';
import { Fields } from '../../enum/fields.enum';
import { ColumnsType } from '../../enum/columns-types.enum';
import { BasicModalComponent } from '../basic-modal/basic-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { TableActionsEnum } from '../../enum/table-actions.enum';

@Component({
  selector: 'app-bank-accounts-list',
  templateUrl: './bank-accounts-list.component.html',
  styleUrls: ['./bank-accounts-list.component.scss']
})
export class BankAccountsListComponent implements OnInit {

  @Input()
  accountList = [];

  @Output()
  save = new EventEmitter<any>();

  @Input()
  canEnableButtons = false;

  @Output()
  remove = new EventEmitter<any>();

  form = this.fb.group({
    id: [null],
    sellerFundId: [null],
    bankId: [null, Validators.required],
    agency: [null, Validators.required],
    account: [null, Validators.required],
    description: [null, Validators.required],
    pattern: [null, Validators.required],
  });

  config: TableConfig = {
    columns: [
      { header: 'bank' },
      { header: 'agency' },
      { header: 'currentAccount' },
      { header: 'description' },
      { header: 'pattern', type: ColumnsType.BOOLEAN },
      { header: 'dateCreated', type: ColumnsType.DATE },
    ],
    rows:
      [],
    limit: 100,
    total: 0,
    page: 0,
    showLimit: false,
    options: {
      actions: []
    }
  };

  selectDefault = {
    value: null,
    label: null,
    options: [
      {
        label: 'fields.yes',
        value: 1
      },
      {
        label: 'fields.no',
        value: 0
      }
    ]
  };

  selectBank = {
    value: null,
    label: null,
    options: [

    ]
  };

  tmpRows = [];

  constructor(public fb: FormBuilder,
    private service: RequestService,
    private modal: BsModalService,
    private confirm: ConfirmDialogService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accountList && changes.accountList.currentValue) {
      this.config.rows = changes.accountList.currentValue;
      this.config.total = this.config.rows.length;
      this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    }
  }

  ngOnInit(): void {
    this.config.rows = this.accountList;
    this.config.total = this.accountList.length;
    this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    this.listBanks();
    this.config.options.actions = [
      {
        label: 'fields.edit',
        type: TableActionsEnum.EDIT,
        icon: 'fa fa-edit',
        class: 'btn click__item--blue',
        enabled: this.canEnableButtons
      },
      {
        label: 'fields.remove',
        type: TableActionsEnum.REMOVE,
        icon: 'fa fa-trash',
        class: 'btn click__item--red',
        enabled: this.canEnableButtons
      }
    ];
  }


  public addItem(): void {

    const form: any = this.form.value;

    if (this.selectBank.options.length > 0) {
      form.bank = this.selectBank.options.find((ban) => ban.value === form.bankId).label;
    }

    this.form.reset();
    this.save.emit(form);
  }

  public editItem(item): void {
    item.bank = this.selectBank.options.find((ban) => ban.value === item.bankId).label;

    const modalRef = this.modal.show(BasicModalComponent,
      {
        class: 'modal-dialog-centered',
        initialState: {
          config: {
            title: 'menu.updateBank',
            // message: 'global.updateDataButton',
            actionButton: 'buttons.update',
            result: item,
            fields: [
              {
                type: Fields.COMBO,
                value: item.bankId,
                name: 'global.bank',
                required: true,
                options: this.selectBank.options
              },
              {
                name: 'global.agency',
                type: Fields.NUMBER,
                max: 8,
                value: item.agency,
                required: true
              },
              {
                name: 'global.currentAccount',
                type: Fields.NUMBER,
                max: 20,
                value: item.currentAccount,
                required: true
              },
              {
                name: 'global.description',
                type: Fields.TEXT,
                value: item.description,
                required: true
              },
              {
                name: 'global.pattern',
                type: Fields.YES_NO,
                value: item.pattern,
                required: false
              }
            ]
          }
        }
      });

    modalRef.content.onClose.subscribe((response) => {
      let error = false;
      if (response.fields.length > 0) {

        response.fields.map((itens) => {
          if (itens.required) {
            if (itens.type !== Fields.YES_NO && (!itens.value || itens.value.trim() == '')) {
              error = true;
            }
          }
        });


        if (!error) {

          let pattern = response.fields[4].value;
          if (pattern == 'true') {
            pattern = 1;
          } else {
            pattern = 0;
          }

          const request = {
            ...response.result,
            bankId: response.fields[0].value,
            agency: response.fields[1].value,
            currentAccount: response.fields[2].value,
            description: response.fields[3].value,
            pattern: pattern,
          };

          this.save.emit(request);
          modalRef.hide();
        }
      }
    });
  }

  public removeItem(item): void {
    const option = this.confirm.openDialog(null);

    option.subscribe((op) => {
      if (item.id) {
        this.remove.emit(item);
      }
    });
  }

  get formData() {
    return this.form.controls;
  }

  private listBanks(): void {
    this.service.list(`${APIS.BANK}${APIS.GET}`, new HttpParams().append(Fields.SIZE, Fields.SIZE_DEFAULT)).subscribe((banks) => {

      banks.model.items.map((bank) => {
        this.selectBank.options.push({
          value: bank.id,
          label: bank.name
        });
      });
    });
  }

  public search(item: string): void {
    if (!item || item === '') {
      this.config.rows = JSON.parse(JSON.stringify(this.tmpRows));
      this.config.total = this.config.rows.length;
    } else {
      item = item.toLowerCase();
      this.config.rows = this.tmpRows.filter((r) => r.bank.toLowerCase().includes(item) || r.agency.toLowerCase().includes(item)
        || r.currentAccount.toLowerCase().includes(item) || r.description.toLowerCase().includes(item));
      this.config.total = this.config.rows.length;
    }
  }
}
