import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input()
  form = this.fb.group({
    contacts: [''],
    phoneNumbers: [''],
    email: ['', Validators.email],
    webSite: ['']
  });
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  public save(): void {

  }
}
