<table name="simple__table__bordered__cp" class="simple__table">
    <thead>
        <tr>
            <th [style.width.%]="column.width ? column.width: null"
                *ngFor="let column of config.columns; trackBy: trackByFn">{{ "global." + column.header | translate }}
            </th>
            <th *ngIf="hasOptions()">
                {{ config.options.label | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of config?.rows; trackBy: trackByFn">
            <td *ngFor="let column of config.columns; trackBy: trackByFn">
                <span *ngIf="column.type !== columnsType.RATING && column.type !== columnsType.TRUNCATED">
                    <small class="mobile">{{ "global." + column.header | translate }}:</small>
                    <span
                        *ngIf="column.type !== columnsType.THUMBS_UP">{{ buildRow(column, row[column.header]) }}
                        <span *ngIf="requiredValidator(row)">*</span></span>
                    <div class="thumbs__up" *ngIf="column.type === columnsType.THUMBS_UP">
                        <label>{{ ('global.' + (row[column.header] ? column.yes : column.no)) | translate }} </label>
                        <img *ngIf="row[column.header]" src="assets/svgs/like.svg">
                    </div>
                    <a class="a__link" *ngIf="row.link && column.header === 'document'" [href]="row.link"
                        target="_blank">{{row.linkName | translate}}</a>
                </span>
                <span *ngIf="column.type === columnsType.RATING">
                    <small class="mobile">{{ "global." + column.header | translate }}:</small>
                    <span tooltip="{{row.tooltip | translate}}" class="circle" [ngClass]="row.class"></span>
                </span>
                <span *ngIf="column.type === columnsType.TRUNCATED">
                    <small class="mobile">{{ "global." + column.header | translate }}:</small>
                    <span tooltip="{{row.nameTooltip | translate}}"> {{ buildRow(column, row[column.header]) }}<small *ngIf="row.nameTooltip !== ''">...</small></span>
                </span>
                <span *ngIf="column.type === columnsType.NUMBER">
                  <small class="mobile">{{ "global." + column.header | translate }}:</small>
                  <span tooltip="{{row.nameTooltip | currency: returnCurrencyType() }}"> {{ buildRow(column, row[column.header]) }}<small *ngIf="row.nameTooltip !== ''">...</small></span>
              </span>
            </td>
            <td *ngIf="hasOptions()">
                <small class="mobile">{{ config.options.label  | translate }}:</small>
                <div *ngFor="let item of config.options.more; trackBy: trackByFn">
                    <button [ngStyle]="{'color': item.color ? item.color : 'black'}" class="option__button"
                        (click)="manageOptions(row)">{{ item.label | translate }}</button>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<div *ngIf="isPaged">
    <ul>
        <li (click)="setPage(page - 1)">
            {{'global.previous' | translate}}
        </li>
        <li *ngFor="let pa of paginatedArray; trackBy: trackByFn; let i= index" (click)="setPage(i)">
            {{i + 1 }}
        </li>
        <li (click)="setPage(page + 1)">
            {{'global.next' | translate}}
        </li>
    </ul>
</div>
