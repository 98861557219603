<div class="contact-list">
    <div class="card middle-form">
        <div class="card-header card-header--white"><strong>{{'global.addRepresentative' | translate }}</strong></div>
        <div class="contact__form">
            <form class="row" [formGroup]="form">
                <div class="col-md-3 col-sm-12">
                    <small>CPF*</small>
                    <input class="form-control mb-3" placeholder="CPF" name="cpf" formControlName="cpf"
                        mask="000.000.000-00" (blur)="validateCpf()">
                    <small class="small__error"
                        *ngIf="invalidCpf">{{'messages.COMMON_CPF_INVALID' | translate }}</small>
                </div>

                <div class="col-md-5 col-sm-12">
                    <small>{{'global.name' | translate }}*</small>
                    <input class="form-control mb-3" type="text" placeholder="{{'global.name' | translate }}"
                        name="name" formControlName="name">
                </div>

                <div class="col-md-4 col-sm-12">
                    <small>{{'global.email' | translate }}*</small>
                    <input class="form-control mb-3" type="text" placeholder="{{'global.email' | translate }}"
                        name="email" formControlName="email" maxlength="100" [pattern]="patterns.EMAIL_PATTERN">
                </div>

                <div class="col-md-2 col-sm-12">
                  <small>{{ 'global.phone' | translate}}*</small>
                  <input maxlength="14" class="form-control mb-3" type="text" placeholder="{{'global.phone' | translate }}"
                      name="phone" [(ngModel)]="phone" [mask]="maskPhone" (keyup)="setPhone()" [ngModelOptions]="{standalone: true}">
              </div>

                <div class="col-md-2 col-sm-12">
                    <small>{{'global.signConcession' | translate }}*</small>
                    <app-select [value]="formData['signConcession'].value" [options]="selectEndorsement.options"
                        (selection)="formData['signConcession'].setValue($event)"></app-select>
                </div>

                <div class="col-md-2 col-sm-12">
                    <small>{{'global.signIsolately' | translate }}*</small>
                    <app-select [value]="formData['signIsolately'].value" [options]="selectEndorsement.options"
                        (selection)="formData['signIsolately'].setValue($event)"></app-select>
                </div>

                <div class="col-md-2 col-sm-12">
                    <small>{{'global.signEndorsement' | translate }}*</small>
                    <app-select [value]="formData['signEndorsement'].value" [options]="selectEndorsement.options"
                        (selection)="formData['signEndorsement'].setValue($event)"></app-select>
                </div>

                <div class="col-md-2 col-sm-12">
                    <small>{{'global.createDuplicate' | translate }}*</small>
                    <app-select [value]="formData['createDuplicate'].value" [options]="selectEndorsement.options"
                        (selection)="formData['createDuplicate'].setValue($event)"></app-select>
                </div>

                <div class="col-md-2 col-sm-4" style="margin-top: 20px;">
                    <div>
                        <button type="button" class="btn btn-primary" [disabled]="form.invalid || invalidCpf"
                            (click)="addItem()">{{'global.add' | translate }}</button>
                    </div>
                </div>


            </form>
            <br />
        </div>
    </div>

    <div class="card-header card-header--white"><strong>{{'global.existingRepresentatives' | translate }}</strong></div>
    <div class="card middle-form">
        <div class="table__items">
            <app-basic-commands [total]="config.total" (search)="search($event)" [showNew]="false"></app-basic-commands>

            <app-table [config]="config" (edit)="editItem($event)" (remove)="removeItem($event)"></app-table>
        </div>
    </div>
</div>
