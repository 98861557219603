import { RouterModule } from '@angular/router';
import { MessageService } from './services/message.service';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxBoostrapModule } from './modules/ngx-bootstrap.module';
import { TranslateModule } from '@ngx-translate/core';
import { RequestService } from './services/request.service';
import { AppBreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { ComponentsModule } from './components/components.module';
import { NgxMaskModule, MaskPipe } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { TransformService } from './services/transform.service';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SafePipe } from './pipes/safe.pipe';
import { RequestNoInterceptorService } from './services/request-no-interceptor';
@NgModule({
    declarations: [
        SafePipe
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule,
        NgxDropzoneModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ComponentsModule,
        NgxMaskModule,
        NgxCurrencyModule,
        NgxDropzoneModule,
        SafePipe,
        
    ],
    providers: [
        MessageService,
        RequestService,
        AppBreadcrumbService,
        DatePipe,
        ConfirmDialogService,
        MaskPipe,
        TransformService,
        SafePipe,
        RequestNoInterceptorService
    ],
    bootstrap: []
})
export class SharedModule { }
