<form class="flex__row--spaced " [formGroup]="form">

    <div class="col-md-12">
        <small>{{'global.contactName' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.contactName' | translate }}" name="contacts"
            formControlName="contacts">
    </div>

    <div class="col-md-12">
        <small>{{'global.phones' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'global.phones' | translate }}" name="phones"
            formControlName="phoneNumbers">
    </div>

    <div class="col-md-12">
        <small>Email</small>
        <input class="form-control mb-3" type="text" placeholder="Email" name="email" formControlName="email">
    </div>

    <div class="col-md-12">
        <small>Web Site</small>
        <input class="form-control mb-3" type="text" placeholder="Web Site" name="webSite" formControlName="webSite">
    </div>
</form>
<app-btn-actions [form]="form" (save)="save()"></app-btn-actions>