<div class="historic-list">
  <div class="card middle-form">
      <div class="card-header card-header--white"><strong>{{'global.historicEvents' | translate }}</strong>
      </div>

      <div class="table__items">
          <app-basic-commands [total]="config.total" [showSearch]="false" [showNew]="false"></app-basic-commands>
          <app-table [config]="config" (details)="detailsItem($event)"></app-table>
      </div>
  </div>
</div>
