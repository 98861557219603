import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { Cities } from '../models/cities.model';


@Injectable()
export class RequestService {

    constructor(private http: HttpClient) { }

    public post(api: string, request: any): Observable<ResponseModel> {
        return this.http.post<ResponseModel>(`${environment.API}/${api}`, request);
    }

    public list(api: string, httpParams: HttpParams): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${environment.API}/${api}`, { params: httpParams });
    }

    public getById(api: string, id: string): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${environment.API}/${api}?id=${id}`);
    }

    public update(api: string, request: any): Observable<ResponseModel> {
        return this.http.put<ResponseModel>(`${environment.API}/${api}`, request);
    }

    public delete(api: string, id: any) {
        return this.http.delete<ResponseModel>(`${environment.API}/${api}?id=${id}`);
    }

    public getCompanyTypes(): any {
        return JSON.parse(localStorage.getItem('COMPANY_TYPES'));
    }

    public getMultipleResources(requests: string[]): Observable<any[]> {
        const multiple = [];
        requests.map((request) => {
            multiple.push(this.http.get(`${environment.API}/${request}`));
        });
        return forkJoin(multiple);
    }

    public saveTempData(data): void {
        sessionStorage.setItem('TMP_DATA', JSON.stringify(data));
    }

    public getTempData(): any {
        const data = JSON.parse(sessionStorage.getItem('TMP_DATA'));
        sessionStorage.removeItem('TMP_DATA');
        return data;
    }

    public saveSharedData(key, value): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public getSharedData(key):any {
        return JSON.parse(sessionStorage.getItem(key));
    }

    public deleteWithoutId(api: string, id: any) {
      return this.http.delete<ResponseModel>(`${environment.API}/${api}?${id}`);
  }
}
