import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fields } from '../../enum/fields.enum';

@Component({
  selector: 'app-widgets-dashboard',
  templateUrl: './widgets-dashboard.component.html',
  styleUrls: ['./widgets-dashboard.component.scss']
})
export class WidgetsDashboardComponent implements OnInit {

  @Input()
  dateConsultation = new Date();

  @Input()
  records;

  @Input()
  consultation;

  @Output()
  details: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.records = 0;
  }

  public onDetails(item): void{
    console.log(item)
    this.details.emit(item)
  }

  public returnCurrencyType(): string{
    return this.translateService.instant('global.currency')
  }

  public dateConsultationFormat(): string{
    let item = this.dateConsultation.toISOString();
    if(this.returnCurrencyType() == 'BRL'){
     return this.datePipe.transform(item, Fields.DATE_PATTERN);
    }else{
      return this.datePipe.transform(item, Fields.DATE_PATTERN_US);
    }
  }
}
