import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  message: string;
  type: string;
  title: string;
  button_text: string;
  public modalRef: BsModalRef;

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if(event.key === 'Enter')
    {
      this.close();
    }
  }

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {

  }

  close(){
    this.modalService._hideModal(1);
  }

}
