<div *ngFor="let group of config;trackBy: trackByFn" class="appcheck">
    <div class="appcheck__list flex__row--spaced">
        <div class="appcheck__list__title">
            <label>
                {{ 'menu.' + group.name | translate }}
            </label>
        </div>

        <div *ngFor="let checks of group.permissions;trackBy: trackByFn" class="appcheck__list__item"> {{ 'fields.'
            + checks.field
            | translate }}
            <label class="switch switch-outline-primary-alt">
                <input [(ngModel)]="checks.value" name="value" [checked]="checks.value" class="switch-input" type="checkbox" (change)="onSelection()"><span class="switch-slider"></span>
            </label>
        </div>
    </div>
</div>
