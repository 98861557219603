import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../core/auth/auth.service';
import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Company } from '../../enum/company-enum';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { Fields } from '../../enum/fields.enum';
import { ContextCompaniesComponent } from '../context-companies/context-companies.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BasicFundListModel } from '../../models/basic-fund-list.model';
@Component({
  selector: 'app-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss']
})
export class NavbarContainerComponent implements OnInit {

  claims = this.auth.getClaims();

  public sidebarMinimized = false;
  public navItems = [];
  user = null;
  navbarBrandFull = { src: 'assets/images/logo_reduced_arrow.png', alt: 'Logo Edge Captal', height: null, width: null };
  navbarBrandMinimized = { src: 'assets/images/edge-min.png', width: 30, height: 30, alt: 'Logo Edge Captal' };
  accessLabel: string;
  showLink: boolean;
  funds: BasicFundListModel[] = [];
  fundsHtml = '';

  constructor(private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private confirm: ConfirmDialogService,
    private modalService: BsModalService) { }

  ngAfterViewChecked(): void {
    const component: any = document.querySelectorAll("a[href='https://app.lexio.legal/login/idsf']");

    if(component && component.length > 0) {
      component[0].target = 'blank'
    }
  }

  ngOnInit() {
    this.setMenuLanguage();
    this.user = JSON.parse(localStorage.getItem(Fields.USER));

    if (localStorage.getItem(Fields.COMPANY) === Company.ID.toString()) {
      this.navbarBrandFull.src = 'assets/images/id-logo.png';
      this.navbarBrandFull.width = 90;
      this.navbarBrandFull.height = 45;
      this.navbarBrandMinimized.src = 'assets/images/id-min.png';
    }
    this.getAccessItem();
  }

  private getAccessItem(): void {
    const accessItem = this.auth.getAccessType();
    this.accessLabel = accessItem.label;
    const allAccessess = this.auth.getAccessOptions();
    this.showLink = allAccessess && allAccessess.totalTypes > 1 ? true : false;
    this.funds = this.auth.getFunds() ? this.auth.getFunds() : [];

    if (this.funds.length > 1) {
      for (let i = 1; i < this.funds.length; i++) {
        this.fundsHtml += `${this.funds[i].name}<br/>`;
      }
    }
  }

  public changeAccess(): void {
    if (this.showLink) {
      const option = this.confirm.openDialog({ message: 'accessTypeAlteration' });

      option.subscribe((op) => {
        this.router.navigate(['/select-access']);
      });
    }

  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public logout() {
    this.auth.logout();
  }

  public setMenuLanguage(): void {
    const currentLang = localStorage.getItem(Fields.LANGUAGE);
    this.translate.use(currentLang || this.translate.getDefaultLang()).subscribe((success) => {

      if (!success) {
        setTimeout(() => {
          this.buildMenu();
        }, 1000);
      } else {
        this.buildMenu();
      }
    });
  }

  private buildMenu(): void {


    const config = [
      {
        title: this.translate.instant('menu.admin'),
        items: [
          {
            name: this.translate.instant('menu.groups'),
            url: '/home/admin/groups',
            icon: 'fa fa-users',
            visible: this.claims.LIST_ROLE ? true : false
          },
          {
            name: this.translate.instant('menu.users'),
            url: '/home/admin/users',
            icon: 'fa fa-user',
            visible: this.claims.LIST_USER ? true : false
          },
          {
            name: this.translate.instant('menu.companies'),
            url: '/home/admin/companies',
            icon: 'fa fa-copyright',
            visible: this.claims.LIST_COMPANY ? true : false
          },
          {
            name: this.translate.instant('menu.custodians'),
            url: '/home/admin/custodians',
            icon: 'fa fa-user-secret',
            visible: this.claims.LIST_CUSTODIAN ? true : false
          },
          {
            name: this.translate.instant('menu.administrators'),
            url: '/home/admin/administrators',
            icon: 'fa fa-lock',
            visible: this.claims.LIST_ADMINISTRATOR ? true : false
          },
          {
            name: this.translate.instant('menu.managers'),
            url: '/home/admin/managers',
            icon: 'fa fa-handshake-o',
            visible: this.claims.LIST_MANAGER ? true : false
          },

          {
            name: this.translate.instant('menu.consultants'),
            url: '/home/admin/consultants',
            icon: 'fa fa-eye',
            visible: this.claims.LIST_CONSULTANT ? true : false
          },
          {
            name: this.translate.instant('menu.funds'),
            url: '/home/admin/funds',
            icon: 'fa fa-calculator',
            visible: this.claims.LIST_FUND ? true : false,
          }

        ]
      },
      // {
      //   title: this.translate.instant('menu.shareholder'),
      //   items: [

      //     {
      //       name: this.translate.instant('menu.dashboardShareholder'),
      //       url: '/home/admin/shareholders/dashboard',
      //       icon: 'fa fa-table',
      //       visible: this.claims.LIST_SHAREHOLDER ? true : false
      //     }

      //   ]
      // },
      {
        title: this.translate.instant('menu.parameterization'),
        items: [
          {
            name: this.translate.instant('menu.banks'),
            url: '/home/parameterization/banks',
            icon: 'fa fa-money',
            visible: this.claims.LIST_BANK ? true : false
          },
          {
            name: this.translate.instant('menu.notifications'),
            url: '/home/',
            icon: 'fa fa-bell-o',
            visible: this.claims.LIST_MASTER ? true : false
          },
                {
            name: this.translate.instant('Templates'),
            url: '/home/parameterization/template',
            icon: 'fa fa-folder',
            visible: this.claims.LIST_MASTER ? true : false
          },
          // {
          //   name: this.translate.instant('menu.holidays'),
          //   url: '/home/registrations/holi  days',
          //   icon: 'fa fa-calendar',
          //   visible: this.claims.LIST_HOLIDAY ? true : false
          // },
          // {
          //   name: 'CNPJs/CPFs',
          //   url: '/home/registrations/cnpjscpfs',
          //   icon: 'fa fa-id-card',
          //   visible: this.claims.LIST_BANK ? true : false //TODO
          // },
          // {
          //   name: this.translate.instant('menu.operationsTypes'),
          //   url: '/home/registrations/operationstypes',
          //   icon: 'fa fa-cog',
          //   visible: this.claims.LIST_BANK ? true : false //TODO
          // },
          // {
          //   name: this.translate.instant('menu.fee'),
          //   url: '/home/registrations/fee',
          //   icon: 'fa fa-barcode',
          //   visible: this.claims.LIST_BANK ? true : false //TODO
          // }
        ]
      },
      {
        title: 'dashboards',
        items: [
          {
            name: this.translate.instant('menu.wallet'),
            url: '/home/dashboards/wallet',
            icon: 'fa fa-credit-card',
            visible: true
          }
        ]
      },
      {
        title: this.translate.instant('menu.register'),
        items: [
          {
            name: this.translate.instant('menu.seller'),
            url: '/home/registrations/sellers',
            icon: 'fa fa-user-plus',
            visible: this.claims.LIST_SELLER ? true : false, //TODO
          },
          {
            name: this.translate.instant('menu.drawee'),
            url: '/home/registrations/drawee',
            icon: 'fa fa-user-plus',
            visible: this.claims.LIST_DRAWEE ? true : false
          },
          {
            name: this.translate.instant('menu.shareholder'),
            url: '/home/registrations/shareholders',
            icon: 'fa fa-user-plus',
            visible: this.claims.LIST_SHAREHOLDER ? true : false
          }
        ]
      },
      {
        title: this.translate.instant('menu.financial'),
        items: [
          {
            name: this.translate.instant('menu.operations'),
            url: '/home/operation/operations',
            icon: 'fa fa-tasks',
            visible: this.claims.LIST_OPERATIONS ? true : false
          },
          {
            name: this.translate.instant('menu.liquidation'),
            url: '/home/operation/liquidation',
            icon: 'fa fa-tasks',
            visible: this.claims.LIST_LIQUIDACAO ? true : false
          }
        ]
      },
      {
        title: this.translate.instant('menu.imports'),
        items: [
          {
            name: this.translate.instant('menu.shippingFile'),
            url: '/home/imports/shippingFile',
            icon: 'fa fa-upload',
            visible: this.claims.LIST_CONSIGNMENTFILE
            ? true : false
          },
          {
            name: this.translate.instant('menu.seller'),
            url: '/home/imports/importSeller',
            icon: 'fa fa-upload',
            visible: this.claims.WRITE_SELLER ? true : false
          }
        ]
      },
      {
        title: this.translate.instant('menu.juridical'),
        items: [
          {
            name:this.translate.instant('Lexio'),
            url: 'https://app.lexio.legal/login/idsf',
            icon: 'fa fa-handshake-o',
            visible: this.claims.LIST_LEGAL? true : false
          }
        ]
      },
      {
        title: this.translate.instant('menu.report'),
        items: [
          {
            name: this.translate.instant('menu.createdFiles'),
            url: '/home/reports/created-files',
            icon: 'fa fa-book',
            visible: this.claims.LIST_REPORT ? true : false
          },
          {
            name: this.translate.instant('menu.stock'),
            url: '/home/reports/stock',
            icon: 'fa fa-book',
            visible: this.claims.WRITE_REPORTFILE_STOCK ? true : false
          },
          {
            name: this.translate.instant('menu.liquidate'),
            url: '/home/reports/liquidate',
            icon: 'fa fa-book',
            visible: this.claims.WRITE_REPORTFILE_LIQUIDATE ? true : false
          },
          {
            name: this.translate.instant('menu.acquisitions'),
            url: '/home/reports/acquisitions',
            icon: 'fa fa-book',
            visible: this.claims.WRITE_REPORTFILE_ACQUISITION ? true : false
          }
        ]
      }
    ];

    const navTempItems = [];

    config.map((conf) => {

      let menu = false;
      conf.items.map((item) => {

        if (item.visible) {
          if (!menu) {
            navTempItems.push({
              title: true,
              name: conf.title
            });
            menu = true;
          }
          navTempItems.push(item);
        }
      });
    });

    this.navItems = navTempItems;
  }

  public dispathResize(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  public openContextModal(): void {

    const acesses = this.auth.getAllAccesses();
    const modalRef = this.modalService.show(ContextCompaniesComponent,
      {
        class: 'modal-dialog-centered',
        initialState: null
      });

    modalRef.setClass('modal-lg modal-lg-fundo');
    modalRef.content.onClose.subscribe((item) => {
      this.router.navigate(['login']);
    });
  }
}
