import { Injectable } from '@angular/core';
import { Messages } from '../enum/messages.enum';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

class Message {
    message: string;
    type: string;
    title?: string;
    button_text: string

}
const MESSAGE_TYPES = {
    SUCESS: {
        type: 'alert alert-success',
        title: 'SUCESS_TITLE',
        button_text: 'ALERT_BUTTON_SUCCESS'
    },
    ERROR: {
        type: 'alert alert-danger',
        title: 'ERROR_TITLE',
        button_text: 'ALERT_BUTTON_ERROR'
    },
    INFO: {
        type: 'alert alert-info',
        title: 'INFO',
        button_text: 'ALERT_BUTTON_INFO'
    }
}

@Injectable()
export class MessageService {


    constructor(private translate: TranslateService,
        private toastr: ToastrService) { }

    public show(message: string, type: string): void {

        let options: Message = {
            message,
            type,
            button_text: ''
        };

        switch (type) {
            case Messages.ERROR:
                options = {
                    message: options.message,
                    ...MESSAGE_TYPES.ERROR,
                    button_text: MESSAGE_TYPES.ERROR.button_text
                };
                this.toastr.error(this.translate.instant(`messages.${options.message}`), this.translate.instant(`messages.${options.title}`), {
                    closeButton: true,
                    timeOut: 30000
                });
                break;
            case Messages.SUCESS:
                options = {
                    message: options.message,
                    ...MESSAGE_TYPES.SUCESS,
                    button_text: MESSAGE_TYPES.SUCESS.button_text,
                };
                this.toastr.success(this.translate.instant(`messages.${options.message}`), this.translate.instant(`messages.${options.title}`), {
                    closeButton: true
                });
                break;
            case Messages.INFO:
                options = {
                    message: options.message,
                    ...MESSAGE_TYPES.INFO,
                    button_text: MESSAGE_TYPES.INFO.button_text
                };
                this.toastr.info(this.translate.instant(`messages.${options.message}`), this.translate.instant(`messages.${options.title}`), {
                    closeButton: true
                });
                break;
        }
    }

    public close(): void {
        this.toastr.clear();
    }
}
