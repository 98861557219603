import { ContractService } from 'src/app/modules/contract/contract.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-policy-modal-details',
  templateUrl: './policy-modal-details.component.html',
  styleUrls: ['./policy-modal-details.component.scss']
})
export class PolicyModalDetailsComponent implements OnInit {

  config;

  color:string;

  constructor(private service: ContractService,
              public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    const color = this.service.getContractPrimaryColor();
    this.color = color;
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
