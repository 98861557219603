<div class="translater">
    <app-translater></app-translater>
</div>
<div class="login" >

    <div class="login__img__container" [ngClass]="{'id__company': isIdcompany, 'edge__company': !isIdcompany}" >
        <img [src]="logoImg">
    </div>

    <div *ngIf="!forgotPassword" class="login_container">
        <img class="logo" [src]="logoImg">
        <h2 id="loginTitle">{{'authentication.login' | translate }}</h2>
        <p>{{'authentication.loginSubtitle' | translate }}</p>
        <form #form="ngForm" [formGroup]="loginForm">

            <input class="form-control mb-3" type="email" placeholder="{{'authentication.email' | translate }}"
                formControlName="email" (keyup.enter)="login()" name="email" id="email" required>
            <input class="form-control mb-3" type="password" (keyup.enter)="login()"
                placeholder="{{'authentication.password' | translate }}" formControlName="password" name="password"
                id="password" minlength="8" required>

            <label class="container-check login_remember mb-3">
                <input type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe">
                <span class="checkmark"></span>
                <label for="remeber">{{'authentication.remember' | translate }}</label>
            </label>

            <div class="login__action mb-3">
                <a (click)="forgotPassword = true">{{'authentication.forgotPassword' | translate }}</a>
                <button id="login" type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="login()">Login</button>
            </div>
        </form>
    </div>

    <div *ngIf="forgotPassword" class="login_container">
        <img class="logo__forgot" [src]="logoImg">
        <form #form="ngForm" [formGroup]="loginForm">
            <h2>{{'authentication.recover' | translate }}</h2>
            <p>{{'authentication.recoverSubtitle' | translate }}</p>
            <input class="form-control mb-3" type="email" placeholder="{{'authentication.email' | translate }}"
                name="email" formControlName="email" (keyup.enter)="resetPassword()" required>
            <div class="login__action">
                <a (click)="forgotPassword = false">{{'buttons.back' | translate }}</a>
                <button type="button" class="btn btn-primary" (click)="resetPassword()">{{'buttons.next'
                    | translate }}</button>
            </div>
        </form>
    </div>
</div>
