import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-basic-commands',
  templateUrl: './basic-commands.component.html',
  styleUrls: ['./basic-commands.component.scss']
})
export class BasicCommandsComponent implements OnInit {

  @Input()
  total = 0;

  @Output()
  search: EventEmitter<any> = new EventEmitter();

  @Output()
  new: EventEmitter<any> = new EventEmitter();

  @Input()
  newButton = true;

  @Input()
  showNew = true;

  @Input()
  showSearch = false;

  @Output()
  checkboxDelete: EventEmitter<any> = new EventEmitter();

  @Input()
  showcheckboxDelete = false;

  filterDate;

  bsValue = new Date();

  constructor() { }

  ngOnInit(): void {
    this.filterDate = [this.bsValue, this.bsValue];
  }

  public doSearch(event) {
    this.search.emit(event);
  }

  public newItem() {
    this.new.emit();
  }

  public onRemove(): void {
    this.checkboxDelete.emit();
  }
}
