import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Values } from '../../models/values.model';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-mat-select',
  templateUrl: './mat-select.component.html',
  styleUrls: ['./mat-select.component.scss']
})
export class MatSelectComponent implements OnInit {

  @Input()
  items: Values[];

  @Input()
  value: FormControl;

  @Input()
  label: string;

  trackByFn = trackByFn

  constructor() { }

  ngOnInit(): void {
  }

}
