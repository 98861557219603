import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContractService } from 'src/app/modules/contract/contract.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Output()
  back = new EventEmitter<any>();
  color: string;
  constructor(private contractService: ContractService) { }

  ngOnInit(): void {
    const receivedColor = this.contractService.getContractPrimaryColor();
    this.color = receivedColor ? receivedColor : '#7fc845';
  }

  public return(): void {
    this.back.emit();
  }

}
