<div *ngIf="!showForm" class="row">
    <div class="options col-md-3 col-sm-12">
        <div class="list-group" id="list-tab" role="tablist">
            <a (click)="selectMenu('one')" [ngClass]="{'active': list.one.selected}"
                class="list-group-item list-group-item-action" id="list-home-list" data-toggle="list" role="tab"
                aria-controls="home">{{'global.stepOne' | translate
                }}</a>
            <a (click)="selectMenu('two')" [ngClass]="{'active': list.two.selected}"
                class="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" role="tab"
                aria-controls="profile">{{'global.stepTwo' |
                translate }}</a>
        </div>
    </div>

    <div *ngIf="list.one.selected" class="first col-md-8 col-sm-12 card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-8">
                    <small>{{'global.stepOne' | translate }}</small>
                    <app-select [value]="select.value" [label]="select.label" [options]="select.options"
                        (selection)="selectItem($event)" [isTranslated]="false"></app-select>
                    <small *ngIf="askCreation">{{'global.dontShowList' | translate}} <a href="#"
                            (click)="false">{{'global.clickHereToAdd' | translate}}</a></small>
                </div>
                <div class="col-md-3">
                    <button (click)="selectMenu('two')" type="button" class="btn btn-success"
                        [disabled]="!onwerCompanyId">{{'global.stepTwo'
                        | translate }}</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="list.two.selected" class="second col-md-8 col-sm-12 card">
        <div class="card-body">

            <small>{{'global.stepTwo' | translate }}</small>

            <div class="row">
                <div class="col-md-5">
                    <small>{{'global.typeCompanyCnpj'
                        | translate }}</small>
                    <input [(ngModel)]="cnpj" class="form-control mb-3" type="text" placeholder="CNPJ" name="cnpj"
                        mask="00.000.000/0000-00">
                </div>
                <div class="col-md-4">
                    <button (click)="getCompanyByCnpj()" type="button" class="btn btn-success">{{'fields.search'
                        | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showForm">
    <app-company-form [companiesForm]="companiesForm" [contactForm]="contactForm" [addressForm]="addressForm">
    </app-company-form>
    <app-btn-actions [form]="companiesForm" (save)="save()" [disabled]="!companyReceived?.allowUpdate"></app-btn-actions>
</div>
