<div class="basic__title">
    {{title | translate}}
</div>

<div class="basic__header">
    <app-material-table   [config]="config" (edit)="actionEvent($event)">
    </app-material-table>
</div>
<div class="card__actions">
    <button *ngIf="showSave" type="button" class="btn btn-success" (click)="action()">{{'buttons.save'
        | translate }}</button>
</div>
