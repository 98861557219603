import { ColumnsType } from './../../enum/columns-types.enum';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { Columns } from '../../models/columns.model';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Fields } from '../../enum/fields.enum';
import { TableConfig } from '../../models/table-config.model';
import { trackByFn } from '../../utils/track-by';
import { TableActionsEnum } from '../../enum/table-actions.enum';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TranslatePipe, DatePipe]
})
export class TableComponent implements OnInit, OnChanges {

  @Input()
  config: TableConfig;

  @ViewChild('table')
  table: DatatableComponent;

  @Output()
  pagination: EventEmitter<any> = new EventEmitter();


  @Output()
  limit: EventEmitter<any> = new EventEmitter();

  @Output()
  edit: EventEmitter<any> = new EventEmitter();

  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  @Output()
  more?: EventEmitter<any> = new EventEmitter();

  @Output()
  download?: EventEmitter<any> = new EventEmitter();

  pageControl = 1;

  select = {
    value: 10,
    label: null,
    options: [
      {
        label: '10',
        value: 10
      },
      {
        label: '20',
        value: 20
      },
      {
        label: '50',
        value: 50
      },
      {
        label: '100',
        value: 100
      }
    ]
  };

  isMobile = false;
  showPaginator = false;
  lastPage = 0;

  trackByFn = trackByFn;

  columnsType = ColumnsType;
  tableActionsEnum = TableActionsEnum;

  constructor(private datePipe: DatePipe,
    private translatePipe: TranslatePipe,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.onResize();
    this.buildMobilePaginator();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.config = changes.config.currentValue ? changes.config.currentValue : this.config;
    this.select.value = this.config ? this.config.limit : this.select.value;
    this.buildMobilePaginator()
  }

  public page(event): void {
    const page = event.page ? event.page : event;

    if (page <= this.lastPage) {
      this.pageControl = page;
      this.pagination.emit(page);
    }

  }

  public buildRow(column: Columns, item) {
    switch (column.type) {
      case ColumnsType.DATE:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_PATTERN_US);
        }
      case ColumnsType.BOOLEAN:
        return this.buildBooleanValue(item);
      case ColumnsType.TYPE:
        return this.translatePipe.transform(item);
      case ColumnsType.DATE_TIME:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN_US);
        }
      default:
        if (item != null && item != undefined) {
          return item;
        }
        return this.translatePipe.transform(Fields.NOT_REGISTRED);
    }
  }

  private buildBooleanValue(value: boolean): string {

    let field = Fields.YES;
    if (!value) {
      field = Fields.NO;
    }
    return this.translatePipe.transform(field);
  }

  public selectItem(value) {
    this.pageControl = 1;
    this.limit.emit(value || 10);
  }

  public onEdit(item): void {
    this.edit.emit(item);
  }

  public onRemove(item): void {
    this.remove.emit(item);
  }

  public onMoreOptions(item, option): void {
    this.more.emit({ id: item.id, option: option.label });
  }

  public onDownload(item): void {
    this.download.emit(item);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  private buildMobilePaginator(): void {

    this.showPaginator = ((this.config.total / this.config.limit) > 1);
    this.lastPage = Math.ceil(this.config.total / this.config.limit);

  }

  public verifyTootipItem(title) {

    if (title && title.roleGroup) {
      return this.translatePipe.transform(title.roleGroup);
    }
    return '';
  }

  public isColoredLabel(item, row): any {
    if (item.isColored && row.color) {
      return { color: row.color, 'font-weight': 'bold' };
    } else {
      return { color: 'black' };
    }
  }


  public emitAction(row, action): void {

    switch (action.type) {
      case TableActionsEnum.EDIT:
        this.onEdit(row);
        break;
      case TableActionsEnum.REMOVE:
        this.onRemove(row);
        break;
      case TableActionsEnum.DETAILS:
        this.onRemove(row);
        break;
      case TableActionsEnum.DOWNLOAD:
        this.onDownload(row);
        break;
        case TableActionsEnum.REFRESH:
        row.isRefresh = true;
        this.onEdit(row);
        break;
    }
  }

  public verificaStatusDetalhes (row, action): boolean{
//action.type === TableActionsEnum.EDIT
    if(row.details !== null && row.details == false){
      return true;
    }
    return false;
  }

  public returnCurrencyType(): string{
    return this.translateService.instant('global.currency')
  }
}
