import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CNPJValidator, CPFValidator } from '../../utils/validations';

enum Mask {
  CPF = '000.000.000-000',
  CNPJ = '00.000.000/0000-00'
}
@Component({
  selector: 'app-cpf-cnpj-field',
  templateUrl: './cpf-cnpj-field.component.html',
  styleUrls: ['./cpf-cnpj-field.component.scss']
})
export class CpfCnpjFieldComponent implements OnInit {


  @Input()
  form = this.fb.group({
    cnpjCpf: [null, Validators.required]
  });

  @Output()
  enterEvent = new EventEmitter();
  
  mask = Mask.CNPJ;

  validator = false;
  constructor(public fb: FormBuilder) { }

  ngOnInit(): void {

    const document = this.formData['cnpjCpf'].value ? this.formData['cnpjCpf'].value.replace(/[^\d]+/g, '') : null;

    if (document && document.length > 11) {
      this.mask = Mask.CNPJ;
    } else {
      this.mask = Mask.CPF;
    }


    this.form.get("cnpjCpf").valueChanges.subscribe(field => {
      if (field && field.length > 11) {
        this.mask = Mask.CNPJ;
      } else {
        this.mask = Mask.CPF;
      }

    });
  }

  public emitItem(): void {

    const field = this.formData['cnpjCpf'].value;

    if (field) {

      switch (field.length) {
        case 11:
          this.validator = !(CPFValidator(field))
          break;
        case 14:
          this.validator = !(CNPJValidator(field));
          break;
        default:
          this.form.setErrors({ notUnique: true });
          this.validator = true;
          return;
      }

      if (this.validator) {
        this.form.setErrors({ notUnique: true });
      } else {
        this.form.setErrors(null);
        this.formData['cnpjCpf'].setErrors(null)
      }
    }
  }

  public emitEnter(): void {
    this.enterEvent.emit();
  }

  get formData() {
    return this.form.controls;
  }

}
