import { PolicyModalDetailsComponent } from 'src/app/shared/components/policy-modal-details/policy-modal-details.component';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavbarContainerComponent } from './navbar-container/navbar-container.component';
import { TranslaterComponent } from './translater/translater.component';
import { MessageComponent } from './message/message.component';
import { BasicCommandsComponent } from './basic-commands/basic-commands.component';
import { TableComponent } from './table/table.component';
import { SelectComponent } from './select/select.component';
import { NgxResizeWatcherDirective } from '../directives/ngx-resize-watcher.directive';
import { EdgeBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SimpleCheckListComponent } from './simple-check-list/simple-check-list.component';
import { BtnActionsComponent } from './btn-actions/btn-actions.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { HiddenDefaultFieldsComponent } from './hidden-default-fields/hidden-default-fields.component';
import { ContactComponent } from './contact/contact.component';
import { AddressComponent } from './address/address.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBoostrapModule } from '../modules/ngx-bootstrap.module';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { CheckListTabledComponent } from './check-list-tabled/check-list-tabled.component';
import { NgxMaskModule } from 'ngx-mask';
import { CompanyFormComponent } from './company-form/company-form.component';
import { SubcompaniesFormComponent } from './subcompanies-form/subcompanies-form.component';
import { ContextCompaniesComponent } from './context-companies/context-companies.component';
import { CheckListFilteredComponent } from './check-list-filtered/check-list-filtered.component';
import { CpfCnpjFieldComponent } from './cpf-cnpj-field/cpf-cnpj-field.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { RepresentativeListComponent } from './representative-list/representative-list.component';
import { BankAccountsListComponent } from './bank-accounts-list/bank-accounts-list.component';
import { GuarantorsListComponent } from './guarantors-list/guarantors-list.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { ConfirmDialogFieldComponent } from './confirm-dialog-field/confirm-dialog-field.component';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { PhoneFieldComponent } from './phone-field/phone-field.component';
import { BasicListItemsComponent } from './basic-list-items/basic-list-items.component';
import { SimpleDashboardComponent } from './simple-dashboard/simple-dashboard.component';
import { HistoricListComponent } from './historic-list/historic-list.component';
import { CpfFieldComponent } from './cpf-field/cpf-field.component';
import { ValuesDetailListComponent } from './values-detail-list/values-detail-list.component';
import { RatingCompassComponent } from './rating-compass/rating-compass.component';
import { FocusDirective } from '../directives/focus.directive';
import { OnlyNumbersDirective } from '../directives/only-numbers.directive';
import { BrazilMapComponent } from './brazil-map/brazil-map.component';
import { ModalTableListComponent } from './modal-table-list/modal-table-list.component';
import { MaterialTableComponent } from './material-table/material-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FilterItemDirective } from '../directives/filter-item.directive';
import { GraphicBarComponent } from './graphic-bar/graphic-bar.component';
import { ChartsModule } from 'ng2-charts';
import { GraphicLineChartComponent } from './graphic-line-chart/graphic-line-chart.component';
import { MaterialTableModalComponent } from './material-table-modal/material-table-modal.component';
import { MatSelectComponent } from './mat-select/mat-select.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { WidgetsDashboardComponent } from './widgets-dashboard/widgets-dashboard.component';
import { PhoneCodeComponent } from './phone-code/phone-code.component';
@NgModule({
    declarations: [
        NavbarContainerComponent,
        TranslaterComponent,
        MessageComponent,
        BasicCommandsComponent,
        TableComponent,
        SelectComponent,
        NgxResizeWatcherDirective,
        EdgeBreadcrumbComponent,
        SimpleCheckListComponent,
        BtnActionsComponent,
        ConfirmDialogComponent,
        HiddenDefaultFieldsComponent,
        ContactComponent,
        AddressComponent,
        MultipleSelectComponent,
        CheckListTabledComponent,
        CompanyFormComponent,
        SubcompaniesFormComponent,
        ContextCompaniesComponent,
        CheckListFilteredComponent,
        CpfCnpjFieldComponent,
        ContactListComponent,
        RepresentativeListComponent,
        BankAccountsListComponent,
        GuarantorsListComponent,
        BasicModalComponent,
        ConfirmDialogFieldComponent,
        SimpleTableComponent,
        PhoneFieldComponent,
        BasicListItemsComponent,
        SimpleDashboardComponent,
        HistoricListComponent,
        CpfFieldComponent,
        ValuesDetailListComponent,
        RatingCompassComponent,
        FocusDirective,
        OnlyNumbersDirective,
        BrazilMapComponent,
        ModalTableListComponent,
        MaterialTableComponent,
        FilterItemDirective,
        GraphicBarComponent,
        GraphicLineChartComponent,
        MaterialTableModalComponent,
        MatSelectComponent,
        BackButtonComponent,
        WidgetsDashboardComponent,
        PhoneCodeComponent,
        PolicyModalDetailsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxBoostrapModule,
        NgxMaskModule.forRoot(),
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSortModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatMenuModule,
        MatRippleModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        ChartsModule
    ],
    exports: [
        NavbarContainerComponent,
        TranslaterComponent,
        TranslateModule,
        BasicCommandsComponent,
        TableComponent,
        SelectComponent,
        NgxResizeWatcherDirective,
        EdgeBreadcrumbComponent,
        SimpleCheckListComponent,
        BtnActionsComponent,
        ConfirmDialogComponent,
        HiddenDefaultFieldsComponent,
        ContactComponent,
        AddressComponent,
        MultipleSelectComponent,
        CheckListTabledComponent,
        CompanyFormComponent,
        SubcompaniesFormComponent,
        ContextCompaniesComponent,
        CheckListFilteredComponent,
        CpfCnpjFieldComponent,
        ContactListComponent,
        RepresentativeListComponent,
        GuarantorsListComponent,
        BankAccountsListComponent,
        BasicModalComponent,
        ConfirmDialogFieldComponent,
        SimpleTableComponent,
        PhoneFieldComponent,
        BasicListItemsComponent,
        SimpleDashboardComponent,
        HistoricListComponent,
        ValuesDetailListComponent,
        RatingCompassComponent,
        FocusDirective,
        OnlyNumbersDirective,
        BrazilMapComponent,
        ModalTableListComponent,
        NgxBoostrapModule,
        MaterialTableComponent,
        GraphicBarComponent,
        GraphicLineChartComponent,
        MatSelectComponent,
        BackButtonComponent,
        WidgetsDashboardComponent,
        PhoneCodeComponent,
        PolicyModalDetailsComponent
    ],
    providers: [
        TranslatePipe,
        DatePipe,
        CurrencyPipe
    ],
    bootstrap: []
})
export class ComponentsModule { }
