import { RequestService } from './../../../shared/services/request.service';
import { Company } from './../../../shared/enum/company-enum';
import { Messages } from './../../../shared/enum/messages.enum';
import { MessageService } from './../../../shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { ResponseModel } from 'src/app/shared/models/response.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { ClaimsModel } from 'src/app/shared/models/claims.model';
import { APIS } from 'src/app/shared/enum/apis.enum';
import { Fields } from 'src/app/shared/enum/fields.enum';
import { AccessTypeData } from 'src/app/shared/data/access-type.data';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ContextCompaniesComponent } from 'src/app/shared/components/context-companies/context-companies.component';
import { environment } from 'src/environments/environment';

enum AccessTypes {
  MASTERS = 'masters',
  CUSTODIANS = 'custodians',
  MANAGERS = 'managers',
  CONSULTANTS = 'consultants',
  ADMINS = 'admins'
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(5)]],
    rememberMe: [false],
  });

  forgotPassword = false;
  logoImg = 'assets/images/logo.png';
  isIdcompany = false;

  accesses = [];

  userAccessTypes;

  types = [
    {
      type: 0,
      label: 'global.master',
      field: AccessTypes.MASTERS,
      icon: 'fa fa-key'
    },
    {
      type: 1,
      label: 'global.custodian',
      field: AccessTypes.CUSTODIANS,
      icon: 'fa fa-user-secret'
    },
    {
      type: 2,
      label: 'global.administrator',
      field: AccessTypes.ADMINS,
      icon: 'fa fa-lock'
    },
    {
      type: 3,
      label: 'global.manager',
      field: AccessTypes.MANAGERS,
      icon: 'fa fa-handshake-o'
    },
    {
      type: 4,
      label: 'global.consultant',
      field: AccessTypes.CONSULTANTS,
      icon: 'fa fa-eye'
    }
  ];

  acessesType = AccessTypeData.acessesType;

  persistentData;

  constructor(private fb: FormBuilder,
    private service: LoginService,
    private auth: AuthService,
    private message: MessageService,
    private router: Router,
    private requestService: RequestService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.verifyCompany();

    if (this.auth.getAuthentication()) {
      this.router.navigate(['/home/dashboards/wallet']);
    }
  }

  public login(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.service.login(this.loginForm.value).subscribe((success: ResponseModel) => {
      this.auth.setAuthentication(success.model);
      this.getAcessType();
    });
  }

  public resetPassword(): void {
    this.service.forgotPassword(this.loginForm.get('email').value).subscribe((sucess: ResponseModel) => {
      this.forgotPassword = false;
    }, (error) => {
      this.message.show('RETRIEVE_PASSWORD', Messages.ERROR);
    });
  }

  private getUserData(): void {
    this.service.getUserData().subscribe((user: ResponseModel) => {
      localStorage.setItem(Fields.USER, JSON.stringify({
        username: user.model.name,
        email: user.model.email,
      }));
    });
  }

  private buildClaims(claims: ClaimsModel): void {
    claims = new ClaimsModel(claims);
    let claimsObject;

    Object.keys(claims).map((claim) => {
      claimsObject = {
        ...claimsObject,
        [`${claim}`]: true
      }
    });

    this.auth.setClaims(claimsObject);
  }

  private verifyCompany(): void {
    const isIdcompany = window.location.href.includes(Fields.IDSF);

    if (isIdcompany) {
      this.logoImg = 'assets/images/id-logo.png';
      localStorage.setItem(Fields.COMPANY, Company.ID.toString());
      this.isIdcompany = true;
    }
  }

  public getCompanyTypes(): void {
    this.requestService.list(`${APIS.COMPANY}${APIS.GET_COMMON}company-type`, null).subscribe((company: any) => {

      company.map((c) => {
        c.value = c.name;
        c.label = c.name;
        delete c.name;
      });

      localStorage.setItem(Fields.COMPANY_TYPES, JSON.stringify(company));
    });
  }

  private getRolesByUser(): void {

    this.requestService.list(`${APIS.USER}${APIS.GET_COMMON}${APIS.ROLE}s`, null).subscribe((roles) => {
      const data = roles.model;

      if (data.length > 0) {
        this.types.map((type) => {
          const permission = data.find((dat) => dat.type === type.type);
          if (permission) {
            this.accesses.push(type);
          }
        });

        const accessess = {
          accesses: this.accesses,
          totalTypes: this.accesses.length
        };

        this.auth.setAccessOptions(accessess);

        if (this.persistentData && this.persistentData.isPersistent) {
          this.sendDirectItem({ label: this.persistentData.label, type: this.persistentData.type });
          this.auth.setAllAccesses({ totalTypes: this.accesses.length });
          return;
        }

        let item;

        if (this.accesses.length > 0) {
          if (this.accesses.length > 1) {
            this.router.navigate(['select-access']);
          } else {
            item = this.accesses[0];
            this.sendDirectItem(item);
          }
        } else {
          item = { field: 'funds' };
          this.sendDirectItem(item);
        }
      } else {
        this.message.show('NO_ROLE_ABLE', Messages.ERROR);
        this.router.navigate(['login']);
      }
    });
  }

  private async sendDirectItem(item) {

    this.auth.setAccessType(item);

    const currentType = await this.requestService.post(`${APIS.USER_CURRENT_TYPE}${APIS.REGISTER}`, {
      accessType: item.type,
      isPersistent: true

    }).toPromise();

    if (currentType.code === 200) {
      let success = await this.requestService.post(`${APIS.AUTHENTICATION}/gettoken`, null).toPromise();

      this.getUserData();
      this.getCompanyTypes();

      this.auth.setAuthentication(success.model);
      this.buildClaims(jwt_decode(success.model));

      const companies = await this.requestService.list(`${APIS.COMPANY_CURRENT}${APIS.GET}`, null).toPromise();

      if (companies.model.items.length === 0) {
        this.getCompaniesByUser(item);
      } else {
        const funds = companies.model.items.map((fund) => {
          return { fundId: fund.fundId, name: fund.fund.company.fullName };
        });
        this.auth.saveFunds(funds);
        this.router.navigate(['/home/dashboards/wallet']);
      }
    }
  }

  private getCompaniesByUser(event): void {

    this.requestService.list(`${APIS.COMMON}${APIS.GET_COMMON}${APIS.COMPANY}-type-list`, null).subscribe((companies) => {
      const response = companies.model;
      this.userAccessTypes = response;
      const types = Object.keys(this.acessesType);

      if (response.funds.length > 0) {
        response.totalTypes = response.funds.length;
        this.setAccessesTypes(response, event);
        this.openContextModal();
      } else {
        this.auth.logout();
        this.router.navigate(['login']);
      }
    });
  }


  private openContextModal(): void {
    const modalRef = this.modalService.show(ContextCompaniesComponent,
      {
        class: 'modal-dialog-centered',
        initialState: null
      });

    modalRef.setClass('modal-lg');
    modalRef.content.onClose.subscribe((item) => {
      this.router.navigate(['/home/dashboards/wallet']);
    });
  }

  private setAccessesTypes(data, event) {

    this.auth.setAllAccesses(data);

    const userAccessType = this.userAccessTypes ? this.userAccessTypes[event.field] : [];

    this.auth.setAccessType({
      label: event.label,
      access: event.field,
      companies: userAccessType
    });
  }

  private getAcessType(): void {
    this.requestService.list(`${APIS.USER_CURRENT_TYPE}${APIS.GET_BY_ID}`, null).subscribe((success) => {

      const item = success.model;

      if (item && item.isPersistent) {
        const type = this.types.find((type) => type.type === item.accessType);
        this.persistentData = {
          isPersistent: item.isPersistent,
          label: type.label,
          type: type.type
        };

      }
      this.getRolesByUser();
    });
  }

}
