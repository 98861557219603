<div class="contact-list">
    <div class="card middle-form">
        <div class="card-header card-header--white"><strong>{{'global.addContact' | translate }}</strong>
        </div>
        <div class="contact__form">
            <form [formGroup]="form" class="flex__row--spaced">
                <div class="col-md-4 col-sm-12">
                    <small>{{ 'global.name' | translate}}*</small>
                    <input class="form-control mb-3" type="text" placeholder="{{'global.name' | translate }}"
                        name="name" formControlName="name" maxlength="200">
                </div>
                <div class="col-md-4 col-sm-12">
                    <small>{{ 'global.email' | translate}}*</small>
                    <input class="form-control mb-3" type="email" placeholder="{{'global.email' | translate }}"
                        name="email" formControlName="email" maxlength="100" [pattern]="patterns.EMAIL_PATTERN">
                </div>
                <div class="col-md-2 col-sm-12">
                  <small>{{ 'global.phone' | translate}}*</small>
                  <input maxlength="14" class="form-control mb-3" type="text" placeholder="{{'global.phone' | translate }}"
                      name="phone" [(ngModel)]="phone" [mask]="mask" (keyup)="setPhone()" [ngModelOptions]="{standalone: true}" (blur)="setPhone()">
              </div>

                <div class="btn__div">
                    <button type="button" class="btn btn-primary" [disabled]="form.invalid || !canEnableButtons"
                        (click)="addItem()" (mouseover)='setPhone()'>{{'global.add' | translate }}</button>
                </div>

            </form>

        </div>
    </div>

    <div class="card middle-form">
        <div class="card-header card-header--white"><strong>{{'global.existingContacts' | translate }}</strong>
        </div>

        <div class="table__items">
            <app-basic-commands [total]="config.total" (search)="search($event)" [showNew]="false"></app-basic-commands>

            <app-table [config]="config" (edit)="editItem($event)" (remove)="removeItem($event)"></app-table>
        </div>
    </div>
</div>
