import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { trackByFn } from '../../utils/track-by';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { RequestService } from '../../services/request.service';
import { MessageService } from '../../services/message.service';
import { MoreOptionsBtnEnum } from '../../enum/more-options-btn.enum';
import { Messages } from '../../enum/messages.enum';


@Component({
  selector: 'app-btn-actions',
  templateUrl: './btn-actions.component.html',
  styleUrls: ['./btn-actions.component.scss']
})
export class BtnActionsComponent implements OnInit {

  @Output()
  save: EventEmitter<any> = new EventEmitter();

  @Output()
  onMoreAction: EventEmitter<any> = new EventEmitter();

  @Input()
  form: FormGroup;

  @Input()
  disabled = false;

  @Input()
  showSave = true;

  trackByFn = trackByFn;

  @Input()
  more = [];

  isDropup = true;



  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private confirm: ConfirmDialogService,
    private service: RequestService,
    private message: MessageService) { }

  ngOnInit(): void {
  }

  public back(): void {
    this.router.navigate(['.'], { relativeTo: this.activatedRoute.parent });
  }

  public saveAction(): void {
    this.save.emit();
  }

  public onMoreOptions(item): void {

    if(item.action === MoreOptionsBtnEnum.SELLER_ACTIONS) {
      this.onMoreAction.emit(item);
      return;
    }

    if (item.action === MoreOptionsBtnEnum.NEW) {
      this.router.navigate(['add'], { relativeTo: this.activatedRoute.parent })
    } else {
      const option = this.confirm.openDialog(null);

      option.subscribe((op) => {
        const id = this.form.controls['id'].value;
        if (id) {
          switch (item.action) {
            case MoreOptionsBtnEnum.REMOVE:
              this.service.delete(item.url, id).subscribe((deleted) => {
                this.message.show(Messages.SUCESS_DEFAULT, Messages.SUCESS);
                this.back();
              });
              break;
            case MoreOptionsBtnEnum.USER:
              this.service.post(`${item.url}${id}`, null).subscribe((option) => {
                this.message.show(Messages.SUCESS_DEFAULT, Messages.SUCESS);
                this.back();
              });
              break;
          }
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    // const id = this.form.controls['id'] ? this.form.controls['id'].value : null;
    // if (id && this.more.length > 0 && window.innerWidth > 450) {

    //   const left = getComputedStyle(document.getElementById('moreItems'), '').left;

    //   if (left === '10px') {
    //     document.getElementById('moreItems').classList.remove('closed');
    //     document.getElementById('moreItems').classList.add('opened');
    //   } else {
    //     document.getElementById('moreItems').classList.remove('opened');
    //     document.getElementById('moreItems').classList.add('closed');
    //   }
    // }
  }
}
