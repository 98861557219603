<form [formGroup]="hiddenForm">
    <div class="col-md-4">

        <small>Id</small>
        <input class="form-control mb-3" type="text" placeholder="id" name="id" formControlName="id">

    </div>

    <div class="col-md-4">

        <small>{{'fields.createdBy' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'fields.createdBy' | translate }}" name="createdBy"
            [(ngModel)]="username" [ngModelOptions]="{standalone: true}" disabled>

    </div>

    <div class="col-md-4">

        <small>{{'fields.createdDate' | translate }}</small>
        <input class="form-control mb-3" type="text" placeholder="{{'fields.dateCreated' | translate }}" name="dateCreated"
            formControlName="dateCreated">
    </div>
</form>