import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RequestService } from '../../services/request.service';
import { APIS } from '../../enum/apis.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hidden-default-fields',
  templateUrl: './hidden-default-fields.component.html',
  styleUrls: ['./hidden-default-fields.component.scss']
})
export class HiddenDefaultFieldsComponent implements OnInit, AfterViewInit {


  @Input()
  hiddenForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    userIdCreated: [{ value: null, disabled: true }],
    dateCreated: [{ value: null, disabled: true }]
  });

  @Input()
  userId: string;

  username: string;

  constructor(private fb: FormBuilder,
    private datePipe: DatePipe,
    private service: RequestService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService) { }

  ngOnInit(): void {

    if (this.hiddenForm.controls['dateCreated'].value && !this.hiddenForm.controls['dateCreated'].value.includes('/')) {
      this.hiddenForm.controls['dateCreated'].setValue(this.datePipe.transform(this.hiddenForm.controls['dateCreated'].value, this.returnCurrencyDate()));
    }

    this.getUserById();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  private getUserById(): void {
    if (this.userId) {
      this.service.getById(`${APIS.USER}${APIS.GET_BY_ID}`, this.userId).subscribe((user) => {
        this.username = user.model ? user.model.name : null;
      });
    }
  }

  public returnCurrencyDate(): string{
    return this.translateService.instant('global.dateFormat');
  }
}
