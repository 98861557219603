import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/shared/models/response.model';
;


@Injectable()
export class RequestNoInterceptorService {

    private NO_SPINNER = 'NO_SPINNER';
    headers = { headers: { [`${this.NO_SPINNER}`]: '' } };

    constructor(private http: HttpClient){
    }

    public post(api: string, request: any): Observable<ResponseModel> {
        return this.http.post<ResponseModel>(`${environment.API}/${api}`, request, { headers: { [`${this.NO_SPINNER}`]: '' } });
    }

    public list(api: string, httpParams: HttpParams): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${environment.API}/${api}`, { params: httpParams,  headers: { [`${this.NO_SPINNER}`]: '' }  });
    }

    public getById(api: string, id: string): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${environment.API}/${api}?id=${id}`,{ headers: { [`${this.NO_SPINNER}`]: '' } } );
    }

    public update(api: string, request: any): Observable<ResponseModel> {
        return this.http.put<ResponseModel>(`${environment.API}/${api}`, request, { headers: { [`${this.NO_SPINNER}`]: '' } });
    }

    public delete(api: string, id: any) {
        return this.http.delete<ResponseModel>(`${environment.API}/${api}?id=${id}`, { headers: { [`${this.NO_SPINNER}`]: '' } });
    }
}
