<script src="https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.3/d3.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/topojson/1.6.9/topojson.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/datamaps/0.5.9/datamaps.bra.js"></script>

<div class="map__container">
    <div class="row justify-content-end">
        <a class="col-10 link" (click)="selectMapFilter()" style="margin-top: 7px;"><strong>{{ mapFilterLabel | translate }}</strong></a>
        <a class="col-10 link" (click)="openDetails()" style="margin-top: 1px;"><strong>{{ 'global.details' | translate }}</strong></a>
    </div>


    <div id="{{mapId}}" class="map"></div>

    <div class="container">
    <div [style.opacity]="hoverMap?.properties?.name ? '1' : '0'" class="flex__row--spaced datalist">
        <label><span class="circle--yellow"></span>{{mapFilterLabel | translate}}: {{buildDataLabel(hoverMap)}}</label>
        <label><span class="circle--yellow"></span>{{'global.assignors' | translate}}:
            {{getSellerCount(hoverMap)}}</label>
        <label><span class="circle--yellow"></span>{{'menu.wallet' | translate}}:
            {{getTotalValue(hoverMap) | currency: 'BRL'}}</label>
    </div>
  </div>
</div>
