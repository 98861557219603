import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-check-list-tabled',
  templateUrl: './check-list-tabled.component.html',
  styleUrls: ['./check-list-tabled.component.scss']
})
export class CheckListTabledComponent implements OnInit {

  @Input()
  config = [];

  @Input()
  isFiltered = null;

  @Output()
  selection: EventEmitter<any> = new EventEmitter();

  select = {
    value: null,
    label: "global.type",
    options: []
  };

  trackByFn = trackByFn;

  constructor() { }

  ngOnInit(): void {
  }

  public onSelection() {
    this.selection.emit(this.config);
  }

}
