import { MessageService } from 'src/app/shared/services/message.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';
import { finalize, catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Messages } from 'src/app/shared/enum/messages.enum';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    private totalRequests = 0;
    private TOKEN = 'SESSION_TOKEN';
    private NO_SPINNER = 'NO_SPINNER';

    constructor(private spinner: NgxSpinnerService,
        private auth: AuthService,
        private router: Router,
        private messages: MessageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        this.totalRequests++;

        if(request.headers.get(this.NO_SPINNER) == null || request.headers.get(this.NO_SPINNER) == undefined) {
            this.spinner.show();
        }

        request = this.includeSession(request);

        return next.handle(request).pipe(finalize(() => {
            this.totalRequests--;
            if (this.totalRequests === 0) {
                this.spinner.hide();
            }
        }), map((event) => this.handleSuccessObservable(event, request)), catchError((e) => this.handleErrorObservable(e, this.router))
        );
    }

    includeSession(request) {
        if ((!request.headers.get(this.TOKEN) || request.headers.get(this.TOKEN) === '')) {
            request = request.clone({
                setHeaders: {
                    authorization: `Bearer ${this.auth.getAuthentication()}`,
                },
            });
        }

        return request;
    }

    handleSuccessObservable(event: HttpEvent<any>, request: HttpRequest<any>): HttpEvent<any> {
        if (event instanceof HttpResponse && request.method !== 'GET') {
            if (event.body.messages.length > 0) {
                this.messages.show(event.body.messages[0].key, Messages.SUCESS);
            }
        }
        return event;
    }

    handleErrorObservable(error: Response | any, router: Router): Observable<any> {
        if (error.status === 401 && router.url !== '/login') {
            this.auth.logout();
            return throwError(error);
        }

        if (error.error && error.error.errors && error.error.errors.length > 0) {
            this.messages.show(error.error.errors[0].key, Messages.ERROR);

        } else  if(error.status === 403){
          this.messages.show("PERMISSION_DENIED", Messages.ERROR);

        }else {
            this.messages.show(Messages.ERROR_DEFAULT, Messages.ERROR);
        }

        return throwError(error);
    }
}
