import { Component, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trackByFn } from '../../utils/track-by';
import { Fields } from '../../enum/fields.enum';

@Component({
  selector: 'app-translater',
  templateUrl: './translater.component.html',
  styleUrls: ['./translater.component.scss']
})
export class TranslaterComponent {

  img = 'assets/icons/brazil-flag.png';

  languages = [
    {
      id: 'pt-br',
      name: 'Português',
      image: 'assets/icons/brazil-flag.png',
    },
    {
      id: 'en',
      name: 'Inglês',
      image: 'assets/icons/united-states-flag.png'
    }
  ];
  trackByFn = trackByFn;

  @Output()
  change: EventEmitter<any> = new EventEmitter();


  constructor(public translate: TranslateService) {
    translate.addLangs(this.languages.map(i => i.id));

    const lang = this.getLang();

    if (!lang || lang === 'null') {
      translate.setDefaultLang(this.languages[0].id);
      this.selectLang(this.languages[0]);
    } else {
      this.translate.use(lang);
      this.selectLang(this.languages.find(l => l.id === lang));
      translate.setDefaultLang(lang);
    }
  }

  public selectLang(lang) {
    this.img = lang.image;
    this.translate.use(lang.id);
    this.setLang(lang.id);
    this.change.emit(lang);
  }

  private setLang(lang: string) {
    this.translate.setDefaultLang(lang);
    localStorage.setItem(Fields.LANGUAGE, lang);
  }

  private getLang(): string {
    return localStorage.getItem(Fields.LANGUAGE);
  }


}
