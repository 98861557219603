<div class="back__button__container">
    <div>
        <mat-icon [ngStyle]="{'color': color}" [matMenuTriggerFor]="popupmenu">more_horiz</mat-icon>
    </div>

    <mat-menu #popupmenu>
        <button (click)="return()" class="more__button dropdown-item">
            {{ "global.backToInit" | translate }}
        </button>
    </mat-menu>
</div>