<div class="check__list" *ngFor="let items of config; trackBy: trackByFn">
    <label class="check-list__title">{{'global.' + items.title.toLowerCase() | translate}}</label>
    <div class="check-list">
        <div class="check-list__items" *ngFor="let permitions of items.permissions; trackBy: trackByFn">
            <label class="container-check">{{permitions.label}}
                <input [(ngModel)]="permitions.value" type="checkbox" [checked]="permitions.value"
                    (change)="onSelection()">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</div>