import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  message: string;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
    public modalService: BsModalService) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.message = this.message ? `global.${this.message}` : 'global.confirmMessage';
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  public onYes(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
