import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Fields } from '../../enum/fields.enum';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements OnInit, OnChanges {

  @Input()
  form: FormGroup;

  @Input()
  config: {
    label: '',
    type: Fields
  };

  fields = Fields;

  mask = '(00)0000-00000';

  constructor() { }

  ngOnInit(): void {
    this.removeError();
    this.form.get('phone').valueChanges.subscribe((item) => {
      this.removeError();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue.controls.phone.value.length > 10) {
      this.mask = '(00)00000-0000';
    }
    this.removeError();
  }

  public emitItem(): void {
    let item = this.form.controls.phone.value;
    this.maskVerification(item);
    this.removeError();
  }

  private maskVerification(item): void {
    if (item && item.length > 10) {
      this.mask = '(00)00000-0000';
    } else {
      this.mask = '(00)0000-00000';
    }
    this.removeError();
  }

  private removeError(): void {
    this.form.controls.phone.setErrors(null);
  }
}
