<div class="card-header">
    <div class="flex__row--spaced">

        <div class="col-md-3" *ngIf="showSearch">
            <input type="text" placeholder="{{'global.periodFilter' | translate }}" class="form-control"
                bsDaterangepicker [bsConfig]="{ adaptivePosition: true, rangeInputFormat : 'DD/MM/YYYY' }"
                name="filterDate" [(ngModel)]="filterDate" (ngModelChange)="doSearch($event)" autocomplete="off">
        </div>

        <div class="middle-item">
            <div class="small-text-muted">{{'fields.totalItems' | translate }}</div>
            <strong>{{total}}</strong>
        </div>
        <button id="removeItemBasicCommands" *ngIf="showcheckboxDelete" type="button" class="remove btn btn-danger"
            (click)="onRemove()"> {{'buttons.remove' | translate }}</button>

        <button id="newItemBasicCommands" *ngIf="showNew" type="button" class="new btn btn-primary" (click)="newItem()"
            [disabled]="!newButton"> + {{'buttons.new' | translate }}</button>
    </div>
</div>
