import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable()
export class ContractService {

    disabled = false;
    
    public clientIdentificationForm = this.fb.group({
        name: [{ value: null, disabled: true }, [Validators.required]],
        cpf: [{ value: null, disabled: true }, Validators.required],
        gender: [null, Validators.required],
        schooling: [null],
        identityDocument: [null],
        documentType: [null],
        emitterDepartament: [null],
        expeditionDate: [null],
        emitterState: [null],
        nacionality: [null],
        birthPlace: [null],
        birthDate: [null, Validators.required],
        fathersName: [null],
        mothersName: [null],
        legalSituation: [null],
        responsibleName: [null],
        maritalStatus: [null],
        marriageRegime: [null],
        dependentsNumber: [null],
        partnerSpouseName: [null],
        residentialAddress: [null],
        residentialNumber: [null],
        residentialComplement: [null],
        partnetCpf: [null],
        residentialNeighborhood: [null],
        residentialCity: [null],
        residentialState: [null],
        addressCode: [null],
        residentialCountry: [null],
        residentialPhoneCode: ['+55'],
        residentialPhone: [null],
        residentialMobilePhoneCode: ['+55'],
        residentialMobilePhone: [null],
        residentialfaxCode: ['+55'],
        residentialfax: [null],
        resitentialEmail: [null, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
        occupation: [null],
        workPlace: [null],
        comercialAddress: [null],
        comercialaddressNumber: [null],
        comercialAddressComplement: [null],
        comercialAddressNeighborhood: [null],
        comercialAddressState: [null],
        comercialAddressCity: [null],
        comercialAddressAddressCode: [null],
        comercialAddressCountry: [null],
        comercialPhoneCode: ['+55'],
        comercialPhone: [null],
        comercialCellPhoneCode: ['+55'],
        comercialCellPhone: [null],
        comercialFaxCode: ['+55'],
        comercialFax: [null],
        profession: [null],
        roleInCompany: [null],
        commercialEmail: [null, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
        correspondenceAddress: [null],
        extensionLine: [null]
    });

    public accountsList: any[] = [];
    public referenceList: any[] = [];

    formProperties = this.fb.group({
        propertyDescription: [null],
        propertyValue: [null],
        otherPropertiesFinancialDescription: [null],
        otherPropertiesFinancialValue:[null],
        actionsDescription: [null],
        actionsValue:[null],
        automobilesDescription: [null],
        automobilesValue:[null],
        otherPropertiesDescription: [null],
        otherPropertiesValue:[null],
        monthlyIncomeDescription: [null],
        monthlyIncomValue:[null],
        wageCompensationDescription: [null],
        wageCompensationValue:[null],
        anotherIncomeDescription: [null],
        anotherIncomeValue: [null]
    });

    propertiesList = [];

    formForeignInvestor = this.fb.group({
        legalNature: [null],
        operationalCode: [null],
        bacenNumber: [null],
        originContry: [null]
    });

    participationList = [];

    formDeclarations = this.fb.group({
        onItsOwn: [null, Validators.required],
        operedAccountName: [null],
        isVinculedBroker: [null, Validators.required],
        sendedOrders: [null, Validators.required],
        representativeOrders: [null, Validators.required],
        representativeName: [null],
        representativeCpf: [null]
    });

    formRequiredDeclaration = this.fb.group({
        fundsShareholder: [null],
        distribuition: [null],
        financialInvestiments: [null],
        advisory: [null],
        otherEspecify: [null],
        description: [null],
        amIPoliticallyExposed: [null, Validators.required],
        ifYesExplain: [null],
        amIRelatedPoliticallyExposed: [null, Validators.required],
        ifRelatedExplain: [null],
        relatedName: [null],
        relatedCpf: [null]
    });

    complementaryInformationForm = this.fb.group({
        citizenship: [null, Validators.required],
        ifYesCitizenship: [null],
        addressOtherContry: [null, Validators.required],
        ifYesAddressOtherContry: [null],
        hasResidenceVisaGreenCard: [null, Validators.required],
        ifYesResidenceVisaGreenCard: [null],
        taxDomicileUnitedStates: [null],
        socialNumber: [null],
        abroadMoreThan30Days: [null, Validators.required],
        abroadMoreThanCountries: [null],
        abroadThisYear: [0],
        firstYearAbroad: [0],
        secondYearAbroad: [0],
        resignedNacionality: [null, Validators.required],
        resignedNacionalityContries: [null]

    });

    finalForm = this.fb.group({
        agree: [null, Validators.required],
    });

    listItemsError = {
        bankList: false,
        comercialReference: false
    };

    isFormSubmited: boolean = false;

    registrationStatus = {
        formRegistration: false,
        investorClassification: false,
        factaForm: false,
        suitabilityForm: false,
        documents: false
    };

    bankForm = this.fb.group({
        bankName: [null],
        agencyNumber: [null],
        accountNumber: [null]
    });

    referenceForm = this.fb.group({
        name: [null],
        phone: [null],
        phoneCode: [null]
    });


    societyParticipations = this.fb.group({
        socialReason: [null],
        cnpj: [null],
        participation: [null]
    });

    constructor(private fb: FormBuilder) {

    }

    public updateClientCache(): void {
        const clientIdentification = this.clientIdentificationForm.getRawValue();
        sessionStorage.setItem('CLIENT_IDENTIFICATION', JSON.stringify(clientIdentification));
    }

    public updateBankAccounts(): void {
        sessionStorage.setItem('BANK_ACCOUNTS', JSON.stringify(this.accountsList ? this.accountsList : []));
    }

    public updateReferenceList(): void {
        sessionStorage.setItem('COMERCIAL_REFERENCES', JSON.stringify(this.referenceList));
    }

    public updateFormDeclaration(): void {
        sessionStorage.setItem('REQUIRED_DECLARATIONS', JSON.stringify(this.formRequiredDeclaration.getRawValue()));
    }

    public updateComplementary(): void {
        sessionStorage.setItem('COMPLEMENTARY_INFORMATION', JSON.stringify(this.complementaryInformationForm.getRawValue()));
    }

    public updateFinancialSituation(): void {
        sessionStorage.setItem('FINANCIAL_SITUATION', JSON.stringify({
            formProperties: this.formProperties.getRawValue(),
            propertiesList: this.propertiesList
        }));
    }

    public updateForegnerInvestor(): void {
        sessionStorage.setItem('FOREIGN_INVESTOR', JSON.stringify(this.formForeignInvestor.getRawValue()));
    }

    public updateParticipation(): void {
        sessionStorage.setItem('PARTICIPATION_LIST', JSON.stringify(this.participationList));
    }

    public updateDeclarations(): void {
        sessionStorage.setItem('DECLARATIONS', JSON.stringify(this.formDeclarations.getRawValue()));
    }

    public updateFormStatus(): void {
        sessionStorage.setItem('FORM_STATUS', JSON.stringify(this.registrationStatus));
    }

    public getFormStatus() {
        this.registrationStatus = JSON.parse(sessionStorage.getItem('FORM_STATUS'));
    }

    public getCache(): void {
        const response = sessionStorage.getItem('CLIENT_IDENTIFICATION');

        if (response) {
            this.clientIdentificationForm.setValue(JSON.parse(response));
        }

        const addressResponse = sessionStorage.getItem('BANK_ACCOUNTS');
        if (addressResponse) {
            this.accountsList = JSON.parse(addressResponse);
        }

        const comercialReferences = sessionStorage.getItem('COMERCIAL_REFERENCES');
        if (comercialReferences) {
            this.referenceList = JSON.parse(comercialReferences);
        }

        let financialSituation: any = sessionStorage.getItem('FINANCIAL_SITUATION');

        if (financialSituation) {
            financialSituation = JSON.parse(financialSituation);

            if (financialSituation.formProperties) {
                this.formProperties.setValue(financialSituation.formProperties);
            }

            this.propertiesList = financialSituation.propertiesList;
        }

        const foreignInvestor = sessionStorage.getItem('FOREIGN_INVESTOR');

        if (foreignInvestor) {
            this.formForeignInvestor.setValue(JSON.parse(foreignInvestor));
        }

        const participationList = sessionStorage.getItem('PARTICIPATION_LIST');

        if (participationList) {
            this.participationList = JSON.parse(participationList);
        }

        const declarations = sessionStorage.getItem('DECLARATIONS');

        if (declarations) {
            this.formDeclarations.setValue(JSON.parse(declarations));
        }

        const requiredDeclarations = sessionStorage.getItem('REQUIRED_DECLARATIONS');

        if (requiredDeclarations) {
            this.formRequiredDeclaration.setValue(JSON.parse(requiredDeclarations));
        }

        const complementary = sessionStorage.getItem('COMPLEMENTARY_INFORMATION');

        if (complementary) {
            this.complementaryInformationForm.setValue(JSON.parse(complementary));
        }
    }

    public updateInvestorClassification(rows): void {
        sessionStorage.setItem('INVESTOR_CLASSIFICATION', JSON.stringify(rows));
    }

    public getClassification() {
        return sessionStorage.getItem('INVESTOR_CLASSIFICATION');
    }

    public saveAll(): void {
        this.updateClientCache();
        this.updateBankAccounts();
        this.updateReferenceList();
        this.updateFormDeclaration();
        this.updateComplementary();
        this.updateFinancialSituation();
        this.updateForegnerInvestor();
        this.updateParticipation();
        this.updateDeclarations();
    }

    public updateFactaForm(factaForm) {
        sessionStorage.setItem('FORM_FACTA', JSON.stringify(factaForm));
    }

    public getFactaForm() {
        return JSON.parse(sessionStorage.getItem('FORM_FACTA'));
    }

    public updateSuitabilityForm(suitability): void {
        sessionStorage.setItem('SUITABILITY', JSON.stringify(suitability));
    }

    public getSuitabilityForm() {
        return sessionStorage.getItem('SUITABILITY');
    }

    public updateFiles(files): void {
        sessionStorage.setItem('FILES', JSON.stringify(files));
    }

    public getFiles() {
        return sessionStorage.getItem('FILES');
    }


    public saveContractId(contractId: string): void {
        sessionStorage.setItem('CONTRACT_ID', contractId);
    }

    public getContractId(): string {
       return sessionStorage.getItem('CONTRACT_ID');
    }

    public setPrimaryColor(color: string): void {
        sessionStorage.setItem('COLOR', color);
    }


    public getContractPrimaryColor(): string {
        return sessionStorage.getItem('COLOR');
    }

    public saveCookies(show: string) {
        sessionStorage.setItem('cookies', show);
    }

    public getCookies(): boolean {
        const saved = sessionStorage.getItem('cookies');

        if(saved == 'true') {
            return false;
        } else {
            return true;
        }
    }
}
