<div class="context__title">
    {{'global.contextCompanies' | translate}}
</div>

<div class="context">

    <div class="context__info">
        <p>{{'global.access' | translate}}: {{accessLabel | translate}}</p>

        <p *ngIf="accessType !== CONSULTANT">{{'global.selectCompanies' | translate}}</p>
        <p *ngIf="accessType === CONSULTANT">{{'global.selectFundsCompanies' | translate}}</p>
    </div>

    <div class="context__view">

        <span>{{ 'global.selectTotalCompanies' | translate }} {{values.length}}</span>

        <app-check-list-filtered *ngIf="configCompanies?.permissions?.length > 0" [config]="configCompanies" [select]="isFiltered" (selection)="onSelectedChange($event)"></app-check-list-filtered>
    </div>

    <div class="context__actions">
        <button type="button" class="btn btn-secondary" (click)="onBack()">{{'buttons.back' | translate }}</button>
        <button type="button" class="btn btn-success" (click)="onSave()" [disabled]="values.length === 0">{{'buttons.save' | translate }}</button>
    </div>
</div>
