import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trackByFn } from './../../utils/track-by';


@Component({
  selector: 'app-simple-check-list',
  templateUrl: './simple-check-list.component.html',
  styleUrls: ['./simple-check-list.component.scss']
})
export class SimpleCheckListComponent implements OnInit {

  @Output()
  selection: EventEmitter<any> = new EventEmitter();

  @Input()
  config;

  trackByFn = trackByFn;
  
  constructor() { }

  ngOnInit(): void {
  }

  public onSelection() {
    this.selection.emit(this.config);
  }

}
