<div class="desktop__table" *ngIf="!isMobile">
  <ngx-datatable [messages]="{emptyMessage: 'global.noDataFound' | translate }" [rows]="config?.rows"
    [columns]="config?.columns" [limit]="config?.limit" class="bootstrap" [columnMode]="'force'" [rowHeight]="'auto'"
    [headerHeight]="50" [footerHeight]="50" appNgxResizeWatcher #table>
    <ngx-datatable-column *ngFor="let item of config?.columns; let i = index; trackBy: trackByFn"
      name="{{ item.header | uppercase }}" prop="{{ item.prop || item.header }}">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <strong><span>{{ "global." + item.header | translate }}</span></strong>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <label [ngStyle]="isColoredLabel(item, row)" *ngIf="item.type !== columnsType.TOOLTIP; else elseBlock">{{
          buildRow(item, row[item.header])
        }}</label>
        <ng-template #elseBlock>
          <label *ngIf="row[item.header]">
            <div>
              <div *ngIf="item.header == 'groups'; else companyBlock">
                <span>{{ verifyTootipItem(row[item.header].title)}}
                  <span *ngIf="row[item.header].title">:</span>
                  <span *ngIf="!row[item.header].title">{{'global.notRegistred' | translate}}</span>
                  {{row[item.header].title?.name}}</span>

                <ng-template #popTemplate>
                  <span class="span__tooltip" *ngFor="let item of row[item.header].tooltip; trackBy: trackByFn">
                    {{item.roleGroup | translate}}: {{item.name}}
                  </span>
                </ng-template>
                <a *ngIf="row[item.header]?.total > 0" [routerLink]="" [tooltip]="popTemplate" placement="bottom">
                  {{ "global.and" | translate }}
                  {{ "global.more" | translate }}
                  {{ row[item.header].total }}
                </a>
              </div>
              <ng-template #companyBlock>
                <a *ngIf="
                    row[item.header]?.total == 1 ||
                      row[item.header]?.total == 0;
                    else totalBlock
                  ">
                  {{ row[item.header].total }}
                  {{ "global.company" | translate }}
                </a>
                <ng-template #totalBlock>
                  <a>
                    {{ row[item.header].total }}
                    {{ "global.companies" | translate }}
                  </a>
                </ng-template>
              </ng-template>
            </div>
          </label>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="config.options">
      <ng-template let-column="column" ngx-datatable-header-template>
        <strong> {{ "fields.actions" | translate }} </strong>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="action__buttons">

          <span *ngFor="let action of config?.options?.actions;trackBy: trackByFn">
            <button [class]="action.class" *ngIf="action.type !== tableActionsEnum.REFRESH" (click)="emitAction(row, action)" [disabled]="!action.enabled ||  verificaStatusDetalhes(row, action)"
              tooltip="{{action.label | translate}}">
              <i [class]="action.icon"></i>
            </button>

            <button [class]="action.class" *ngIf="action.type === tableActionsEnum.REFRESH && row.refresh"
              (click)="emitAction(row, action)" tooltip="{{action.label | translate}}">
              <i [class]="action.icon"></i>
            </button>
          </span>


          <li class="li__more" dropdown placement="bottom right" *ngIf="config?.options?.more?.length > 0">
            <button class="btn click__item--green" data-toggle="dropdown" data-boundary="window" href="#" role="button"
              aria-haspopup="true" aria-expanded="true" tooltip="{{ 'fields.more' | translate }}" dropdownToggle>
              <i class="fa fa-sort-desc" aria-hidden="true"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right drop__table" *dropdownMenu aria-labelledby="simple-dropdown">
              <button *ngFor="
                  let m of config.options.more[row.index];
                  trackBy: trackByFn
                " (click)="onMoreOptions(row, m)" [disabled]="!m.enabled" class="more__button dropdown-item">
                {{ "fields." + m.label | translate }}
              </button>
            </div>
          </li>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer style="border-style: solid;">
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
        <div class="items__page" style="float: left;  width: 180px;" *ngIf="config.showLimit !== false">
          <div style="position: absolute;">
            <p style="margin-top: 10px; color: #000;">
              {{ "fields.linesPeerPage" | translate }}
            </p>
          </div>

          <div class="items__page" style="float: right; position: relative; ">
            <app-select [value]="select.value" [label]="select.label" [options]="select.options"
              (selection)="selectItem($event)"></app-select>
          </div>
        </div>

        <div class="bd-highlight">
          <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="pageControl"
            [size]="pageSize" [count]="config.total" [hidden]="!(config.total / pageSize > 1)" (change)="page($event)">
          </datatable-pager>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<div class="mobile__table" *ngIf="isMobile">
  <ul *ngFor="let row of config?.rows; trackBy: trackByFn">
    <li *ngFor="let column of config.columns; trackBy: trackByFn">
      <small>{{ "global." + column.header | translate }}:</small>
      <label [ngStyle]="isColoredLabel(column, row)" *ngIf="column.type !== columnsType.TOOLTIP; else elseBlock">
        {{ buildRow(column, row[column.header]) }}</label>
      <ng-template #elseBlock><label>
          <label *ngIf="row[column.header]">
            <div>
              <div *ngIf="column.header == 'groups'; else companyBlock">
                <span>{{ verifyTootipItem(row[column.header].title)}}
                  <span *ngIf="row[column.header].title">:</span>
                  <span *ngIf="!row[column.header].title">{{'global.notRegistred'| translate}}</span>
                  {{row[column.header].title?.name}}</span>
                <ng-template #popTemplate>
                  <span class="span__tooltip" *ngFor="let item of row[column.header].tooltip; trackBy: trackByFn">
                    {{item.roleGroup | translate}}: {{item.name}}
                  </span>
                </ng-template>
                <a *ngIf="row[column.header]?.total > 0" [routerLink]="" [tooltip]="popTemplate" placement="bottom">
                  {{ "global.and" | translate }}
                  {{ "global.more" | translate }}
                  {{ row[column.header].total }}</a>
              </div>
              <ng-template #companyBlock>
                <a *ngIf="
                    row[column.header]?.total == 1 ||
                      row[column.header]?.total == 0;
                    else totalBlock
                  ">
                  {{ row[column.header]?.total }}
                  {{ "global.company" | translate }}
                </a>
                <ng-template #totalBlock>
                  <a>
                    {{ row[column.header]?.total }}
                    {{ "global.companies" | translate }}
                  </a>
                </ng-template>
              </ng-template>
            </div>
          </label>
        </label></ng-template>
    </li>
    <div *ngIf="config.options" class="action__container">
      <li *ngFor="let action of config?.options?.actions;trackBy: trackByFn" class="action__icons">
        <button [class]="action.class" *ngIf="action.type !== tableActionsEnum.REFRESH" [disabled]="!action.enabled"
          (click)="emitAction(row, action)" tooltip="{{action.label | translate}}">
          <i [class]="action.icon"></i>
        </button>
        <button [class]="action.class" *ngIf="action.type === tableActionsEnum.REFRESH && row.refresh" (click)="emitAction(row, action)"
          tooltip="{{action.label | translate}}">
          <i [class]="action.icon"></i>
        </button>
      </li>

      <li class="mobile__more" dropdown placement="bottom right" *ngIf="config?.options?.more?.length > 0">
        <button class="btn click__item--green" data-toggle="dropdown" data-boundary="window" href="#" role="button"
          aria-haspopup="true" aria-expanded="true" tooltip="{{ 'fields.more' | translate }}" dropdownToggle>
          <i class="fa fa-sort-desc" aria-hidden="true"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right drop__table" *dropdownMenu aria-labelledby="simple-dropdown">
          <div *ngFor="let m of config.options.more[row.index]; trackBy: trackByFn" (click)="onMoreOptions(row, m)"
            class="dropdown-item">
            {{ "fields." + m.label | translate }}
          </div>
        </div>
      </li>
    </div>
  </ul>
  <div class="mobile__paginator">
    <div class="items__page" style="float: left;  width: 95%;">
      <div style="position: absolute;">
        <p style="margin-top: 10px; color: #000;">
          {{ "fields.linesPeerPage" | translate }}
        </p>
      </div>

      <div class="items__page" style="float: right; position: relative; ">
        <app-select [value]="select.value" [label]="select.label" [options]="select.options" [isTranslated]="false"
          (selection)="selectItem($event)"></app-select>
      </div>
    </div>
  </div>

  <div *ngIf="showPaginator" class="mobile__paginator">
    <button (click)="page(1)">
      <i class="cui-chevron-left  icons font-2xl d-block"></i>
      <i class="cui-chevron-left  icons font-2xl d-block"></i>
    </button>
    <i class="cui-chevron-left  icons font-2xl d-block" (click)="page(config?.page - 1)"></i>
    <label>{{ "fields.page" | translate }} {{ config?.page }}</label>
    <i class="cui-chevron-right  icons font-2xl d-block" (click)="page(config?.page + 1)"></i>
    <button (click)="page(lastPage)">
      <i class="cui-chevron-right  icons font-2xl d-block"></i>
      <i class="cui-chevron-right  icons font-2xl d-block"></i>
    </button>
  </div>
</div>