<mat-form-field>
    <mat-label>{{'global.code' |translate}}</mat-label>
    <mat-select [formControl]="control" class="phone__code" (selectionChange)="emitSelect()">
        <mat-select-trigger>
            {{control.value}}
        </mat-select-trigger>
        <mat-option *ngFor="let option of countriesList" [value]="option.code">
            <img src="assets/icons/flags/{{option.initials}}.png"> {{option.name}} ({{option.code}})
        </mat-option>
    </mat-select>
</mat-form-field>