<select *ngIf="isTranslated" [(ngModel)]="value" (change)="onChange($event.target.value)" class="form-control"
    name="select" [disabled]="isDisabled">
    <option [value]="null" *ngIf="label" disabled>{{ label | translate }}</option>
    <option *ngFor="let op of options; trackBy: trackByFn" [value]="op.value" >
        {{op.label | translate}}</option>
</select>

<select *ngIf="!isTranslated" [(ngModel)]="value" (change)="onChange($event.target.value)" class="form-control"
    name="select"  [disabled]="isDisabled">
    <option [value]="null" *ngIf="label" disabled>{{ label | translate }}</option>
    <option *ngFor="let op of options; trackBy: trackByFn" [value]="op.value">{{op.label}}
    </option>
</select>