import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-table-list',
  templateUrl: './modal-table-list.component.html',
  styleUrls: ['./modal-table-list.component.scss']
})
export class ModalTableListComponent implements OnInit {

  title;
  titleConfig;
  config;
  titleConfig2;
  config2;
  status;
  fileName;
  certifier;
  type = null;

  onClose: Subject<any>;

  constructor() { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

}
