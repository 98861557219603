import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { trackByFn } from '../../utils/track-by';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input()
  options: any[];

  @Input()
  label: string;

  @Input()
  value = 0;

  @Output()
  selection: EventEmitter<any> = new EventEmitter();

  @Input()
  isTranslated = true;

  @Input()
  isDisabled = false;

  trackByFn = trackByFn;
  constructor() { }

  ngOnInit(): void {
  }

  public onChange(value): void {
    this.selection.emit(value);
  }
}
