import { ActionsEnum } from './../../enum/actions.enum';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnsType } from '../../enum/columns-types.enum';
import { TableActionsEnum } from '../../enum/table-actions.enum';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Fields } from '../../enum/fields.enum';
import { Columns } from '../../models/columns.model';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { trackByFn } from '../../utils/track-by';
import { TableConfig } from '../../models/table-config.model';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterConditionsEnum } from '../../enum/filter.conditions.enum';
import { MaskPipe } from 'ngx-mask';


const CONDITIONS_LIST = [
  { value: FilterConditionsEnum.EQUAL, label: 'isEqual' },
  { value: FilterConditionsEnum.DIFFERENT, label: 'isNotEqual' },
  { value: FilterConditionsEnum.CONTAINS, label: 'contain' },
  { value: FilterConditionsEnum.BEGINS_WITH, label: 'beginsWith' },
  { value: FilterConditionsEnum.ENDS_WITH, label: 'endsWith' },
];

const CONDITIONS_DATE_LIST = [
  { value: FilterConditionsEnum.EQUAL, label: 'isEqual' },
  { value: FilterConditionsEnum.DIFFERENT, label: 'different' },
  { value: FilterConditionsEnum.GREATER_THAN, label: 'greaterThan' },
  { value: FilterConditionsEnum.LESS_THAN, label: 'lessThan' },
];

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss'],

})
export class MaterialTableComponent implements OnInit, OnChanges, AfterViewInit {

  tableActionsEnum = TableActionsEnum;
  trackByFn = trackByFn;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  config: TableConfig;

  dataSource = new MatTableDataSource();

  displayedColumns: string[] = [];

  @Output()
  pagination: EventEmitter<any> = new EventEmitter();


  @Output()
  limit: EventEmitter<any> = new EventEmitter();

  @Output()
  edit: EventEmitter<any> = new EventEmitter();

  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  @Output()
  more?: EventEmitter<any> = new EventEmitter();

  @Output()
  download: EventEmitter<any> = new EventEmitter();

  @Output()
  checkboxes: EventEmitter<any> = new EventEmitter();

  @Output()
  filter: EventEmitter<any> = new EventEmitter();

  @Input()
  showFilter = true;

  @Input()
  showCheckboxRight = true;

  @Input()
  showSelectedCertifier = false;

  lastPage = 0;
  pageControl = 0;
  length = 0;

  pageSize = 0;

  hideTable = false;

  selection = new SelectionModel<any>(true, []);

  columnsType = ColumnsType;

  filterDate;

  public conditionsList = CONDITIONS_LIST;

  public conditionsDateList = CONDITIONS_DATE_LIST;

  public conditionsListBasic = [
    { value: FilterConditionsEnum.EQUAL, label: 'isEqual' },
    { value: FilterConditionsEnum.DIFFERENT, label: 'isNotEqual' },
  ];
  public searchValue: any = {};
  public searchCondition: any = {};
  public orderby;

  constructor(private translatePipe: TranslatePipe,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    public activateRoute: ActivatedRoute,
    private router: Router,
    private currency: CurrencyPipe,
    private mask: MaskPipe) { }

  ngOnInit(): void {
    this.initTable();

    if (this.config.showLimit !== false && this.paginator) {
      this.changeLabelObservable();
    }

    this.pageSize = this.config.limit;
    this.buildPredicate();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.config = changes.config.currentValue ? changes.config.currentValue : this.config;
    this.dataSource = new MatTableDataSource(this.config.rows);
    this.length = this.config.total;
    this.dataSource.sort = this.sort;
    this.lastPage = Math.ceil(this.config.total / this.config.limit);
    this.selection.clear();
    this.buildCheckedRows();
  }


  private buildCheckedRows(): void {
    this.config.rows.map((item) => {
      if (item.checked) {
        this.selection.select(item);
      }
    });
  }

  public page(event): void {

    if (event.pageSize !== this.pageSize) {
      this.pageSize = event.pageSize;
      this.selectItem(event.pageSize);
      return;
    }
    const page = event.pageIndex !== null ? event.pageIndex : event;

    if (page <= this.lastPage) {
      this.pageControl = page;
      this.pagination.emit(page + 1);
    }
  }

  private changeLabelObservable(): void {
    this.translateService.onDefaultLangChange.subscribe(() => {
      this.paginator._intl.itemsPerPageLabel = this.translateService.instant('fields.linesPeerPage');
    });
  }

  private initTable(): void {

    this.dataSource = new MatTableDataSource(this.config.rows);

    if(this.config.showSelectRigth !== true){
    if (this.config.showSelect !== false) {
      this.displayedColumns.push('select');
    }
  }
    this.displayedColumns.push(...this.config.columns.map(c => c.header));
    this.length = this.config.total;

    if (this.config.showSelectRigth === true) {
      this.displayedColumns.push('select');
    }

    if (this.config.options && this.config.options.actions.length > 0) {
      this.displayedColumns.push('actions');
    }

    this.lastPage = Math.ceil(this.config.total / this.config.limit);

    if (this.config.showLimit !== false && this.paginator) {
      this.paginator._intl.itemsPerPageLabel = this.translateService.instant('fields.linesPeerPage');
    }

  }

  public verifyTootipItem(title) {

    if (title && title.roleGroup) {
      return this.translatePipe.transform(title.roleGroup);
    }
    return '';
  }

  public isColoredLabel(item, row): any {
    if (item.isColored && row.color) {
      return { color: row.color, 'font-weight': 'bold', 'background-color': row.softColor, 'padding': '4px', 'border-radius': '15px', 'font-size': '12px' };
    } else {
      return { color: 'rgb(117, 117, 117)'};
    }
  }


  public emitAction(row, action): void {

    switch (action.type) {
      case TableActionsEnum.EDIT:
        this.onEdit(row);
        break;
      case TableActionsEnum.REMOVE:
        this.onRemove(row);
        break;
      case TableActionsEnum.DETAILS:
        row.isDetails = true;
        this.onEdit(row);
        break;
      case TableActionsEnum.REFRESH:
        row.isRefresh = true;
        this.onEdit(row);
        break;
      case TableActionsEnum.DOWNLOAD:
        this.onDownload(row);
        break;
      case TableActionsEnum.MANAGE:
        this.onEdit(row);
        break;
      case TableActionsEnum.ACTION:
        row.header = action.header;
        this.onEdit(row);
        break;
    }
  }

  public buildRow(column: Columns, item) {
    switch (column.type) {
      case ColumnsType.DATE:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_PATTERN_US);
        }
      case ColumnsType.BOOLEAN:
        return this.buildBooleanValue(item);
      case ColumnsType.TYPE:
        return this.translatePipe.transform(item);
      case ColumnsType.DATE_TIME:
        if(this.returnCurrencyType() == 'BRL'){
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN);
        }else{
          return this.datePipe.transform(item, Fields.DATE_HOUR_PATTERN_US);
        }
      case ColumnsType.CURRENCY:
        return this.currency.transform(item, this.returnCurrencyType());
      case ColumnsType.PHONE:
        if(item){
          return item.lenth > 10 ? this.mask.transform(item, '(00)00000-0000') : this.mask.transform(item, '(00)0000-0000')
        }
          return item;
      default:
        if (item != null && item != undefined) {
          return item;
        }
        return this.translatePipe.transform(Fields.NOT_REGISTRED);
    }
  }

  private buildBooleanValue(value: boolean): string {

    let field = Fields.YES;
    if (!value) {
      field = Fields.NO;
    }
    return this.translatePipe.transform(field);
  }


  public onEdit(item): void {
    this.edit.emit(item);
  }

  public onRemove(item): void {
    this.remove.emit(item);
  }

  public onDownload(item): void {
    this.download.emit(item);
  }

  public onMoreOptions(item, option): void {
    this.more.emit({ id: item.id, option: option.label });
  }


  public selectItem(value) {
    this.pageControl = 0;
    this.limit.emit(value || 10);
  }

  public isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.checkboxes.emit(this.selection.selected);
  }


  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(column) {
    if (column) {

      if (column.header === 'cnpjcpf') {
        column.filterName = column.filterName.replace(/\D+/g, '');
      }

      if(column.type === ColumnsType.DATE || column.type === ColumnsType.DATE_TIME){
        let date = this.datePipe.transform(this.filterDate, "MM/dd/yyyy")

        this.filter.emit({
          column: column.filterName ? column.filterName : column.header,
          search: date !== null ? date : '',
          condition: this.searchCondition[column.header],
          orderby: this.orderby
        });
      }else{
        this.filter.emit({
          column: column.filterName ? column.filterName : column.header,
          search: this.searchValue[column.header] !== null ? this.searchValue[column.header].toString().toLowerCase() : '',
          condition: this.searchCondition[column.header],
          orderby: this.orderby
        });
      }
    } else {
      this.filter.emit(null);
    }
  }

  clearColumn(columnKey: string): void {
    this.filterDate = null;
    this.searchValue[columnKey] = null;
    this.searchCondition[columnKey] = 'none';
    this.applyFilter(null);
  }

  private buildPredicate(): void {
    this.dataSource.filterPredicate = (p: any, filtre: any) => {
      let result = true;
      const keys = this.displayedColumns.filter((fl) => fl !== 'select' && fl !== 'action' && fl !== 'selectRigth');

      for (const key of keys) {
        const searchCondition = filtre.conditions[key];

        if (searchCondition && searchCondition !== 'none') {
          if (filtre.methods[searchCondition](p[key], filtre.values[key]) === false) {
            result = false;
            break;
          }
        }
      }

      return result;
    };
  }

  public selectRow(row): void {
    this.selection.toggle(row);
    this.checkboxes.emit(this.selection.selected);
  }

  public emitLink(column, row): void {
    this.router.navigate([`update/${row.id}`], { relativeTo: this.activateRoute });
  }

  public verificaStatusOperacao(row, action): boolean {

    if (row.update !== null && row.update == false && action.type === TableActionsEnum.EDIT) {
      return true;
    }
    return false;
  }

  public verifyColumnDifferent(column): boolean {
    if (![ColumnsType.TOOLTIP, ColumnsType.CHECK, ColumnsType.ACTION, ColumnsType.THUMBS_UP].includes(column.type)) {
      return true;
    }
    return false;
  }

  public buildUrl(updateLink: string, id: string): string {

    if (updateLink.includes(`/update/`)) {
      const firstIndex = updateLink.indexOf('/update/');
      updateLink = updateLink.substring(0, firstIndex);
    }
    return `${updateLink}/update/${id}`;
  }

  public matSortChange(event): void{
    if(event.direction){
      this.orderby = event.direction;
      if(this.searchValue.name == undefined){
        this.filter.emit({
          column: event.active,
          orderby: event.direction
        });
      }else{
          if(this.filterDate == undefined){
            this.filter.emit({
            column: event.active,
            orderby: event.direction,
            search: this.searchValue.name,
            condition: this.searchCondition.name
          });
          }else{
            let date = this.datePipe.transform(this.filterDate, "MM/dd/yyyy");
            this.filter.emit({
              column: event.active,
              orderby: event.direction,
              search: date,
              condition: this.searchCondition.name
            });
        }
      }
    }else{
      this.filter.emit(null);
    }
  }

  public returnCurrencyType(): string{
    return this.translateService.instant('global.currency')
  }
}
