import { ClaimsModel } from 'src/app/shared/models/claims.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Fields } from 'src/app/shared/enum/fields.enum';
import { AccessTypesModel } from 'src/app/shared/models/access-types.model';
import { BasicFundListModel } from 'src/app/shared/models/basic-fund-list.model';


@Injectable()
export class AuthService {

    private SESSION_TOKEN = 'SESSION_TOKEN';
    private CLAIMS = 'CLAIMS';
    private ACCESS_TYPE = 'ACCESS_TYPE';
    private ALL_ACCESSES = 'ALL_ACCESSES';
    private ACCESS_OPTIONS = 'ACCESS_OPTIONS';
    private FUNDS = 'FUNDS';

    constructor(private router: Router) {

    }

    public setAuthentication(session: string): void {
        localStorage.setItem(this.SESSION_TOKEN, session);
    }

    public getAuthentication(): string {
        return localStorage.getItem(this.SESSION_TOKEN);
    }

    public logout(): void {
        const language = localStorage.getItem(Fields.LANGUAGE);
        localStorage.clear();
        localStorage.setItem(Fields.LANGUAGE, language);
        this.router.navigate(['/login']);
    }

    public setClaims(claims: ClaimsModel): void {
        localStorage.setItem(this.CLAIMS, JSON.stringify(claims));
    }

    public getClaims(): ClaimsModel {
        return JSON.parse(localStorage.getItem(this.CLAIMS));
    }

    public setAccessType(item) {
        localStorage.setItem(this.ACCESS_TYPE, JSON.stringify(item));
    }

    public getAccessType() {
        return JSON.parse(localStorage.getItem(this.ACCESS_TYPE));
    }

    public setAccessOptions(options) {
        localStorage.setItem(this.ACCESS_OPTIONS, JSON.stringify(options));
    }

    public getAccessOptions() {
        return JSON.parse(localStorage.getItem(this.ACCESS_OPTIONS));
    }

    public setAllAccesses(item: any) {
        localStorage.setItem(this.ALL_ACCESSES, JSON.stringify(item));
    }

    public getAllAccesses(): any {
        return JSON.parse(localStorage.getItem(this.ALL_ACCESSES));
    }

    public saveFunds(funds: BasicFundListModel[]): void {
        localStorage.setItem(this.FUNDS, JSON.stringify(funds));
    }

    public getFunds(): BasicFundListModel[] {
        return JSON.parse(localStorage.getItem(this.FUNDS));
    }

}
