import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TableConfig } from '../../models/table-config.model';
import { MaskPipe } from 'ngx-mask';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BasicModalComponent } from '../basic-modal/basic-modal.component';
import { Fields } from '../../enum/fields.enum';
import { ColumnsType } from '../../enum/columns-types.enum';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { RegexPatternsData } from '../../data/regex-patterns.data';
import { TableActionsEnum } from '../../enum/table-actions.enum';

enum PhoneMaskEnum {
  PHONE = '(00)0000-00000',
  CELLPHONE = '(00)00000-0000'
}
@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit, OnChanges {

  @Input()
  contacts = [];

  @Input()
  canEnableButtons = false;

  @Output()
  save = new EventEmitter<any>();


  @Output()
  remove = new EventEmitter<any>();


  form = this.fb.group({
    id: [null],
    sellerFund: [null],
    sellerFundId: [null],
    name: [null, Validators.required],
    email: [null, [Validators.email, Validators.required]],
    phone: [null, Validators.required],
    userIdCreated: [null]
  });

  mask = PhoneMaskEnum.PHONE;

  config: TableConfig = {
    columns: [
      { header: 'name' },
      { header: 'email' },
      { header: 'phone' },
      { header: 'dateCreated', type: ColumnsType.DATE }
    ],
    rows:
      [],
    limit: 100,
    total: 0,
    page: 0,
    showLimit: false,
    options: {
      actions: []
    }
  };

  phone: string;
  tmpRows = [];

  patterns = RegexPatternsData;
 
  constructor(public fb: FormBuilder,
    private maskPipe: MaskPipe,
    private modal: BsModalService,
    private confirm: ConfirmDialogService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contacts && changes.contacts.currentValue) {
      this.config.rows = changes.contacts.currentValue;
      this.config.total = this.config.rows.length;
      this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    }
  }

  ngOnInit(): void {
    this.config.rows = this.contacts;
    this.tmpRows = JSON.parse(JSON.stringify(this.config.rows));
    this.config.options.actions = [
      {
        label: 'fields.edit',
        type: TableActionsEnum.EDIT,
        icon: 'fa fa-edit',
        class: 'btn click__item--blue',
        enabled: this.canEnableButtons
      },
      {
        label: 'fields.remove',
        type: TableActionsEnum.REMOVE,
        icon: 'fa fa-trash',
        class: 'btn click__item--red',
        enabled: this.canEnableButtons
      }
    ];
  }

  public addItem(): void {

    const form = this.form.value;
    if (form.phone.length > 10) {
      form.phone = this.maskPipe.transform(form.phone, PhoneMaskEnum.CELLPHONE);
    } else {
      form.phone = this.maskPipe.transform(form.phone, PhoneMaskEnum.PHONE);
    }
    this.phone = null;
    this.form.reset();
    this.save.emit(form);
  }

  public changeMask(): void {
    const field = this.formData['phone'].value;
    if (field && field.length > 10) {
      this.mask = PhoneMaskEnum.CELLPHONE;
    } else {
      this.mask = PhoneMaskEnum.PHONE;
    }
  }

  public verifyMask(): void {
    const control = this.formData['phone'];
    if (control.value.length === 10) {
      control.setErrors(null);
    }
  }

  public editItem(item): void {

    const modalRef = this.modal.show(BasicModalComponent,
      {
        class: 'modal-dialog-centered',
        initialState: {
          config: {
            title: 'menu.updateContact',
            // message: 'global.updateDataButton',
            actionButton: 'buttons.update',
            result: item,
            fields: [
              {
                name: 'global.name',
                type: Fields.TEXT,
                value: item.name.trim(),
                required: true
              },
              {
                name: 'global.email',
                type: Fields.EMAIL,
                value: item.email,
                required: true
              },
              {
                name: 'global.phone',
                value: item.phone,
                type: Fields.MASKED,
                mask: '(00)00000-0000',
                required: true
              }

            ]
            ,
          }
        }

      })

    modalRef.content.onClose.subscribe((response) => {
      let error = false;
      if (response.fields.length > 0) {

        response.fields.map((itens) => {
          if (itens.required) {
            if (itens.type !== Fields.YES_NO && (!itens.value || itens.value.trim() == '')) {
              error = true;
            }
          }
          if (itens.type === Fields.EMAIL && !(new RegExp(this.patterns.EMAIL_PATTERN).test(itens.value))) {
            error = true;
          }
        });


        if (!error) {
          const request = {
            ...response.result,
            name: response.fields[0].value.trim(),
            email: response.fields[1].value,
            phone: response.fields[2].value.lenth > 10 ? this.maskPipe.transform(response.fields[2].value, '(00)00000-0000') : this.maskPipe.transform(response.fields[2].value, '(00)00000-0000')
          }

          this.save.emit(request);
          modalRef.hide();
        }
      }
    });
  }

  public removeItem(item): void {
    const option = this.confirm.openDialog(null);

    option.subscribe((op) => {
      if (item.id) {
        this.remove.emit(item);
      }
    });

  }

  public search(item: string): void {
    if (!item || item === '') {
      this.config.rows = JSON.parse(JSON.stringify(this.tmpRows));
      this.config.total = this.config.rows.length;
    } else {
      item = item.toLowerCase();
      this.config.rows = this.tmpRows.filter((r) => r.name.toLowerCase().includes(item) || r.email.toLowerCase().includes(item));
      this.config.total = this.config.rows.length;
    }
  }

  public setPhone(): void {

    const phoneValidate = this.phone.replace(/[^\d]+/g, '');

    if (phoneValidate && (phoneValidate.length === 10 || phoneValidate.length === 11)) {
      this.formData['phone'].setValue(this.phone);
    }
  }

  get formData() {
    return this.form.controls;
  }
}
